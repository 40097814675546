import { Injectable } from '@angular/core';
import { Device as AppDevice, DeviceInfo } from '@capacitor/device';
import { Platform } from '@ionic/angular';
import { DeviceToken } from '@model/device-token.model';
import { MsmStorageService } from '@storage/msm-storage.service';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { LogService, PlatformContext } from './log.service';

interface Device {
  token: DeviceToken;
}

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  deviceInfo?: DeviceInfo;
  isInitialized = false;
  isBrowserApp = false;
  isBrowserAppOnAndroid = false;
  isBrowserAppOnIos = false;
  isDeviceApp = false;
  private static readonly INCIDENT_COLUMN_DEVICE = 'msmpAppDevice';

  constructor(
    private readonly logService: LogService,
    private readonly platform: Platform,
    private readonly storageService: MsmStorageService,
  ) {}

  async initialize(): Promise<void> {
    this.deviceInfo = await AppDevice.getInfo();
    this.isBrowserApp = false;
    this.isBrowserAppOnIos = false;
    this.isBrowserAppOnAndroid = false;
    this.isDeviceApp = false;

    const platformContext = { platforms: this.platform.platforms() } as PlatformContext;
    this.logService.addContext('platform', platformContext);
    platformContext.platforms.forEach(platformName => this.logService.addTag(`platform.${platformName}`, true));

    // Android app     op emulator : android, phablet, cordova, capacitor, desktop,         hybrid
    // Android browser op emulator : android,                              desktop
    // Android app     op device   : android, phablet, cordova, capacitor,          mobile, hybrid
    // Android browser op device   : android, phablet,                              mobile,         mobileweb
    // browser macbook             : tablet, desktop

    // iOS browser on device       : iphone, ios, mobile, mobileweb
    // iOS app on device           : iphone, ios, cordova, capacitor, mobile, hybrid
    // iOS browser on emulator     : iphone, ios, mobile, mobileweb
    // iOS app on emulator         : iphone, ios, cordova, capacitor, mobile, hybrid
    // iOS firefox browser         : desktop
    if (this.platform.is('android')) {
      this.isDeviceApp = this.platform.is('hybrid');
      this.isBrowserAppOnAndroid = !this.platform.is('hybrid');
      this.isBrowserApp = this.isBrowserAppOnAndroid;
    } else if (this.platform.is('ios') || this.platform.is('ipad') || this.platform.is('iphone')) {
      this.isDeviceApp = !this.platform.is('mobileweb');
      this.isBrowserAppOnIos = this.platform.is('mobileweb');
      this.isBrowserApp = this.isBrowserAppOnIos;
    } else if (this.platform.is('desktop')) {
      this.isDeviceApp = false;
      this.isBrowserAppOnIos = false;
      this.isBrowserAppOnAndroid = false;
      this.isBrowserApp = true;
    } else if (this.platform.is('mobileweb')) {
      this.isDeviceApp = false;
      this.isBrowserApp = true;
      // We don't know, so we assume no
      this.isBrowserAppOnIos = false;
      this.isBrowserAppOnAndroid = false;
    } else {
      this.logService.logMessage('Unhandled platform combination', 'warning', {
        error: {
          platforms: this.platform.platforms(),
          device: this.deviceInfo,
        },
      });
    }

    platformContext.browserApp = this.isBrowserApp;
    platformContext.deviceApp = this.isDeviceApp;
    this.logService.addContext('platform', platformContext);

    let platformType: 'web' | 'app' | 'unknown';
    if(this.isBrowserApp) {
      platformType = 'web';
    } else {
      platformType = this.isDeviceApp ? 'app' : 'unknown';
    }

    this.logService.addTag('platform.type', platformType );
    this.isInitialized = true;
  }

  storeDeviceToken(deviceToken: DeviceToken): Observable<DeviceToken> {
    return this.loadDevice().pipe(
      map(device => {
        device.token = deviceToken;

        return device;
      }),
      mergeMap(device => this.storageService.store(DeviceService.INCIDENT_COLUMN_DEVICE, JSON.stringify(device))),
      map(() => deviceToken),
    );
  }

  loadDeviceToken(): Observable<DeviceToken> {
    return this.loadDevice().pipe(map(device => device.token));
  }

  clearDevice(): Observable<void> {
    return this.storageService.clear(DeviceService.INCIDENT_COLUMN_DEVICE);
  }

  private loadDevice(): Observable<Device> {
    return this.storageService.read(DeviceService.INCIDENT_COLUMN_DEVICE).pipe(
      map(deviceJson => {
        if (!deviceJson || '{}' === deviceJson) {
          return {} as Device;
        }

        return JSON.parse(deviceJson) as Device;
      }),
    );
  }
}
