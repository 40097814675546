import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormGroupName } from '@angular/forms';
import { AbstractMsmFormComponent } from '@component/abstract-msm-form-component.directive';
import { FormHelper } from '@component/helper/form-helper';
import { DamageIndicatorInputComponent } from '@component/input/damage-indicator/damage-indicator-input/damage-indicator-input.component';
import { Question } from '@model/question.model';
import { AnswersService } from '@service/answers.service';
import { LogService } from '@service/log.service';
import { ToastService } from '@service/toast.service';

@Component({
  selector: 'msm-damage-indicator-grid',
  templateUrl: './damage-indicator-grid.component.html',
  styleUrls: ['./damage-indicator-grid.component.scss'],
})
export class DamageIndicatorGridComponent extends AbstractMsmFormComponent implements OnInit, AfterViewInit {
  @ViewChildren(DamageIndicatorInputComponent) indicators?: QueryList<DamageIndicatorInputComponent>;
  @Input({ required: true }) question!: Question;

  constructor(
    private readonly controlContainer: FormGroupName,
    private cd: ChangeDetectorRef,
    protected override readonly answersService: AnswersService,
    protected override readonly logService: LogService,
    protected override readonly toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.answersForm = this.controlContainer.control;
    this.logService.logBreadcrumb(`Set answersFrom on damage-indicator-grid: ${!!this.answersForm}`);

    this.parentQuestionCode = this.question.code ?? '';
    if (this.answersForm.enabled) {
      this.setDefaultAnswers();
    }
    this.enableUpdateOnChange();
    this.enableMessageOnChange();
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  // Only disabled if all child components are disabled
  disabled(): boolean {
    return !!this.indicators && !this.indicators.some(indicator => !indicator.disabled);
  }

  setInitialContact(location: string): void {
    this.indicators?.forEach(indicator => {
      if (!indicator.includeArrows() && !indicator.hasSelection()) {
        indicator.selectLocation(location);
      }
    });
  }

  getChildQuestionCode(childQuestion: Question): string {
    return FormHelper.getQuestionCodeWithoutPrefix(childQuestion.code, this.question.code);
  }

  get questions(): Question[] {
    return this.question.children?? [];
  }
}

