export interface Version {
  action: VersionAction;
  message: string;

  // Information below is information about the backend
  version: string;
  environment: string;
  buildTimestamp: string;
}

export enum VersionAction {
  OK = 'OK',
  STOP = 'STOP',
}
