import { Component, EventEmitter, OnInit, Optional, Output, Self } from '@angular/core';
import { UntypedFormGroup, NgControl } from '@angular/forms';
import { Incident } from '@model/incident.model';
import { ErrorService } from '@service/error.service';
import { IncidentService } from '@service/incident.service';
import { IncidentsApiService } from '@service/incidents-api.service';
import { ToastService } from '@service/toast.service';
import { FormsPageComponent } from 'src/app/forms/forms-page.component';

import { AbstractMsmInputComponent } from '../abstract-msm-input.component';

@Component({
  selector: 'msm-link-input',
  templateUrl: './link-input.component.html',
  styleUrls: ['./link-input.component.scss'],
})
export class LinkInputComponent extends AbstractMsmInputComponent implements OnInit {
  @Output() navigateTo = new EventEmitter<string>();

  linkLength!: number;
  showPrefix = false;
  incident!: Incident | null;
  linkSuccesfully = false;
  linkForm?: UntypedFormGroup;

  constructor(
    private readonly errorService: ErrorService,
    private readonly incidentsApiService: IncidentsApiService,
    private readonly incidentService: IncidentService,
    private readonly toastService: ToastService,
    @Optional() @Self() override ngControl?: NgControl,
  ) {
    super(ngControl);
    this.incidentService.getIncident().subscribe(incident => (this.incident = incident));
  }

  ngOnInit(): void {
    const metaData = this.question.metaData;

    this.showPrefix = !!(metaData.prefix);
    if (metaData.validationRules && typeof metaData.validationRules.max === 'number') {
      this.linkLength = metaData.validationRules.max;
    } else {
      throw new Error('Linklength cannot be determined');
    }

    this.control?.valueChanges.subscribe(newValue => {
      if (this.ngControl?.control?.valid) {
        this.postlinkCode(newValue);
      }
    });
  }

  private postlinkCode(linkCode: string): void {
    this.incidentsApiService.linkIncidentsByJoinCode(this.incident?.uuid ?? '', linkCode).subscribe({
      next: () => {
        this.navigateTo.emit(FormsPageComponent.NAVIGATION_REQUEST_NEXT);
        this.toastService.showToastKey('component.linkcode.linkIsSuccesful', { color: 'primary' });
      },
      error: err => {
        this.ngControl?.control?.reset();
        this.toastService.showToastKey('component.linkcode.linkNotSucceed', { color: 'danger' });
        this.errorService.logError(err);
      },
    });
  }
}

