import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@component/components.module';
import { PageHeaderComponent } from '@component/page-header/page-header.component';
import { SharedModule } from '@component/shared.module';

@NgModule({
  imports: [
    ComponentsModule,
    SharedModule,
    RouterModule,
  ],
  exports: [
    PageHeaderComponent,
  ],
  declarations: [
    PageHeaderComponent,
  ],
  providers: [],
})
export class PageHeaderModule {
}
