import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'msm-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss'],
})
export class HelpModalComponent {
  @Input({ required: true }) helpText!: string | undefined;
  @Output() dismissModal = new EventEmitter<void>();

  dismiss(): void {
    this.dismissModal.emit();
  }
}
