/* eslint-disable linebreak-style */
/**
 * Count each newline character (\n) in string as two characters.
 * @param {string} text
 * @return {number}
 */
export class StringUtil {
  static countNewLineCharacterString(text: string): number {
    return (text?.length || 0) + (text?.split('\n').length || 1) - 1;
  }
}
