<ion-label class="msm-form-type-header">{{ question.question }}</ion-label>

<ion-item *ngFor="let option of question.options; let index = index"
          id="{{ option.code }}"
          (click)="itemClicked(option.code)"
          button
          class="msm-form-type-option {{ option.code === currentValue? 'focused' : '' }}"
          lines="none"
          [ngClass]="option.metaData?.display? 'msm-importance-' + option.metaData?.display?.importance : ''">

  <ion-grid>
    <ion-row>
      <ion-col size="2" *ngIf="option.metaData?.image">
        <ion-img src="/assets/images/icons/{{ option.metaData?.image }}.png">
        </ion-img>
      </ion-col>
      <ion-col size="10"
               class="{{ option.code === currentValue? 'focused' : '' }}">
        <ion-label class="msm-form-type-title"
                  [ngClass]="option.metaData?.display? 'msm-importance-' + option.metaData?.display?.importance : ''">
              {{ option.label }}</ion-label>
        <ng-container>
          <ul class="msm-form-description {{ descriptionLines[index].length === 1 ? 'msm-no-bullet' : '' }}"
              [ngClass]="option.metaData?.display? 'msm-importance-' + option.metaData?.display?.importance : ''">
            <li *ngFor="let descriptionLine of descriptionLines[index]">
              {{ descriptionLine }}
            </li>
          </ul>
        </ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-item>

<div class="errors"
     *ngIf="errors && ngControl?.touched">
  <span class="error"
        *ngIf="errors.required">
    {{ 'input.errors.required' | translate: {field: question.label} }}
  </span>
</div>
