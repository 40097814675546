import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroupName, UntypedFormGroup } from '@angular/forms';
import { Question } from '@model/question.model';
import { AddressService } from '@service/address.service';
import { LanguageService } from '@service/language.service';
import { LogService } from '@service/log.service';
import { debounceTime, map } from 'rxjs/operators';

export interface StreetTown {
  street: string;
  town: string;
}

@Component({
  selector: 'msm-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
})
export class AddressInputComponent {
  @Input({ required: true }) question!: Question;

  addressForm?: UntypedFormGroup;
  zipcodeControl?: AbstractControl;
  houseNumberControl?: AbstractControl;
  streetControl?: AbstractControl;
  townControl?: AbstractControl;

  zipCheckErrorMessage: string | null = null;

  private static readonly QUESTION_KEY_ZIPCODE = 'zipcodeQuestionKey';
  private static readonly QUESTION_KEY_HOUSENUMBER = 'housenumberQuestionKey';
  private static readonly QUESTION_KEY_STREET = 'streetQuestionKey';
  private static readonly QUESTION_KEY_TOWN = 'townQuestionKey';

  private zipcodeCode?: string;
  private houseNumberCode?: string;
  private streetCode?: string;
  private townCode?: string;

  constructor(
    private controlContainer: FormGroupName,
    private addressService: AddressService,
    private readonly logService: LogService,
    private readonly languageService: LanguageService,
  ) {
  }

  ngOnInit(): void {
    this.addressForm = this.controlContainer.control;
    this.setFieldControls();
  }

  getChildCode(fullCode: string): string {
    return fullCode.replace(`${this.question.code}.`, '');
  }

  private setFieldControls(): void {
    this.zipcodeCode = this.getCode(AddressInputComponent.QUESTION_KEY_ZIPCODE);
    this.houseNumberCode = this.getCode(AddressInputComponent.QUESTION_KEY_HOUSENUMBER);
    this.streetCode = this.getCode(AddressInputComponent.QUESTION_KEY_STREET);
    this.townCode = this.getCode(AddressInputComponent.QUESTION_KEY_TOWN);

    this.zipcodeControl = this.getControl(this.zipcodeCode);
    this.houseNumberControl = this.getControl(this.houseNumberCode);
    this.streetControl = this.getControl(this.streetCode);
    this.townControl = this.getControl(this.townCode);

    this.zipcodeControl.valueChanges
      .pipe(
        debounceTime(200),
        map(zipcode => zipcode.trim()),
      )
      .subscribe(zipcode => {
        this.zipcodeControl?.setValue(zipcode, { emitEvent: false });
        this.updateAddress();
      });

    this.houseNumberControl.valueChanges.pipe(debounceTime(200)).subscribe(() => this.updateAddress());
  }

  private updateAddress(): void {
    if (this.houseNumberControl?.valid && this.zipcodeControl?.valid) {
      this.addressService
        .retrieve(this.zipcodeControl.value, this.houseNumberControl.value)
        .pipe(
          map(result => {
            const mappedResult = {} as { [key: string]: string };
            if (this.streetCode && result.street) {
              mappedResult[this.streetCode] = result.street;
            }
            if (this.townCode && result.town) {
              mappedResult[this.townCode] = result.town;
            }

            return mappedResult;
          }),
        )
        .subscribe({
          next: result => this.processAddressResult(result),
          error: (error: HttpErrorResponse) => {
            console.error('Unexpected error calling the address service', error);
            this.logService.logException(error);
          },
        });
    } else {
      if (this.streetControl) {
        this.streetControl.enable();
      }
      if (this.townControl) {
        this.townControl.enable();
      }
    }
  }

  private processAddressResult(result: {
    [key: string]: string;
  }) : void {
    // Unknown combination of zipcode - house number
    if ((this.townCode && !result[this.townCode]) || (this.streetCode && !result[this.streetCode])) {
      this.languageService
        .getTranslation('component.address.unknownCombination', { field: this.question.label })
        .subscribe(res => (this.zipCheckErrorMessage = res));
    } else {
      this.zipCheckErrorMessage = null;
    }

    this.addressForm?.patchValue(result);
    this.addressForm?.updateValueAndValidity();
    if (this.streetCode && this.streetControl) {
      if (result[this.streetCode]) {
        this.streetControl.disable();
      } else {
        this.streetControl.enable();
      }
    }
    if (this.townCode && this.townControl) {
      if (result[this.townCode]) {
        this.townControl.disable();
      } else {
        this.townControl.enable();
      }
    }
  }

  private getChildQuestionCodeQuestionKey(questionKey: string): string | null {
    const questionCode = this.question.metaData[questionKey] as string;
    if (!questionCode) {
      return null;
    }

    return this.getChildCode(questionCode);
  }

  private getControl(code: string): AbstractControl {
    const control = this.addressForm?.get(code);
    if (!control) {
      throw new Error(`Control is null for code ${code}`);
    }

    return control;
  }

  private getCode(name: string): string {
    const code = this.getChildQuestionCodeQuestionKey(name);
    if (code === null) {
      throw new Error(`Code is null for name ${name}`);
    }

    return code;
  }
}
