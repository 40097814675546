/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroupName, Validators } from '@angular/forms';
import { AbstractMsmFormComponent } from '@component/abstract-msm-form-component.directive';
import { ViewWillLeave } from '@ionic/angular';
import { Incident } from '@model/incident.model';
import { Question, SituationData } from '@model/question.model';
import { AnswersService } from '@service/answers.service';
import { ErrorService } from '@service/error.service';
import { IncidentsApiService } from '@service/incidents-api.service';
import { LogService } from '@service/log.service';
import { ToastService } from '@service/toast.service';
import { Crashr, CrashrErrorType, CrashrPartyOptions, CrashrType, FirstImpact, ICrash, ICrashrError, ICrashrResult, IsSupported, LocationType, Movement, PartyType } from '@via-software/crashr';
import { EMPTY, from, Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

interface CrashrChange {
  Step: string;
  Result: ICrashrResult;
}

interface ImageWrapper {
  uuid: string;
  base64DataUrl: string;
}

@Component({
  selector: 'msm-chrashr-input',
  templateUrl: './crashr-input.component.html',
  styleUrls: ['./crashr-input.component.scss'],
})
export class CrashrInputComponent extends AbstractMsmFormComponent implements OnInit, AfterViewInit, ViewWillLeave {
  @Input({ required: true }) question!: Question;
  @Input({ required: true }) incident: Incident | undefined | null;
  @ViewChild('crashr') crashrElementRef?: ElementRef<HTMLElement>;

  crashrId = '';
  crashrSupported = false;

  private static readonly CRASHR_CONTEXT_KEY = 'crashr';
  private static readonly NONIDENTIFYING_CRASHR_PARTY_FIELDS = ['CustomID', 'FirstImpact', 'Movement', 'Type'];
  private static readonly partyTypesWithoutFirstImpact: (keyof typeof PartyType)[] = [PartyType.Pedestrian, PartyType.Horse, PartyType.Skateboard];


  constructor(
    private readonly httpClient: HttpClient,
    private readonly controlContainer: FormGroupName,
    protected override readonly answersService: AnswersService,
    private readonly errorService: ErrorService,
    private readonly incidentsApi: IncidentsApiService,
    protected override readonly logService: LogService,
    protected override readonly toastService: ToastService,
  ) {
    super();
  }

  get questions(): Question[] {
    return this.question.children ?? [];
  }

  ngOnInit(): void {
    this.parentQuestionCode = this.question.code;

    this.answersForm = this.controlContainer.control;
    this.logService.logBreadcrumb(`Set answersFrom on crashr-input: ${!!this.answersForm}`);

    this.enableSoftConditions();

    this.crashrSupported = IsSupported();
    if (this.crashrSupported) {
      /*
        Since this component shows the fields when Crashr is not supported, it is hard to configure it to work correctly when Crashr IS supported.
        We need to make the important inputs required.
       */
      this.addRequiredValidator('id');
      this.addRequiredValidator('image_hash');
    } else {
      this.addRequiredValidator('location_line1');
      this.addRequiredValidator('location_line2');
    }

    const crashrId = this.answersForm.get('id')?.value;
    if (crashrId) {
      this.crashrId = crashrId;

      if (!this.crashrSupported) {
        this.answersForm.disable();
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.crashrSupported) {
      this.initCrashr();
    } else {
      this.logCrashrUnsupported(this.crashrId);

      if (this.crashrId && !this.answersForm.get('image_hash')?.value) {
        this.logService.logMessage('Non WebGL user has a CrashrID but no image hash', 'warning');
      }
    }
  }

  override ionViewWillLeave(): void {
    this.logService.removeContext(CrashrInputComponent.CRASHR_CONTEXT_KEY);

    super.ionViewWillLeave();
  }

  getChildQuestionCode(childQuestion: Question): string {
    return childQuestion.code.replace(`${this.question.code}.`, '');
  }

  private addRequiredValidator(childQuestionCode: string): void {
    this.answersForm.get(childQuestionCode)?.addValidators(Validators.required);
    this.answersForm.get(childQuestionCode)?.updateValueAndValidity();
  }

  private initCrashr(): void {
    if (!this.crashrId) {
      this.httpClient.post<ICrash>(`${environment.endpointUrl}/crashr/Main/Start`, null)
        .subscribe({
          next: id => {
            this.crashrId = id.ID;
            this.patchAnswers({ id: this.crashrId });
            this.answersService.saveAnswer(`${this.parentQuestionCode}.id`, this.crashrId).subscribe();

            this.showCrashr();
          },
          error: error => {
            this.errorService.logError(error);
            this.toastService.showToast(error.message, { color: 'danger' });
          },
        });
    } else {
      this.showCrashr();
    }
  }

  private showCrashr(): Crashr {
    const mode = environment.production ? 'production' : 'development';
    const situationData = this.question.metaData.situationData;
    let parties: CrashrPartyOptions[] = [];
    const crashrType = this.mapCrashType(Object.keys(situationData || {}).length);

    if (situationData && crashrType === CrashrType.Crash) {
      parties = this.processPartySituation(situationData);
    }

    this.addCrashrContextToSentry( this.question.metaData.incidentDate ?? 'unknown',
      this.question.metaData.incidentTime ?? 'unknown', mode, crashrType, parties);
    this.logService.logMessage('Starting the CrashR component', 'debug');

    return new Crashr({
      id: this.crashrId,
      container: this.crashrElementRef?.nativeElement as HTMLElement,
      locationType: LocationType.RoadSegment,
      date: this.question.metaData.incidentDate ?? 'unknown',
      time: this.question.metaData.incidentTime ?? 'unknown',
      culture: 'nl-NL',
      mode: mode,
      parties: parties,
      type: crashrType,
      onChange: (change: CrashrChange) => this.crashrChanged(change),
      onError: (error: ICrashrError) => {
        this.logService.logMessage(`CrashR returned a ${error.Type}${error.Type === CrashrErrorType.Api ? (` [${  error.Data.Code  }]`) : ''} error: ${error.Data.Message}`, 'error');
      },
    });
  }

  private processPartySituation(situationData: Record<string, SituationData>): CrashrPartyOptions[] {
    const parties: CrashrPartyOptions[] = [];

    for (const situationDataKey in situationData) {
      const situation = situationData[situationDataKey];
      const partyType = this.mapPartyType(situation.vehicleType) ?? PartyType.Car;
      const partyFirstImpact = this.getFirstImpact(partyType, situation.firstImpact);
      const partyMovement = this.mapDrivingDirection(situation.drivingDirection) ?? Movement.Forward;
      const partyLicensePlate = situation.licensePlate ?? '';

      parties.push({
        CustomID: situationDataKey,
        FirstImpact: partyFirstImpact,
        LicensePlate: partyLicensePlate,
        Movement: partyMovement,
        Type: partyType,
      });
    }

    return parties;
  }

  private crashrChanged(change: CrashrChange): void {
    if (change.Step === 'Done') {
      const result = change.Result;

      if (change.Result.Hash !== this.answersForm.get('image_hash')?.value) {
        this.logService.logMessage('CrashR sketch is Done',  'debug', {
          hashCodes: {
            previous: this.answersForm.get('image_hash')?.value || 'NA',
            current: change.Result.Hash,
          },
        });

        this.patchAnswers({
          coordinates: { latitude: result.Latitude, longitude: result.Longitude },
          image_hash: result.Hash,
          location_line1: result.Residency ? result.Residency : '',
          location_line2: result.Placeholder ? result.Placeholder : '',
        });

        this.uploadImage(result.Image).subscribe(imageResponse => this.processImage(imageResponse));
      } else {
        this.logService.logBreadcrumb('CrashR sketch changed', 'msm-custom', 'debug', undefined, {
          step: change.Step,
        });
      }
    } else {
      this.logService.logBreadcrumb('CrashR sketch changed', 'msm-custom', 'debug', undefined, {
        step: change.Step,
      });
      if (this.answersForm.get('image_hash')?.value) {
        this.logService.logMessage('CrashR sketch is being edited, reset image hash', 'debug', {
          hashCodes: {
            previous: this.answersForm.get('image_hash')?.value,
            current: 'NA',
          },
        });
      }

      this.patchAnswers({ image_hash: null });
    }
  }

  private processImage(imageResponse: ImageWrapper): void {
    if (this.answersForm.get('location_image')) {
      this.patchAnswers({ location_image: imageResponse.uuid });
    }

    if (this.answersForm.get('sketch_image')) {
      this.patchAnswers({ sketch_image: imageResponse.uuid });
    }
  }

  private uploadImage(dataUrl: string): Observable<ImageWrapper> {
    if (this.incident) {
      const uuid = this.incident.uuid;

      return from(fetch(dataUrl)).pipe(
        concatMap(base64Response => from(base64Response.blob())),
        concatMap(imageBlob => this.incidentsApi.saveMeldingAttachments(uuid, imageBlob, 'image/png')),
        map(uploadResponse => ({ uuid: uploadResponse.uuid, base64DataUrl: dataUrl } as ImageWrapper)),
      );
    } else {
      return EMPTY;
    }
  }

  private mapCrashType(numberOfParties: number): CrashrType {
    return numberOfParties === 1 ? CrashrType.Location : CrashrType.Crash;
  }

  private getFirstImpact(type: keyof typeof PartyType, collision: string): keyof typeof FirstImpact {
    if (CrashrInputComponent.partyTypesWithoutFirstImpact.includes(type)) {
      return FirstImpact.Unknown;
    }

    if (!collision) {
      this.logService.logMessage(`Received no collision value, returning ${FirstImpact.Unknown}, 'warning'`);

      return FirstImpact.Unknown;
    }

    switch (collision) {
      case 'top-left':
        return FirstImpact.LeftFront;
      case 'top-center':
        return FirstImpact.Front;
      case 'top-right':
        return FirstImpact.RightFront;
      case 'middle-left':
        return FirstImpact.LeftSide;
      case 'middle-center':
        return FirstImpact.Top;
      case 'middle-right':
        return FirstImpact.RightSide;
      case 'bottom-left':
        return FirstImpact.LeftRear;
      case 'bottom-center':
        return FirstImpact.Rear;
      case 'bottom-right':
        return FirstImpact.RightRear;
      case 'Unknown':
        return FirstImpact.Unknown;
      default: {
        this.logService.logMessage(`Trying to map unknown collision value ${collision}, returning ${FirstImpact.Unknown}`, 'warning');

        return FirstImpact.Unknown;
      }
    }
  }

  private mapPartyType(type: string): keyof typeof PartyType | null {
    if (type) {
      if (type.toLowerCase() === 'mobility_scooter') {
        return PartyType.MobilityScooter;
      }

      const foundEntry = Object.entries(PartyType).find( entry => entry[0].toLowerCase() === type.toLowerCase());
      if (foundEntry){
        return foundEntry[1];
      }

      this.logService.logMessage(`Trying to map unknown PartyType value ${type}`, 'warning');

      return null;
    } else {
      this.logService.logMessage('Received no party type', 'warning');

      return null;
    }
  }

  private mapDrivingDirection(direction: string): keyof typeof Movement {
    if (!direction) {
      this.logService.logMessage(`Received no driving direction, returning ${Movement.Forward}`, 'warning');

      return Movement.Forward;
    }

    switch (direction) {
      case 'straight_on':
        return Movement.Forward;
      case 'parked':
        return Movement.Parked;
      case 'backwards':
        return Movement.Backward;
      case 'standing_still':
        return Movement.StandingStill;
      case 'turning_left':
        return Movement.ForwardTurningLeft;
      case 'turning_right':
        return Movement.ForwardTurningRight;
      case 'turning_around':
        return Movement.ForwardUturnLeft;
      default: {
        this.logService.logMessage(`Trying to map driving direction value ${direction}, returning ${Movement.Forward}`, 'warning');

        return Movement.Forward;
      }
    }
  }

  private logCrashrUnsupported(crashrID?: string): void {
    this.logService.addContext(CrashrInputComponent.CRASHR_CONTEXT_KEY, {
      crashrID: crashrID,
      Supported: false,
    });
    this.logService.logMessage('Crashr is not supported.');
  }

  private addCrashrContextToSentry(incidentDate: string, incidentTime: string, mode: string, crashrType: CrashrType, parties: CrashrPartyOptions[]) {
    this.logService.addContext(CrashrInputComponent.CRASHR_CONTEXT_KEY, {
      crashrID: this.crashrId,
      incidentDate,
      incidentTime,
      mode,
      crashrType,
      parties: parties.map(party => JSON.stringify(party, (key, value) => {
        if (!key || Number.isInteger(Number(key))) {
          return value;
        }

        return CrashrInputComponent.NONIDENTIFYING_CRASHR_PARTY_FIELDS.includes(key) ? value : undefined;
      }),
      ),
    });
  }

  private patchAnswers(patchValue: { [key: string]: unknown }): void {
    this.answersForm.patchValue(patchValue);
    // Need to manually mark as dirty to make sure answers are updated
    this.answersForm.markAsDirty();
  }
}
