import { Injectable } from '@angular/core';
import { AnswerType, FormAnswers } from '@model/form-answers';
import { LogService } from '@service/log.service';
import { MsmStorageService } from '@storage/msm-storage.service';
import { mergeWith } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import { Constants } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class AnswersService {
  answersChanged = new Subject<FormAnswers>();

  private static readonly INCIDENT_COLUMN_ANSWERS = 'msmpAppAnswers';
  private answersInMemory!: FormAnswers;
  private readWriteAnswersQueue: Observable<FormAnswers>[] = [];

  constructor(
    private readonly logService: LogService,
    private readonly storageService: MsmStorageService,
  ) {
    this.loadAnswers().subscribe(value => {
      this.answersInMemory = value;
    });
  }

  hasAnswers(): Observable<boolean> {
    return this.storageService.read(AnswersService.INCIDENT_COLUMN_ANSWERS).pipe(
      map(answers => answers !== '{}' && Object.keys(answers).filter(key => key !== Constants.PREFILLED_INSURER_QUESTION).length > 0),
    );
  }

  saveAnswers(answers: FormAnswers): Observable<FormAnswers> {
    const saveAnswersObservable = this.doSaveAnswers(answers);

    return this.queueReadWriteAnswers(saveAnswersObservable);
  }

  /* Save one answer
   * question is a 'path' to a question in the questions-tree, eg:  person.address.street
   * then the saved answerobj will be : {person: {address: {street: answer}}}
   */
  saveAnswer(question: string, answer: AnswerType | null): Observable<FormAnswers> {
    let answerObj: FormAnswers | undefined;

    const parts: string[] = question.split('.').reverse();
    parts.forEach(part => {
      answerObj = { [part]: answerObj || answer } as FormAnswers;
    });

    return this.saveAnswers(answerObj as FormAnswers);
  }

  loadAnswers(): Observable<FormAnswers> {
    const loadAnswersObservable = this.doLoadAnswers();

    return this.queueReadWriteAnswers(loadAnswersObservable);
  }

  getAnswer(answerKey: string): Observable<string | boolean | number | FormAnswers | FormAnswers[]> {
    return this.loadAnswers().pipe(
      map(answers => answers[answerKey]),
    );
  }

  prepareForNewClaim(): Observable<FormAnswers> {
    this.answersInMemory = {};

    const clearAnswersObservable = this.storageService
      .clear(AnswersService.INCIDENT_COLUMN_ANSWERS)
      .pipe(map(() => ({})));

    return this.queueReadWriteAnswers(clearAnswersObservable);
  }

  static mergeAllExceptArrays(object: FormAnswers, source: FormAnswers): FormAnswers {
    return mergeWith(object, source, (_objValue, srcValue, _key, _givenObject, _givenSource) => {
      if (Array.isArray(srcValue)) {
        // Do not merge arrays, but overwrite them
        return srcValue;
      }
      // When no return is given, the default merge is used.
    });
  }

  private doLoadAnswers(): Observable<FormAnswers> {
    return this.storageService.read(AnswersService.INCIDENT_COLUMN_ANSWERS).pipe(
      map(storageValue => {
        try {
          return JSON.parse(storageValue);
        } catch (error) {
          this.logService.logException(error);

          return null;
        }
      }),
    );
  }

  /**
   * Create the Observable that saves the answers when subscribed to
   * @param answers
   */
  private doSaveAnswers(answers: FormAnswers): Observable<FormAnswers> {
    let mergedAnswersResult: FormAnswers;

    for (const answer in answers) {
      this.answersInMemory[answer] = answers[answer];
    }

    // Call doLoadAnswers instead of loadAnswers so we don't get queued behind ourselves
    return this.doLoadAnswers().pipe(
      map(storedAnswers =>
        AnswersService.mergeAllExceptArrays(
          AnswersService.mergeAllExceptArrays(storedAnswers || {}, this.answersInMemory),
          answers,
        ),
      ),
      tap(mergedAnswers => mergedAnswersResult = mergedAnswers),
      map(mergedAnswers => JSON.stringify(mergedAnswers)),
      mergeMap(answersJson => this.storageService.store(AnswersService.INCIDENT_COLUMN_ANSWERS, answersJson)),
      map(() => mergedAnswersResult),
      tap(mergedAnswers => this.answersChanged.next(mergedAnswers)),
    );
  }

  /**
   * Queue the saveAnswersObservable to make sure only one saveAnswersObservable is running at a time
   * @param readWriteAnswersObservable
   */
  private queueReadWriteAnswers(readWriteAnswersObservable: Observable<FormAnswers>): Observable<FormAnswers> {
    this.readWriteAnswersQueue.push(readWriteAnswersObservable);

    return new Observable<FormAnswers>(observer => {
      const intervalId = setInterval(() => {
        if (this.readWriteAnswersQueue.length > 0) {
          const first = this.readWriteAnswersQueue[0];
          if (first === readWriteAnswersObservable) {
            clearInterval(intervalId);
            readWriteAnswersObservable.subscribe((...args) => {
              observer.next(...args);
              observer.complete();
              this.readWriteAnswersQueue.shift();
            });
          }
        }
      }, 10);
    });
  }
}
