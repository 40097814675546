import { Component, OnInit, Optional, Self } from '@angular/core';
import { FormBuilder, FormGroup, NgControl } from '@angular/forms';
import { DateHelper } from '@component/helper/date-helper';
import { AbstractMsmInputComponent } from '@component/input/abstract-msm-input.component';
import { DateTimeForm } from '@component/input/datetime-input/date-time-form';
import { DeviceService } from '@service/device.service';
import _moment from 'moment';

interface ValidationMetaData {
  value?: _moment.Moment;
  dateTime?: string | null;
  date?: string | null;
}

@Component({
  selector: 'msm-datetime-input',
  templateUrl: './datetime-input.component.html',
  styleUrls: ['./datetime-input.component.scss'],
})
export class DatetimeInputComponent extends AbstractMsmInputComponent implements OnInit {
  dateHelper = DateHelper;

  dateTimeForm: FormGroup<DateTimeForm> = this.formBuilder.group({
    date: '',
    time: '',
  });

  min: ValidationMetaData = {};
  max: ValidationMetaData = {};

  constructor(
    private formBuilder: FormBuilder,
    public deviceService: DeviceService,
    @Optional() @Self() public override ngControl?: NgControl,
  ) {
    super(ngControl);
  }

  ngOnInit(): void {
    if (this.deviceService.isBrowserApp) {
      this.prepareBrowserApp();
    }

    if (this.question.metaData.validationRules) {
      this.min = this.prepareDateTimeValidation(this.question.metaData.validationRules.min);
      this.max = this.prepareDateTimeValidation(this.question.metaData.validationRules.max);
    }
  }

  prepareDateTimeValidation(rule: string | number): ValidationMetaData {
    if (typeof rule === 'string') {
      const value = DateHelper.parseDateTime(rule);
      const dateTime = DateHelper.getAttributeDateTime(value);
      const date = DateHelper.getAttributeDate(value);

      return { value, dateTime, date };
    }

    return {};
  }

  private prepareBrowserApp(): void {
    this.dateTimeForm = this.formBuilder.group({
      date: this.control?.value ? DateHelper.getAttributeDate(this.control.value) : '',
      time: this.control?.value ? DateHelper.getAttributeTime(this.control.value) : '',
    });

    this.dateTimeForm.valueChanges.subscribe({
      next: changes => {
        if (changes.date && changes.time) {
          this.onChange(DateHelper.formatDateTime(DateHelper.parseDateAndTime(changes.date, changes.time)));
          this.control?.markAsTouched();
        }
      },
    });
  }
}
