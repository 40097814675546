import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { TranslateService } from '@ngx-translate/core';
import _moment from 'moment';
import { firstValueFrom, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LanguageService {

  readonly supportedLanguages = ['nl'];

  private readonly defaultAppLanguage = 'nl';
  private selectedLanguage!: string;

  constructor(
    private readonly translateService: TranslateService,
  ) {
  }

  async initialize(): Promise<void> {
    await this.determineSelectedLanguage();
    await firstValueFrom(this.translateService.use(this.selectedLanguage));
    _moment.locale(this.selectedLanguage);
  }

  async determineSelectedLanguage(): Promise<void> {
    const languageCodeResult = await Device.getLanguageCode();
    this.selectedLanguage = this.supportedLanguages.find(lang => lang === languageCodeResult.value) || this.defaultAppLanguage;
  }

  getTranslation(key: string, options?: Record<string, unknown>): Observable<string> {
    return this.translateService.get(key, options).pipe(catchError(error => {
      console.error(`Failed to retrieve translation for key ${key}, error : ${error}`);

      return throwError(() => error);
    }));
  }
}
