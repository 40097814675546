import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { ToastOptions } from '@ionic/core/dist/types/components/toast/toast-interface';
import { LanguageService } from '@service/language.service';
import { LogService } from '@service/log.service';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {

  private readonly defaultToastDuration = 3000;
  private readonly defaultToastPosition = 'bottom';
  private readonly defaultOptions: ToastOptions = {
    position: this.defaultToastPosition,
    duration: this.defaultToastDuration,
  };

  private readonly defaultLoadingTranslationFailedMessage = 'An exception happened when trying to load the message we tried to show you.';

  private currentToast?: HTMLIonToastElement;

  constructor (
    private readonly languageService: LanguageService,
    private readonly logService: LogService,
    private readonly toastController: ToastController,
  ) {
  }

  clear(): void {
    if (this.currentToast) {
      this.currentToast.dismiss();
    }
  }

  showToast(message: string, options: ToastOptions = {}): void {
    this.clear();

    const mergedOptions = { ...this.defaultOptions, ...options, message };

    from(this.toastController.create(mergedOptions)).subscribe(toast => {
      this.currentToast = toast;
      this.currentToast.present();
    });
  }

  showToastKey(messageKey: string, options?: ToastOptions): void {
    this.languageService.getTranslation(messageKey).subscribe({
      next: message => {
        this.showToast(message, options);
      },
      error: err => {
        console.error('Could not show toastr, got error from language service', err);
        this.logService.logException(err);
        this.showToast(this.defaultLoadingTranslationFailedMessage, { color: 'danger' });
      },
    });
  }
}

