import { Component, Input, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { AbstractMsmFormComponent } from '@component/abstract-msm-form-component.directive';
import { Question } from '@model/question.model';
import { AnswersService } from '@service/answers.service';
import { LogService } from '@service/log.service';

@Component({
  selector: 'msm-person-input',
  templateUrl: './person-input.component.html',
  styleUrls: ['./person-input.component.scss'],
})
export class PersonInputComponent extends AbstractMsmFormComponent implements OnInit {
  @Input({ required: true }) question!: Question;
  @Input({ required: true }) index!: number;

  constructor(
    protected override readonly answersService: AnswersService,
    protected override readonly logService: LogService,
    private controlContainer: FormGroupDirective,
  ) {
    super();
  }

  ngOnInit(): void {
    this.answersForm = this.controlContainer.form;
    this.logService.logBreadcrumb(`Set answersFrom on person-input: ${!!this.answersForm}`);

    this.parentQuestionCode = this.question.code ?? '';
    this.enableSoftConditions();
    this.enableUpdateOnChange();
    this.enableAppendAnswers();
  }

  get questions(): Question[] {
    return this.question.children ?? [];
  }

  getChildQuestionCode(childQuestion: Question): string {
    return childQuestion.code.replace(`${this.question.code}.`, '');
  }
}
