import { Injectable, OnDestroy } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Version, VersionAction } from '@model/version';
import { VersionApiService } from '@service/version-api.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppVersionGuard  implements OnDestroy {

  private version: Version | null = null;
  private versionResetTimeout?: NodeJS.Timeout;

  constructor(
    private readonly router: Router,
    private readonly versionApi: VersionApiService,
  ) {
  }

  ngOnDestroy(): void {
    if (this.versionResetTimeout) {
      clearTimeout(this.versionResetTimeout);
    }
  }

  canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.version) {
      return this.parseVersionResponse(this.version);
    }

    return this.versionApi.getVersion().pipe(
      tap(version => {
        this.version = version;
        this.versionResetTimeout = setTimeout(() => this.version = null, 10 * 60 * 1000);
      }),
      map(version => this.parseVersionResponse(version)),
    );
  }

  private parseVersionResponse(version: Version): boolean | UrlTree {
    if (version.action === VersionAction.OK) {
      return true;
    }
    console.warn('Version expired', version);

    return this.router.createUrlTree(['error', 'update-version']);
  }

}
