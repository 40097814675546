<ion-item *ngIf="control" lines="none" >
    <ion-checkbox [name]="question.code"
                  [formControl]="control"
                  labelPlacement="end">
      <p class='ion-text-wrap'>{{question.question}}</p>
    </ion-checkbox>
</ion-item>

<div class="errors" *ngIf="errors && ngControl?.touched">
  <span class="error" *ngIf="errors.required">
    {{ 'input.errors.required' | translate: {field: question.label} }}
    {{ 'input.errors.required' | translate: {field: question.label} }}
  </span>
</div>
