import { baseEnvironment } from './environment.base';

const specificEnvironment = {
  deeplinkUrl: 'https://www-dev.mobielschademelden.nl',
  endpointUrl: 'https://www-dev.mobielschademelden.nl/api/v3',
  environment: 'development',
  production: false,
  viaServerUrl: 'https://www-dev.mobielschademelden.nl/api/v2/viaGeoCoding',
};

export const environment = { ...baseEnvironment, ...specificEnvironment };
