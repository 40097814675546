<ion-item *ngIf="control" [ngClass]="{'msm-control-hidden' : control.disabled}">
  <ion-textarea autoGrow="true" [name]="question.code" [formControl]="control" [attr.maxlength]="maxLength"
                labelPlacement="floating"
                label="{{question.question}}"
  ></ion-textarea>
  <div slot="end" class="msm-input-counter" *ngIf="maxLength">{{ stringLength(ngControl?.control?.value)}}/{{maxLength}}</div>
</ion-item>

<!-- Error messages -->
<!-- TODO: Extract and style; only show first error? -->
<div class="errors" *ngIf="errors && ngControl?.touched">
  <span class="error" *ngIf="errors.required">
    {{ 'input.errors.required' | translate: { field : question.label } }}
  </span>
  <span class="error" *ngIf="errors.minlength">
    {{ 'input.errors.minLength' | translate: { field : question.label, minLength : minLength } }}
  </span>
  <span class="error" *ngIf="errors.maxlength">
    {{ 'input.errors.maxLength' | translate: { field : question.label, maxLength : maxLength } }}
  </span>
</div>
