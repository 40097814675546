import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceToken } from '@model/device-token.model';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DeviceApiService {

  private readonly path = 'devices';
  private readonly endpoint: string;

  constructor(
    private readonly httpClient: HttpClient,
  ) {
    this.endpoint = `${environment.endpointUrl}/${this.path}`;
  }

  createDeviceToken(): Observable<DeviceToken> {
    return this.httpClient.post<DeviceToken>(`${this.endpoint}`, {});
  }
}
