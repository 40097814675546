import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService } from '@service/error.service';
import { retry, Observable, timer, catchError, throwError } from 'rxjs';

@Injectable()
export class HttpRetryRequestInterceptor implements HttpInterceptor {

  private timeoutErrorCodes = [0, 408];

  constructor(
    private errorService: ErrorService,
  ) {
  }

  /**
   *
   * Retry request when detecting a timeout, afterwards throw the error upstream. If a timeout occurs, a toaster message will be shown
   * to the user. The error will be thrown, where it will be picked up by the ErrorService class.
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retry({
        count: 3,
        delay: (error, retryCount) => {
          if (error?.status && this.timeoutErrorCodes.includes(error.status)) {
            return timer(retryCount * 1000);
          } else {
            throw error;
          }
        },
      }),
      catchError(catchedError => this.showTimeout(catchedError)),
    );
  }

  private showTimeout(error: HttpErrorResponse): Observable<never> {
    if (this.timeoutErrorCodes.includes(error.status)) {
      this.errorService.handleTimeout();
    }

    return throwError(() => error);
  }
}

