import { Injectable } from '@angular/core';
import { LogService } from '@service/log.service';
import { MsmStorageService } from '@storage/msm-storage.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DossierlinkService {

  private static readonly INCIDENT_DOSSIER_LINK = 'msmpDossierLink';

  constructor(
    private readonly storageService: MsmStorageService,
    private readonly logService: LogService,
  ) { }

  storeDossierLink(storeData: string): Observable<void>{
    if (storeData === null || storeData === undefined) {
      return of(undefined);
    }

    return this.storageService.store(DossierlinkService.INCIDENT_DOSSIER_LINK, JSON.stringify(storeData));
  }

  getDossierLink(): Observable<string> {
    return this.storageService.read(DossierlinkService.INCIDENT_DOSSIER_LINK).pipe(
      map(dossierLinkJson => {
        try{
          return JSON.parse(dossierLinkJson);
        }
        catch (error) {
          this.logService.logException(error);

          return null;
        }
      }),
    );
  }
}
