import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

import { MsmStorageService } from '../storage/msm-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ImageStorageService {

  static readonly NO_IMAGE_FOUND_RESULT = '';
  private static readonly INCIDENT_COLUMN_IMAGE = 'msmpAppImage';

  constructor(
    private readonly msmStorageService: MsmStorageService,
  ) { }

  storeImage(additionalImageStorageKeyPrefix: string, image: string): Observable<void> {
    return from(this.msmStorageService.storeImage(ImageStorageService.INCIDENT_COLUMN_IMAGE, additionalImageStorageKeyPrefix, image));
  }

  retrieveImage(additionalImageStorageKeyPrefix: string): Observable<string> {
    // eslint-disable-next-line max-len
    return from(this.msmStorageService.retrieveImage(ImageStorageService.INCIDENT_COLUMN_IMAGE, additionalImageStorageKeyPrefix, ImageStorageService.NO_IMAGE_FOUND_RESULT));
  }

  removeImage(additionalImageStorageKeyPrefix: string): Observable<void> {
    return from(this.msmStorageService.removeImage(ImageStorageService.INCIDENT_COLUMN_IMAGE, additionalImageStorageKeyPrefix));
  }

  clearImages(): Observable<void> {
    return from(this.msmStorageService.clear(ImageStorageService.INCIDENT_COLUMN_IMAGE));
  }
}
