<div class="msm-container">
  <ion-img *ngIf="imageLoaded"
           class="msm-image-car"
           (ionError)="imageIsNotLoaded()"
           [src]="imageUrl"
           alt="{{ 'component.licenseplate.imageAltText' | translate }}">
  </ion-img>
</div>

<div class="msm-container">
  <ion-label *ngIf="imageLoaded"
             class="msm-different-color">{{ 'component.licenseplate.differentColor' | translate}}</ion-label>
  <ion-label class="msm-brand">{{ brandModel}}</ion-label>
</div>


<msm-licenseplate-input *ngIf="licenseControl"
                        [question]="question" [formControl]="licenseControl">
</msm-licenseplate-input>

<msm-boolean-input *ngIf="control"
                   [question]="question" [formControl]="control" (navigateTo)="onNavigateTo($event)">
</msm-boolean-input>
