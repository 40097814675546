import { Component, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AbstractMsmInputComponent } from '@component/input/abstract-msm-input.component';
import { LicenseplateUtil } from '@component/utils/licenseplate-util';
import { AnswersService } from '@service/answers.service';

@Component({
  selector: 'msm-linking-done',
  templateUrl: './linking-done.component.html',
  styleUrls: ['./linking-done.component.scss'],
})
export class LinkingDoneComponent extends AbstractMsmInputComponent implements OnInit {
  static METADATA_KEY_COUNTERPART_MOBILE = 'counterpart_mobile';
  static METADATA_KEY_COUNTERPART_LICENSEPLATE = 'counterpart_licenseplate';
  static QUESITON_KEY_REPORTER_MOBILE = 'questionKeyMobile';
  static QUESITON_KEY_REPORTER_LICENSEPLATE = 'questionKeyLicensePlate';

  reporterMobileNumber = '';
  reporterLicensePlate = '';
  counterpartMobileNumber = '';
  counterpartLicenseplate = '';

  maxLength = 6;

  constructor(
    private readonly answersService: AnswersService,
    @Optional() @Self() override ngControl?: NgControl,
  ) {
    super(ngControl);
  }

  ngOnInit(): void {
    this.getAnswers();
  }

  getAnswers(): void {
    this.answersService.loadAnswers().subscribe(answers => {
      //Get mobilenumbers as they should always be present
      if (this.question) {
        const reporterMobileKey = this.question.metaData[LinkingDoneComponent.QUESITON_KEY_REPORTER_MOBILE] as string;
        this.reporterMobileNumber = answers[reporterMobileKey] as string;
        this.counterpartMobileNumber = this.question.metaData[
          LinkingDoneComponent.METADATA_KEY_COUNTERPART_MOBILE
        ] as string;

        const reporterLicenseplateKey = this.question.metaData[
          LinkingDoneComponent.QUESITON_KEY_REPORTER_LICENSEPLATE
        ] as string;
        // Get reporter licensplate data if present
        if (answers[reporterLicenseplateKey]) {
          const licenseplate = answers[reporterLicenseplateKey] as string;
          this.reporterLicensePlate = LicenseplateUtil.formatLicenseplate(licenseplate, this.maxLength);
        }

        //Get counterpart licensplate data if present
        if (this.question.metaData[LinkingDoneComponent.METADATA_KEY_COUNTERPART_LICENSEPLATE]) {
          const licensplate = this.question.metaData[
            LinkingDoneComponent.METADATA_KEY_COUNTERPART_LICENSEPLATE
          ] as string;
          this.counterpartLicenseplate = LicenseplateUtil.formatLicenseplate(licensplate, this.maxLength);
        }
      }

      this.control?.setValue('linking_done');
      this.control?.markAsDirty();
    });
  }
}
