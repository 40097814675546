import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, FormGroupName } from '@angular/forms';
import { Question } from '@model/question.model';

@Component({
  selector: 'msm-images-input',
  templateUrl: './images-input.component.html',
  styleUrls: ['./images-input.component.scss'],
})
export class ImagesInputComponent implements OnInit {
  @Input({ required: true }) question!: Question;

  imagesForm?: UntypedFormGroup;

  constructor(
    private controlContainer: FormGroupName,
  ) {
  }

  ngOnInit(): void {
    this.imagesForm = this.controlContainer.control;
  }

  getChildCode(fullCode: string): string {
    return fullCode.replace(`${this.question.code}.`, '');
  }
}
