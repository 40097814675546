import {
  Component,
  EventEmitter,
  Optional,
  Output,
  QueryList,
  Self,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { AbstractMsmInputComponent } from '@component/input/abstract-msm-input.component';
import { DamageIndicatorSectionComponent } from '@component/input/damage-indicator/damage-indicator-section/damage-indicator-section.component';

@Component({
  selector: 'msm-damage-indicator-input',
  templateUrl: './damage-indicator-input.component.html',
  styleUrls: ['./damage-indicator-input.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class DamageIndicatorInputComponent extends AbstractMsmInputComponent {
  @ViewChildren(DamageIndicatorSectionComponent) sections?: QueryList<DamageIndicatorSectionComponent>;
  @Output() initialContactEvent = new EventEmitter<string>();

  constructor(
    @Optional() @Self() public override ngControl?: NgControl,
  ) {
    super(ngControl);
  }

  ngAfterViewInit(): void {
    this.setInitialSelectedLocations();
  }

  /**
   * In case of an initial impact grid type ("arrows"), only one location can be active. That location
   * is set by the input section itself, this method deselects any previously selected locations.
   *
   * An event is emitted when an arrow location is selected and there was no previously selected location,
   * which handled the (optional) parent DamageIndicatorGridComponent.
   *
   * @param selectedLocation The selected location
   */
  updateSelection(selectedLocation: string): void {
    if (!this.disabled) {
      if (this.question.metaData.includeArrows) {
        let hadPreviousSelection = false;

        this.sections?.toArray().forEach(section => {
          if (section.location !== selectedLocation && section.locationSelected) {
            hadPreviousSelection = true;
            section.unsetLocation();
          }
        });
        if (!hadPreviousSelection && this.hasSelection()) {
          this.initialContactEvent.emit(selectedLocation);
        }
      }
      this.onChange(this.getSelectedLocationNames());
    }
  }

  selectLocation(location: string): void {
    this.sections?.toArray().forEach(section => {
      if (location === section.location) {
        section.selectLocation(true);
      }
    });
  }

  includeArrows(): boolean {
    return !!this.question.metaData.includeArrows;
  }

  hasSelection(): boolean {
    return this.getSelectedLocationNames().length > 0;
  }

  override disable(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private getSelectedLocationNames(): string[] {
    if (this.sections && this.sections.length > 0) {
      return this.sections.filter(section => section.locationSelected).map(section => section.location ?? '');
    }

    return [];
  }

  private setInitialSelectedLocations(): void {
    this.sections?.toArray().forEach(section => {
      if (this.control?.value && Array.isArray(this.control.value) && this.control.value.includes(section.location)) {
        section.selectLocation(false);
      }
    });
  }
}
