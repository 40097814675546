<ion-menu menuId="progress" contentId="main-content" type="overlay">
  <ion-header>
    <ion-toolbar>
      <ion-title>Voortgang</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content color="primary">

    <ng-container *ngIf="(menuItems$ | async) as menuItems; else isLoading">
      <ng-container *ngIf="menuItems.length > 0; else isEmpty">
        <ion-list>

          <ion-menu-toggle [id]="'menu-toggle-' + item.code" auto-hide="false" *ngFor="let item of menuItems; let index = index">
            <ion-item [id]="'menu-item-' + item.code"
                      [ngClass]="{ 'selected': location.isCurrentPathEqualTo('/questions/' + item.code) }"
                      [routerLink]="['redirect', item.code]"
                      [disabled]="!item.canNavigate">
              <ion-label [id]="'menu-item-' + item.code + '-label'">{{index + 1}}&nbsp;{{item.label}}</ion-label>
              <ion-icon [id]="'menu-item-' + item.code + '-icon'" slot="end" [ios]="'checkmark-circle-outline'" [md]="'checkmark-circle-outline'"
                        *ngIf="item.completed">
              </ion-icon>
            </ion-item>
          </ion-menu-toggle>

          <ion-item (click)="changeTheme()" *ngIf="environment !== 'PRODUCTION'">
            <ion-label>Toggle theme ({{global.get('theme')}})</ion-label>
          </ion-item>

          <!-- MSMP-1630 hide link button until linking is added -->
          <ion-menu-button hidden auto-hide="false">
            <ion-item routerLink="/link">
              <ion-label>{{ 'menu.link' | translate }}</ion-label>
            </ion-item>
          </ion-menu-button>
          <ion-menu-button auto-hide="false">
            <ion-item routerLink="/getting-started">
              <ion-label>{{ 'menu.gettingStarted' | translate }}</ion-label>
            </ion-item>
          </ion-menu-button>
        </ion-list>
      </ng-container>
    </ng-container>

    <ng-template #isLoading>
      <ion-list>
        <ion-item color="primary" lines="none">
          <ion-label>{{ 'menu.loading' | translate }}</ion-label>
        </ion-item>
        <ion-menu-button>
          <ion-item routerLink="/getting-started">
            <ion-label>{{ 'menu.gettingStarted' | translate }}</ion-label>
          </ion-item>
        </ion-menu-button>
      </ion-list>
    </ng-template>

    <ng-template #isEmpty>
      <ion-list>
        <ion-item color="primary" lines="none">
          <ion-label>{{ 'menu.failedToLoad' | translate }}</ion-label>
        </ion-item>
        <ion-menu-button>
          <ion-item routerLink="/getting-started">
            <ion-label>{{ 'menu.gettingStarted' | translate }}</ion-label>
          </ion-item>
        </ion-menu-button>
      </ion-list>
    </ng-template>

  </ion-content>
</ion-menu>
