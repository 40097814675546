import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CustomValidators } from '@component/helper/custom-validators';
import { DateHelper } from '@component/helper/date-helper';
import { FormAnswers } from '@model/form-answers';
import { Question } from '@model/question.model';
import { LogService } from '@service/log.service';

@Injectable({
  providedIn: 'root',
})
export class FormHelper {
  static readonly QUESTION_TYPES_WITHOUT_CONTROLS = ['staticText', 'staticImage', 'hidden'];

  constructor(
    private readonly logService: LogService,
    private readonly formBuilder: UntypedFormBuilder,
  ) {
  }

  createFormControls(questions: Question[], questionCodePrefix?: string, answers: FormAnswers = {}): UntypedFormGroup {
    const formControls: { [key: string]: AbstractControl } = {};
    questions.forEach(q => {
      if (FormHelper.QUESTION_TYPES_WITHOUT_CONTROLS.includes(q.type)) {
        // Don't create a form control for readonly "questions"
      } else if (q.type === 'persons' && Array.isArray(q.children)) {
        const childControls: UntypedFormGroup[] = [];
        const answerForQuestion = answers[q.code];
        if (answerForQuestion && Array.isArray(answerForQuestion)) {
          for (let count = 0; count < answerForQuestion.length; count++) {
            childControls.push(this.createFormControls(q.children, q.code));
          }
        } else {
          childControls.push(this.createFormControls(q.children, q.code));
        }
        formControls[FormHelper.getQuestionCodeWithoutPrefix(q.code, questionCodePrefix)] = new UntypedFormArray(
          childControls,
        );
      } else if (q.children && Array.isArray(q.children) && q.children.length > 0) {
        formControls[FormHelper.getQuestionCodeWithoutPrefix(q.code, questionCodePrefix)] = this.createFormControls(
          q.children,
          q.code,
        );
        formControls[FormHelper.getQuestionCodeWithoutPrefix(q.code, questionCodePrefix)].addValidators(
          this.createValidators(q),
        );
      } else {
        formControls[FormHelper.getQuestionCodeWithoutPrefix(q.code, questionCodePrefix)] = new UntypedFormControl(
          null,
          this.createValidators(q),
        );
      }
    });

    return this.formBuilder.group(formControls);
  }

  static getQuestionCodeWithoutPrefix(questionCode: string, prefixToStrip?: string): string {
    if (prefixToStrip) {
      return questionCode.replace(`${prefixToStrip}.`, '');
    } else {
      return questionCode;
    }
  }

  private createValidators(question: Question): ValidatorFn[] {
    const validators: ValidatorFn[] = [];

    if (!question.metaData?.validationRules) {
      validators.push(Validators.required);

      return validators;
    }

    if (!question.metaData.validationRules.optional) {
      validators.push(Validators.required);
    }

    if (question.metaData.validationRules.regex && typeof question.metaData.validationRules.regex === 'string') {
      validators.push(Validators.pattern(question.metaData.validationRules.regex));
    }

    if (question.metaData.validationRules.min !== null && question.metaData.validationRules.min !== undefined) {
      this.processMinValue(question, validators);
    }

    if (question.metaData.validationRules.max !== null && question.metaData.validationRules.max !== undefined) {
      this.processMaxValue(question, validators);
    }

    const invalidOption = question.metaData.validationRules.invalidOption;
    if (question.type === 'boolean' && invalidOption !== null && invalidOption !== undefined) {
      validators.push(CustomValidators.isNotEqual(invalidOption));
    }

    if (question.type === 'checkbox' && invalidOption === false) {
      validators.push(Validators.requiredTrue);
    }

    if (question.type === 'datetimerange' && question.children) {
      validators.push(
        CustomValidators.dateTimesInOrder(question.children.map(child => this.getChildQuestionCode(question, child))),
      );
    }

    return validators;
  }

  private processMinValue(question: Question, validators: ValidatorFn[]): void {
    const minValue = question.metaData.validationRules?.min;

    if (typeof minValue === 'number') {
      if (question.type === 'number') {
        validators.push(Validators.min(minValue));
      } else {
        validators.push(Validators.minLength(minValue));
      }
    } else if (typeof minValue === 'string') {
      if (question.type === 'date') {
        validators.push(CustomValidators.minDate(DateHelper.parseDate(minValue)));
      } else if (question.type === 'datetime') {
        validators.push(CustomValidators.minDate(DateHelper.parseDateTime(minValue)));
      } else {
        this.logCannotHandleMetaData(question, 'min');
      }
    } else {
      this.logCannotHandleMetaData(question, 'min');
    }
  }

  private processMaxValue(question: Question, validators: ValidatorFn[]): void {
    const maxValue = question.metaData.validationRules?.max;

    if (typeof maxValue === 'number') {
      if (question.type === 'number') {
        validators.push(Validators.max(maxValue));
      } else {
        validators.push(CustomValidators.maxLength(maxValue));
      }
    } else if (typeof maxValue === 'string') {
      if (question.type === 'date') {
        validators.push(CustomValidators.maxDate(DateHelper.parseDate(maxValue)));
      } else if (question.type === 'datetime') {
        validators.push(CustomValidators.maxDate(DateHelper.parseDateTime(maxValue)));
      } else {
        this.logCannotHandleMetaData(question, 'max');
      }
    } else {
      this.logCannotHandleMetaData(question, 'max');
    }
  }

  private logCannotHandleMetaData(question: Question, field: string): void {
    this.logService.logMessage(`Could not process ${field} value of question ${question.code} metadata`, 'warning', {
      metaData: question.metaData,
    });
  }

  private getChildQuestionCode(parentQuestion: Question, childQuestion: Question): string {
    return childQuestion.code.replace(`${parentQuestion.code}.`, '');
  }
}
