import { Component, EventEmitter, OnInit, Optional, Output, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AbstractMsmInputComponent } from '@component/input/abstract-msm-input.component';
import { AnswersService } from '@service/answers.service';
import { ErrorService } from '@service/error.service';
import { IncidentsApiService } from '@service/incidents-api.service';
import { LogService } from '@service/log.service';
import { ToastService } from '@service/toast.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'msm-signcode-input',
  templateUrl: './signcode-input.component.html',
  styleUrls: ['./signcode-input.component.scss'],
})
export class SignCodeInputComponent extends AbstractMsmInputComponent implements OnInit {
  @Output() signCodeEntered = new EventEmitter<void>();

  requestingCode = false;
  maxLength = 0;
  private incidentUuid?: string;

  constructor(
    private readonly answersService: AnswersService,
    private readonly errorService: ErrorService,
    private readonly incidentsApiService: IncidentsApiService,
    private readonly logService: LogService,
    private readonly toastService: ToastService,
    @Optional() @Self() override ngControl?: NgControl,
  ) {
    super(ngControl);
  }

  ngOnInit(): void {
    if (this.question.metaData && this.question.metaData.validationRules && this.question.metaData.validationRules.max) {
      this.maxLength = +this.question.metaData.validationRules.max;
    }
    const incidentUuidKey = this.question.metaData.questionKeyIncidentUuid;

    if (typeof incidentUuidKey === 'string') {
      this.answersService.getAnswer(incidentUuidKey).subscribe(answer => {
        if (answer) {
          this.incidentUuid = answer as string;
        } else {
          this.logService.logMessage(`SignCode component received an incidentUuidKey with an unexpected type: ${typeof incidentUuidKey}, for question ${this.question.code}.`);
        }
      });
    } else {
      this.logService.logMessage(`SignCode component received an incidentUuidKey with an unexpected type: ${typeof incidentUuidKey}, for question ${this.question.code}.`);
    }

    this.control?.valueChanges.subscribe(() => {
      if (this.control?.valid) {
        this.signCodeEntered.emit();
      }
    });
  }

  requestSignCode(): void {
    if (!this.incidentUuid) {
      this.toastService.showToastKey('component.signcode.codeNotSent', { color: 'danger' });

      return;
    }

    if (!this.requestingCode) {
      this.requestingCode = true;
      this.incidentsApiService.requestSignCode(this.incidentUuid).pipe(
        finalize(() => this.requestingCode = false),
      ).subscribe({
        next: () => {
          const translation = this.question.metaData.recipient ? `component.signcode.codeIsSent.${this.question.metaData.recipient}` : 'component.signcode.confirmationcode_incident.codeIsSent';
          this.toastService.showToastKey(translation, { color: 'primary' });
        },
        error: err => {
          this.errorService.logError(err);
          this.toastService.showToastKey('component.signcode.codeNotSent', { color: 'danger' });
        },
      });
    }
  }
}
