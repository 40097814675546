import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Version } from '@model/version';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VersionApiService {
  private readonly path = 'version';
  private readonly endpoint: string;

  private platform: string;

  constructor(
    private readonly httpClient: HttpClient,
  ) {
    this.endpoint = `${environment.endpointUrl}/${this.path}`;

    const capacitorPlatform = Capacitor.getPlatform();
    switch (capacitorPlatform) {
      case 'web': this.platform = 'browser'; break;
      default: this.platform = capacitorPlatform;
    }
  }

  getVersion(): Observable<Version> {
    return this.httpClient.get<Version>(`${this.endpoint}`, {
      headers: {
        platform: this.platform,
        version: environment.version,
      },
    });
  }
}
