import { DataInput } from './datainput';
import { DataInputMode } from './datainput-enum';
import { DataInputType } from './datainput-enum';

export const dataInputs: DataInput[] = [
  {
    id: 'text',
    inputType: DataInputType.TEXT,
    inputMode: DataInputMode.TEXT,
  },
  {
    id: 'email',
    inputType: DataInputType.EMAIL,
    inputMode: DataInputMode.EMAIL,
  },
  {
    id: 'phone',
    inputType: DataInputType.PHONE,
    inputMode: DataInputMode.PHONE,
  },
  {
    id: 'number',
    inputType: DataInputType.NUMERIC,
    inputMode: DataInputMode.NUMERIC,
  },
  {
    id: 'date',
    inputType: DataInputType.DATE,
    inputMode: DataInputMode.NUMERIC,
  },
  {
    id: 'time',
    inputType: DataInputType.TIME,
    inputMode: DataInputMode.NUMERIC,
  },
  {
    id: 'licenseplate',
    inputType: DataInputType.TEXT,
    inputMode: DataInputMode.TEXT,
  },
];
