import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpModalComponent } from '@component/help-modal/help-modal.component';
import { AddressInputComponent } from '@component/input/address-input/address-input.component';
import { BooleanInputComponent } from '@component/input/boolean-input/boolean-input.component';
import { CheckboxInputComponent } from '@component/input/checkbox-input/checkbox-input.component';
import { CrashrInputComponent } from '@component/input/chrashr-input/crashr-input.component';
import { DamageIndicatorGridComponent } from '@component/input/damage-indicator/damage-indicator-grid.component';
import {
  DamageIndicatorInputComponent,
} from '@component/input/damage-indicator/damage-indicator-input/damage-indicator-input.component';
import {
  DamageIndicatorSectionComponent,
} from '@component/input/damage-indicator/damage-indicator-section/damage-indicator-section.component';
import { DataInputComponent } from '@component/input/data-input/data-input.component';
import { DatetimeInputComponent } from '@component/input/datetime-input/datetime-input.component';
import { DatetimeRangeInputComponent } from '@component/input/datetime-range-input/datetime-range-input.component';
import { FormTypeInputComponent } from '@component/input/form-type-input/form-type-input.component';
import { ImageInputComponent } from '@component/input/image-input/image-input.component';
import { ImagesInputComponent } from '@component/input/images-input/images-input.component';
import {
  LicenseplateConfirmInputComponent,
} from '@component/input/licenseplate-confirm-input/licenseplate-confirm-input.component';
import { LicensePlateInputComponent } from '@component/input/licenseplate-input/licenseplate-input.component';
import {
  LicensePlateSummaryInputComponent,
} from '@component/input/licenseplate-summary/licenseplate-summary-input.component';
import { LinkComponent } from '@component/input/link/link.component';
import { LinkInputComponent } from '@component/input/link-input/link-input.component';
import { LinkingDoneComponent } from '@component/input/linking-done/linking-done.component';
import { OptionInputComponent } from '@component/input/option/option-input.component';
import { PersonInputComponent } from '@component/input/person-input/person-input.component';
import { PersonsInputComponent } from '@component/input/persons-input/persons-input.component';
import { ScanQrInputComponent } from '@component/input/scan-qr-input/scan-qr-input.component';
import { SelectInputComponent } from '@component/input/select-input/select-input.component';
import { SignInputComponent } from '@component/input/sign-input/sign-input.component';
import { SignCodeInputComponent } from '@component/input/signcode-input/signcode-input.component';
import { TextareaInputComponent } from '@component/input/textarea-input/textarea-input.component';
import { SharedModule } from '@component/shared.module';
import { StaticImageComponent } from '@component/static-image/static-image.component';
import { StaticTextComponent } from '@component/static-text/static-text.component';

@NgModule({
  declarations: [
    AddressInputComponent,
    BooleanInputComponent,
    CheckboxInputComponent,
    CrashrInputComponent,
    DataInputComponent,
    DatetimeInputComponent,
    DatetimeRangeInputComponent,
    FormTypeInputComponent,
    HelpModalComponent,
    ImageInputComponent,
    ImagesInputComponent,
    LicenseplateConfirmInputComponent,
    LicensePlateInputComponent,
    LicensePlateSummaryInputComponent,
    LinkComponent,
    LinkInputComponent,
    OptionInputComponent,
    PersonInputComponent,
    PersonsInputComponent,
    ScanQrInputComponent,
    SelectInputComponent,
    SignInputComponent,
    SignCodeInputComponent,
    StaticTextComponent,
    StaticImageComponent,
    TextareaInputComponent,
    DamageIndicatorGridComponent,
    DamageIndicatorInputComponent,
    DamageIndicatorSectionComponent,
    LinkingDoneComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  exports: [
    AddressInputComponent,
    BooleanInputComponent,
    CheckboxInputComponent,
    CrashrInputComponent,
    DataInputComponent,
    DatetimeInputComponent,
    DatetimeRangeInputComponent,
    FormTypeInputComponent,
    HelpModalComponent,
    ImageInputComponent,
    ImagesInputComponent,
    LicenseplateConfirmInputComponent,
    LicensePlateInputComponent,
    LicensePlateSummaryInputComponent,
    LinkComponent,
    LinkInputComponent,
    OptionInputComponent,
    PersonInputComponent,
    PersonsInputComponent,
    ScanQrInputComponent,
    SelectInputComponent,
    SignInputComponent,
    SignCodeInputComponent,
    StaticTextComponent,
    StaticImageComponent,
    TextareaInputComponent,
    DamageIndicatorGridComponent,
    DamageIndicatorInputComponent,
    DamageIndicatorSectionComponent,
    LinkingDoneComponent,
  ],
})
export class ComponentsModule {
}
