export enum DataInputType {
  TEXT = 'text',
  EMAIL = 'email',
  PHONE = 'tel',
  NUMERIC = 'number',
  DATE = 'date',
  TIME = 'time',
}

export enum DataInputMode {
  TEXT = 'text',
  EMAIL = 'email',
  PHONE = 'tel',
  NUMERIC = 'numeric',
}
