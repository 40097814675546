import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormAnswers } from '@model/form-answers';
import { Page } from '@model/page.model';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class PageApiService {

  private readonly path = 'pages';
  private readonly endpoint: string;

  constructor(
    private readonly httpClient: HttpClient,
  ) {
    this.endpoint = `${environment.endpointUrl}/${this.path}`;
  }

  getForm(categoryCode: string, answers: FormAnswers): Observable<Page> {
    return this.httpClient.post<Page>(`${this.endpoint}/categories/${categoryCode}`, answers);
  }

  getPage(pageId: number, answers: FormAnswers): Observable<Page> {
    return this.httpClient.post<Page>(`${this.endpoint}/${pageId}`, answers);
  }

  next(answers: FormAnswers, pageId?: number): Observable<Page> {
    const url = this.endpoint + (pageId ? `/${pageId}/next` : '/next');

    return this.httpClient.post<Page>(url, answers);
  }

  previous(answers: FormAnswers, pageId: number): Observable<Page> {
    return this.httpClient.post<Page>(`${this.endpoint}/${pageId}/previous`, answers);
  }
}
