import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface Address {
  zipcode: string;
  houseNumber: string;
  street: string;
  town: string;
}

interface AddressResponse {
  xa: {
    xapcode: string;
    xahuisnr: string;
    xastrtnm: string;
    xaplaats: string;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AddressService {

  private readonly endpoint: string;

  constructor(
    private readonly httpClient: HttpClient,
  ) {
    this.endpoint = `${environment.endpointUrl}`;
  }

  retrieve(zipcode: string, houseNumber: number): Observable<Address> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient.get<AddressResponse>(`${this.endpoint}/address/${zipcode}/${houseNumber}`, httpOptions).pipe(
      map(addressResponse => ({
        zipcode: addressResponse.xa.xapcode,
        houseNumber: addressResponse.xa.xahuisnr,
        street: addressResponse.xa.xastrtnm,
        town: addressResponse.xa.xaplaats,
      } as Address)),
    );
  }
}
