import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as SentryAngular from '@sentry/angular';
import { SentryErrorHandler } from '@sentry/angular';
import { LanguageService } from '@service/language.service';
import { LogService } from '@service/log.service';
import { ToastService } from '@service/toast.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService implements ErrorHandler {
  private sentryErrorHandler: SentryErrorHandler;

  constructor(
    private router: Router,
    private toastService: ToastService,
    private readonly logService: LogService,
    private readonly languageService: LanguageService,
  ) {
    this.sentryErrorHandler = SentryAngular.createErrorHandler();
  }

  handleTimeout(): void {
    this.languageService.getTranslation('error.timeout').subscribe({
      next: timeoutMessage => {
        this.toastService.showToast(`${timeoutMessage}`);
      },
      error: err => {
        console.error('Could not show toast, got error from language service');

        return this.handleError(err);
      },
    });
  }

  /**
   * Catch critical errors and redirect to the error page, otherwise throw the error upstream so the calling component or service can determine what's appropriate.
   */
  handleError(error: unknown): void {
    console.error('[ErrorService] handled error response:', error);

    if (error && typeof error === 'object' && 'status' in error && 'error' in error) {
      const httpErrorResponse = error as HttpErrorResponse;

      this.logError(httpErrorResponse);
      if (httpErrorResponse.status === 404 || httpErrorResponse.status === 500) {
        this.router.navigate(['error']).catch(err => console.error(`Failed to navigate to error page : ${err}`));
      }
    } else {
      this.sentryErrorHandler.handleError(error);
    }

    throw error;
  }

  logErrorAndNavigate(error: string | HttpErrorResponse): void {
    this.sentryErrorHandler.handleError(error);
    this.router.navigate(['error']).catch(err => console.error(`Failed to navigate to error page : ${err}`));
  }

  logError(err: HttpErrorResponse): void {
    this.logService.logMessage(err.error?.message || err.message, 'error', {
      error: {
        error: err.error,
        message: err.message,
        status: err.status,
        statusText: err.statusText,
        url: err.url,
      },
    });
  }
}
