import { Injectable } from '@angular/core';
import { LogService } from '@service/log.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImageUtilsService {
  constructor(
    private readonly logService: LogService,
  ) {
  }

  convertToBase64DataUrl(dataUrl: string, format: string): Observable<string> {

    return new Observable(subscriber => {
      const img = new Image();
      img.src = dataUrl;
      img.onload = () => {
        const imgWidth = img.naturalWidth;
        const imgHeight = img.naturalHeight;

        const canvas: HTMLCanvasElement = document.createElement('canvas');
        canvas.width = imgWidth;
        canvas.height = imgHeight;

        const snapshotCtx = canvas.getContext('2d');
        if (snapshotCtx === null) {
          throw new Error('Cannot draw an image');
        }
        snapshotCtx.drawImage(img, 0, 0, imgWidth, imgHeight);

        const base64DataUrl = canvas.toDataURL(`image/${format}`);
        console.log(`Parsed dataUrl from ${dataUrl} to base64DataUrl of length ${base64DataUrl.length}`);

        subscriber.next(base64DataUrl);
        subscriber.complete();
      };
    });
  }

  getMimeType(base64DataUrl: string): string {
    const groups = base64DataUrl.match('^data:(image/[-a-zA-Z]*);');
    if (!groups || groups.length !== 2) {
      this.logService.logMessage(
        `Failed to determine MimeType for uploaded image starting with ${base64DataUrl.substring(
          0,
          Math.min(base64DataUrl.length, 20),
        )}, returning default image/jpeg`,
        'warning',
      );

      return 'image/jpeg';
    }

    return groups[1];
  }

  resizeImage(dataUrl: string, maxAllowedHeight: number, maxFileSize: number): Observable<string> {
    return new Observable(subscriber => {
      const img = new Image();
      img.src = dataUrl;
      img.onload = () => {
        const originalWidth = img.naturalWidth;
        const originalHeight = img.naturalHeight;
        const originalMimeType = this.getMimeType(dataUrl);

        const targetMimeType = this.determineTargetMimeType(originalMimeType);

        if (!this.needsResizing(originalHeight, maxAllowedHeight, dataUrl, maxFileSize)) {
          console.log(`No resize, image is only ${originalHeight} high and ${dataUrl.length} bytes`);
          subscriber.next(dataUrl);
          subscriber.complete();

          return;
        }

        let resizeWidth = originalWidth;
        let resizeHeight = originalHeight;
        let resizeSteps = 0;

        if (originalHeight > maxAllowedHeight) {
          resizeWidth = (maxAllowedHeight * originalWidth) / originalHeight;
          resizeHeight = maxAllowedHeight;
        }
        let resizedDataUrl: string;

        do {
          if (resizeSteps > 0 || this.hasChangedParameters(originalHeight, resizeHeight, originalMimeType, targetMimeType)) {
            // When the image is too large after the first resize, or we don't expect the first resize to have any effect, make the image smaller
            resizeWidth *= 0.9;
            resizeHeight *= 0.9;
          }
          resizeSteps++;

          resizedDataUrl = this.doResizeImage(resizeWidth, resizeHeight, img, targetMimeType);
        } while (resizedDataUrl.length > maxFileSize);

        this.logService.logMessage(`Resized base64 url from ${dataUrl.length} to ${resizedDataUrl.length}`, 'info', {
          originalImage: {
            mimeType: originalMimeType,
            width: originalWidth,
            height: originalHeight,
            size: dataUrl.length,
          },
          resizedImage: {
            mimeType: targetMimeType,
            width: resizeWidth,
            height: resizeHeight,
            size: resizedDataUrl.length,
            resizeSteps,
          },
        });

        subscriber.next(resizedDataUrl);
        subscriber.complete();
      };
    });
  }

  private determineTargetMimeType(originalMimeType: string) {
    let resizedMimeType = originalMimeType;
    if (resizedMimeType === 'image/png') {
      resizedMimeType = 'image/jpeg';
    }

    return resizedMimeType;
  }

  private needsResizing(originalHeight: number, maxAllowedHeight: number, dataUrl: string, maxFileSize: number) {
    return originalHeight > maxAllowedHeight || dataUrl.length > maxFileSize;
  }

  private hasChangedParameters(originalHeight: number, resizeHeight: number, originalMimeType: string, targetMimeType: string) {
    return resizeHeight === originalHeight && originalMimeType === targetMimeType;
  }

  private doResizeImage(resizeWidth: number, resizeHeight: number, img: HTMLImageElement, targetMimeType: string) {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = resizeWidth;
    canvas.height = resizeHeight;

    const snapshotCtx = canvas.getContext('2d');
    if (snapshotCtx === null) {
      throw new Error('Cannot draw an image');
    }
    snapshotCtx.drawImage(img, 0, 0, resizeWidth, resizeHeight);

    return canvas.toDataURL(targetMimeType);
  }

}
