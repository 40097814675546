import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'msm-damage-indicator-section',
  templateUrl: './damage-indicator-section.component.html',
  styleUrls: ['./damage-indicator-section.component.scss'],
})
export class DamageIndicatorSectionComponent {
  @Input({ required: true }) location!: string;
  @Input() disabled = false;
  @Output() locationSelectedEvent = new EventEmitter<string>();

  locationSelected = false;

  selectLocation(emitEvent = true): void {
    this.locationSelected = !this.locationSelected;
    if (emitEvent && !this.disabled) {
      this.locationSelectedEvent.emit(this.location);
    }
  }

  unsetLocation(): void {
    this.locationSelected = false;
  }
}
