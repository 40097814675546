<form [formGroup]="imagesForm"
      class="msm-form-container"
      *ngIf="imagesForm">

  <ion-item lines="none"
            *ngIf="question.question && !imagesForm.disabled">
    <ion-label class='ion-text-wrap'>{{ question.question }}</ion-label>
  </ion-item>

  <ion-row class="msm-images-container">
    <ion-col class="msm-images-wrapper"
             *ngFor="let child of question.children">

      <msm-image-input [question]="child"
                       [formControlName]="this.getChildCode(child.code)">
      </msm-image-input>

    </ion-col>

  </ion-row>

</form>
