<msm-page-header [title]="categoryLabel" [subTitle]="pageTitle" [helpText]="helpText"></msm-page-header>
<ion-progress-bar *ngIf="!formAndComponentsReady" type="indeterminate"
                  class="msm-page-loading-bar"></ion-progress-bar>
<ion-content>
  <div class="msm-page-container msm-page-body">
    <ion-item class="msm-logo" lines="none">
      <div class="msm-logo-holder"></div>
    </ion-item>

    <ng-container *ngIf="!formReloading">
      <ion-item class="msm-page-header" lines="none" *ngIf="pageDescription">
        <ion-label>{{ pageDescription }}</ion-label>
      </ion-item>

      <ng-container *ngIf="questions.length > 0; else isEmpty">
        <form *ngIf="answersForm" [formGroup]="answersForm" class="msm-form-container">
          <ion-list>
            <div *ngFor="let question of questions"
                 class="msm-form-question"
                 [ngClass]="{'msm-parent-question': question.children && question.children.length, 'msm-required-question': !question.metaData.validationRules?.optional}"
                 [hidden]="hideQuestion(question)">

              <ng-container [ngSwitch]="question.type">

                <msm-address-input *ngSwitchCase="'address'"
                                   [question]="question"
                                   [formGroupName]="question.code">
                </msm-address-input>

                <msm-boolean-input *ngSwitchCase="'boolean'"
                                   [question]="question"
                                   [formControlName]="question.code"
                                   (navigateTo)="handleNavigateTo($event)">
                </msm-boolean-input>

                <msm-checkbox-input *ngSwitchCase="'checkbox'"
                                    [question]="question"
                                    [formControlName]="question.code">
                </msm-checkbox-input>

                <msm-chrashr-input *ngSwitchCase="'situationsketch'"
                                   [incident]="incident"
                                   [question]="question"
                                   [formGroupName]="question.code">
                </msm-chrashr-input>

                <msm-form-type *ngSwitchCase="'form_type'"
                               [question]="question"
                               [formControlName]="question.code">
                </msm-form-type>

                <msm-damage-indicator-grid *ngSwitchCase="'vehicle_gridimages'"
                                           [formGroupName]="question.code"
                                           [question]="question">
                </msm-damage-indicator-grid>

                <msm-damage-indicator-input *ngSwitchCase="'vehicle_gridimage'"
                                            [formControlName]="question.code"
                                            [question]="question">
                </msm-damage-indicator-input>

                <msm-data-input
                  *ngSwitchCase="['text', 'email', 'phone', 'number', 'date', 'time'] | switchArrayCase:question.type"
                  [question]="question"
                  [formControlName]="question.code">
                </msm-data-input>

                <msm-datetime-input
                  *ngSwitchCase="'datetime'"
                  [question]="question"
                  [formControlName]="question.code">
                </msm-datetime-input>

                <msm-datetime-range-input
                  *ngSwitchCase="'datetimerange'"
                  [question]="question"
                  [formGroupName]="question.code">
                </msm-datetime-range-input>

                <msm-finished-redirect *ngSwitchCase="'finished'" [question]="question">
                </msm-finished-redirect>

                <msm-licenseplate-input *ngSwitchCase="'licenseplate'"
                                        [question]="question"
                                        [formControlName]="question.code">
                </msm-licenseplate-input>

                <msm-licenseplate-confirm-input *ngSwitchCase="'licenseplate_confirm'"
                                                [question]="question"
                                                [formControlName]="question.code"
                                                (navigateTo)="handleNavigateTo($event)">
                </msm-licenseplate-confirm-input>

                <msm-link *ngSwitchCase="'link'"
                          [question]="question"
                          [currentIncident]="incident"
                          [formControlName]="question.code"
                          (navigateTo)="handleNavigateTo($event)">
                </msm-link>

                <msm-link-input *ngSwitchCase="'linkcode'"
                                [question]="question"
                                [formControlName]="question.code"
                                (navigateTo)="handleNavigateTo($event)">
                </msm-link-input>

                <msm-linking-done *ngSwitchCase="'linking_done'"
                                  [question]="question"
                                  [formControlName]="question.code">
                </msm-linking-done>

                <msm-option *ngSwitchCase="'option'"
                            [question]="question"
                            [formControlName]="question.code"
                            (navigateTo)="handleNavigateTo($event)">
                </msm-option>

                <msm-persons-input *ngSwitchCase="'persons'"
                                   [question]="question"
                                   [formArrayName]="question.code">
                </msm-persons-input>

                <msm-images-input *ngSwitchCase="'images'"
                                  [question]="question"
                                  [formGroupName]="question.code">
                </msm-images-input>

                <msm-select-input *ngSwitchCase="['dropdown','leasecompany'] | switchArrayCase:question.type"
                                  [question]="question"
                                  [formControlName]="question.code">
                </msm-select-input>

                <ng-container *ngSwitchCase="'sign'">
                  <msm-sign-input *ngIf="formAndComponentsReady"
                                  [question]="question"
                                  [formGroupName]="question.code"
                                  [parentAnswersForm]="answersForm"
                                  [signPageId]="pageId"
                                  [incident]="incident"
                                  (navigateTo)="handleNavigateTo($event)">
                  </msm-sign-input>
                </ng-container>

                <msm-waiting *ngSwitchCase="'waiting'"
                             [question]="question"
                             [formControlName]="question.code"
                             (navigateTo)="handleNavigateTo($event)">
                </msm-waiting>

                <msm-finished-redirect *ngSwitchCase="'finished'" [question]="question">
                </msm-finished-redirect>

                <msm-scan-qr-input *ngSwitchCase="'scanner'"
                                   [incident]="incident"
                                   [question]="question"
                                   [formControlName]="question.code"
                                   (navigateTo)="handleNavigateTo($event)">
                </msm-scan-qr-input>

                <msm-static-text *ngSwitchCase="'staticText'"
                                 [question]="question">
                </msm-static-text>

                <msm-static-image *ngSwitchCase="'staticImage'"
                                  [question]="question">
                </msm-static-image>

                <msm-textarea-input *ngSwitchCase="'textarea'"
                                    [question]="question"
                                    [formControlName]="question.code">
                </msm-textarea-input>

                <msm-summary-page *ngSwitchCase="'summary'"
                                  class="msm-summary-page"
                                  [question]="question"
                                  [formControlName]="question.code"
                                  (summaryReady)="setComponentReady(question.code, $event)">
                </msm-summary-page>

                <ng-container *ngSwitchCase="'hidden'"></ng-container>

                <ng-container *ngSwitchDefault>
                  <ion-label>Question of type <strong>{{question.type}}</strong>: {{question.question}}</ion-label>
                  <ion-list *ngIf="question.options">
                    <ion-item *ngFor="let option of question.options">
                      <ion-label>{{option.label}}</ion-label>
                    </ion-item>
                  </ion-list>
                </ng-container>
              </ng-container>

              <div class="errors"
                   *ngIf="answersForm.get(question.code) && answersForm.get(question.code)?.errors && !answersForm.get(question.code)?.touched">
                <span class="error" *ngIf="answersForm.get(question.code)?.errors?.backendValidationError">
                  {{ answersForm.get(question.code)?.errors?.backendValidationError }}
                </span>
              </div>

            </div>
          </ion-list>
        </form>
      </ng-container>

      <ng-template #isEmpty>
        <div *ngIf="failedToLoad">
          <ion-item lines="none" color="danger">
            <ion-label>{{ 'error.forms.failedToLoad' | translate }}.</ion-label>
          </ion-item>
        </div>
      </ng-template>

    </ng-container>
  </div>
</ion-content>

<ion-footer [translucent]="true" class="msm-page-footer">
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col *ngIf="canUsePrevious" sizeXs="6">
          <ion-button id="previous-button" type="button" color="primary" fill="outline" [disabled]="!formReady"
                      (click)="previous()">
            <ion-label>
              {{ 'generic.previous' | translate }}
            </ion-label>
            <ion-icon name="arrow-back-outline" slot="start"></ion-icon>
          </ion-button>
        </ion-col>
        <ion-col *ngIf="this.showNextButton">
          <div (click)="next()">
            <ion-button id="next-button" type="button" fill="solid"
                        [color]="disabledNextButton() ? 'medium':'primary'"
                        [disabled]="disabledNextButton()">
              <ion-label>{{ 'generic.next' | translate }}</ion-label>
              <ion-icon name="arrow-forward-outline" slot="end"></ion-icon>
            </ion-button>
          </div>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <ion-button size="medium" expand="full" fill="clear">
            <a href="tel:{{supportNumber}}" class="msm-support-href">{{ supportText }}</a>
            <ion-icon slot="end" color="primary" name="call-outline" class="msm-phone-icon-align"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>

    </ion-grid>
  </ion-toolbar>
</ion-footer>
