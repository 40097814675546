<ion-item lines="none">
  <ion-label>{{question.label}} {{index + 1}}</ion-label>
</ion-item>
<form [formGroup]="answersForm" class="msm-form-container" *ngIf="answersForm">
  <ion-list>
    <ion-item *ngFor="let child of questions"
              class="msm-form-question"
              lines="none"
              [hidden]="hideQuestion(child)">
      <!-- TODO: This code is the same as on the forms-page, should it be merged? -->
      <msm-data-input *ngIf="['text', 'email', 'phone', 'number', 'date', 'time'].includes(child.type)"
                      [question]="child"
                      [formControlName]="this.getChildQuestionCode(child)">
      </msm-data-input>

      <msm-boolean-input *ngIf="child.type === 'boolean'" [question]="child" [formControlName]="this.getChildQuestionCode(child)">
      </msm-boolean-input>

      <msm-select-input *ngIf="child.type === 'dropdown'" [question]="child" [formControlName]="this.getChildQuestionCode(child)"></msm-select-input>

      <ng-container *ngIf="child.type === 'option'">
        <msm-option class="option-container" [formControlName]="this.getChildQuestionCode(child)" [question]="child">
        </msm-option>
      </ng-container>

      <ng-container *ngIf="!['text', 'email', 'phone', 'number', 'dropdown', 'date', 'time', 'boolean', 'option'].includes(child.type)">
        <ion-label>Question of type <strong>{{child.type}}</strong>: {{child.question}}</ion-label>
        <ion-list *ngIf="child.options">
          <ion-item *ngFor="let option of child.options">
            <ion-label>{{option.label}}</ion-label>
          </ion-item>
        </ion-list>
      </ng-container>

    </ion-item>
  </ion-list>
</form>
