<div [ngClass]="{'msm-required-question': !question.metaData.validationRules?.optional}">
  <ion-item class="msm-damage-indicator-question-container" lines="none">
    <ion-label class="msm-damage-indicator-question">{{question.question}}</ion-label>
  </ion-item>

  <div class="vehicle"
       [class]="this.question.metaData.vehicleType"
       [class.arrows]="this.question.metaData.includeArrows" >
    <msm-damage-indicator-section [location]="'top-left'" (locationSelectedEvent)="updateSelection($event)" [disabled]="this.disabled"></msm-damage-indicator-section>
    <msm-damage-indicator-section [location]="'top-center'" (locationSelectedEvent)="updateSelection($event)" [disabled]="this.disabled"></msm-damage-indicator-section>
    <msm-damage-indicator-section [location]="'top-right'" (locationSelectedEvent)="updateSelection($event)" [disabled]="this.disabled"></msm-damage-indicator-section>

    <msm-damage-indicator-section [location]="'middle-left'" (locationSelectedEvent)="updateSelection($event)" [disabled]="this.disabled"></msm-damage-indicator-section>
    <msm-damage-indicator-section [location]="'middle-center'" (locationSelectedEvent)="updateSelection($event)" [disabled]="this.disabled"></msm-damage-indicator-section>
    <msm-damage-indicator-section [location]="'middle-right'" (locationSelectedEvent)="updateSelection($event)" [disabled]="this.disabled"></msm-damage-indicator-section>

    <msm-damage-indicator-section [location]="'bottom-left'" (locationSelectedEvent)="updateSelection($event)" [disabled]="this.disabled"></msm-damage-indicator-section>
    <msm-damage-indicator-section [location]="'bottom-center'" (locationSelectedEvent)="updateSelection($event)" [disabled]="this.disabled"></msm-damage-indicator-section>
    <msm-damage-indicator-section [location]="'bottom-right'" (locationSelectedEvent)="updateSelection($event)" [disabled]="this.disabled"></msm-damage-indicator-section>
  </div>

  <div class="errors" *ngIf="control && control.errors && control.touched">
    <span class="error" *ngIf="control.errors.required">
      {{ 'input.errors.required' | translate: { field : question.label } }}
    </span>
  </div>
</div>
