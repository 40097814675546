import { AnswerType } from '@model/form-answers';
import { QuestionOption } from '@model/question-option.model';

export interface Question {
  children?: Question[];
  code: string;
  description: string;
  label: string;
  metaData: {
    validationRules?: {
      optional: boolean;
      max: string | number;
      min: string | number;
      regex: string;
      invalidOption: boolean;
    };
    vehicle?: VehicleMetaData;
    linkTo?: string;
    [key: string]: unknown;
    text: {
      size: string;
      color?: string;
      showHtml?: boolean;
    };
    messageOnChange?: string;
    updateOnChange: {
      [key: string]: AnswerType | null;
    };
    waiting?: {
      code: string;
      codePrefix: string;
      displayAs: string;
      pollIntervalInMilliSeconds: number;
      maxPollIterations: number;
      maxLength: number;
      validationMethod?: string;
      validationValue?: string;
    };
    prefix?: string;
    defaultValue: AnswerType;
    incidentDate: string;
    incidentTime: string;
    situationData?: Record<string, SituationData>;
  };
  options?: QuestionOption[];
  question: string;
  sequence: number;
  softCondition?: { question: string; value: string | number | boolean | string[]; actionWhenNotMet?: string };
  type: string;
  prefill: boolean;
  showInSummary: string;
}

export interface VehicleMetaData {
  licenseplate: string;
  image: string;
  brand: string;
  model: string;
}

export enum QuestionType {
  QUESTION_TYPE_HIDDEN = 'hidden',
  QUESTION_TYPE_INCIDENT_UUID = 'incident_uuid',
}

export enum WaitingDisplayAs {
  CODE = 'code',
  NONE = '',
  QR = 'qr',
  SPINNER = 'spinner',
}

export interface SituationData {
  drivingDirection: string;
  firstImpact: string;
  licensePlate: string;
  vehicleType: string;
}
