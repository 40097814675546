import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceToken } from '@model/device-token.model';
import { DossierLinkResponse } from '@model/dossier-link-response';
import { FormAnswers } from '@model/form-answers';
import { Incident, UpdateStatus } from '@model/incident.model';
import { QarDataResponse } from '@model/qardata-response';
import { SignCodeResponse } from '@model/sign-code-response';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface SaveAttachmentResponse {
  uuid: string;
  mimetype: string;
  addedForDossier: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class IncidentsApiService {

  private readonly path = 'incidents';
  private readonly endpoint: string;
  private readonly subPathAttachments = 'attachments';

  constructor(
    private readonly httpClient: HttpClient,
  ) {
    this.endpoint = `${environment.endpointUrl}/${this.path}`;
  }

  createIncident(deviceToken: DeviceToken): Observable<Incident> {
    const headers = {
      deviceToken: deviceToken.token,
    };

    return this.httpClient.post<Incident>(`${this.endpoint}`, {}, { headers });
  }

  getIncident(incidentUuid: string): Observable<Incident> {
    return this.httpClient.get<Incident>(`${this.endpoint}/${incidentUuid}`);
  }

  getSharedData(incidentUuid?: string): Observable<FormAnswers> {
    if (!incidentUuid) {
      return of({});
    }

    return this.httpClient.get<FormAnswers>(`${this.endpoint}/${incidentUuid}/sharedData`);
  }

  getAnswers(incidentUuid: string, continuationCode: string): Observable<QarDataResponse> {
    if (!incidentUuid || !continuationCode) {
      return of();
    }

    return this.httpClient.get<QarDataResponse>(`${this.endpoint}/${incidentUuid}/qarData/${continuationCode}`);
  }

  getAttachmentUrl(incidentUuid: string, attachmentUuid: string): string {
    return `${this.endpoint}/${incidentUuid}/attachments/${attachmentUuid}`;
  }

  saveAttachments(incidentUuid: string, image: Blob, mimeType: string): Observable<SaveAttachmentResponse> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': mimeType,
      }),
    };

    return this.httpClient.post<SaveAttachmentResponse>(`${this.endpoint}/${incidentUuid}/${this.subPathAttachments}`, image, httpOptions);
  }

  saveMeldingAttachments(incidentUuid: string, image: Blob, mimeType: string): Observable<SaveAttachmentResponse> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': mimeType,
      }),
    };

    return this.httpClient.post<SaveAttachmentResponse>(`${this.endpoint}/${incidentUuid}/melding/${this.subPathAttachments}`, image, httpOptions);
  }

  removeAttachment(incidentUuid: string, attachmentUuid: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.endpoint}/${incidentUuid}/${this.subPathAttachments}/${attachmentUuid}`);
  }

  requestSignCode(uuid: string): Observable<SignCodeResponse> {
    return this.httpClient.get<SignCodeResponse>(`${this.endpoint}/${uuid}/signcode`);
  }

  linkIncidentsByUuid(incidentUuid: string, linkWithIncidentUuid: string): Observable<void> {
    if (!linkWithIncidentUuid) {
      throw new Error('LinkTo is undefined');
    }

    return this.httpClient.post<void>(`${this.endpoint}/${incidentUuid}/linkTo/${linkWithIncidentUuid}`, {});
  }

  linkIncidentsByJoinCode(incidentUuid: string, linkWithJoinCode: string): Observable<void> {
    return this.httpClient.post<void>(`${this.endpoint}/${incidentUuid}/link/${linkWithJoinCode}`, {});
  }

  updateStatus(uuid: string, updateStatus: UpdateStatus): Observable<DossierLinkResponse> {
    return this.httpClient.put<DossierLinkResponse>(`${this.endpoint}/${uuid}/status`, updateStatus);
  }

  samesidedUpdateStatus(incidentUuid: string, linkedIncidentUuid: string, samesidedUpdateStatus: UpdateStatus): Observable<DossierLinkResponse> {
    return this.httpClient.put<DossierLinkResponse>(`${this.endpoint}/${incidentUuid}/status/${linkedIncidentUuid}`, samesidedUpdateStatus);
  }
}
