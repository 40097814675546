import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Category } from '@model/category.model';
import { FormAnswers } from '@model/form-answers';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class CategoryApiService {

  private readonly path = 'categories';
  private readonly endpoint: string;

  constructor(
    private readonly httpClient: HttpClient,
  ) {
    this.endpoint = `${environment.endpointUrl}/${this.path}`;
  }

  findForMenu(answers: FormAnswers): Observable<Category[]> {
    return this.httpClient.post<Category[]>(`${this.endpoint}/menu`, answers);
  }
}
