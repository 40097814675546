import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AbstractMsmInputComponent } from '@component/input/abstract-msm-input.component';
import { Incident } from '@model/incident.model';
import { ErrorService } from '@service/error.service';
import { IncidentsApiService } from '@service/incidents-api.service';
import { ToastService } from '@service/toast.service';

import { FormsPageComponent } from '../../../forms/forms-page.component';

@Component({
  selector: 'msm-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent extends AbstractMsmInputComponent implements OnInit {
  @Input({ required: true }) currentIncident!: Incident | undefined | null;
  @Output() navigateTo = new EventEmitter<string>();

  constructor(
    private readonly incidentsApiService: IncidentsApiService,
    private readonly toastService: ToastService,
    private readonly errorService: ErrorService,
    @Optional() @Self() override ngControl?: NgControl,
  ) {
    super(ngControl);
  }

  ngOnInit(): void {
    const linkedIncidentUuid = this.control?.value;
    this.incidentsApiService.linkIncidentsByUuid(this.currentIncident?.uuid ?? '', linkedIncidentUuid).subscribe({
      next: () => {
        this.toastService.showToast('component.link.linkSucces');
        this.navigateTo.emit(FormsPageComponent.NAVIGATION_REQUEST_NEXT);
      },
      error: error => {
        this.setValue('');
        this.toastService.showToast('component.link.unableTolink');
        console.error(`[LinkComponent] unable to link incidents ${this.currentIncident?.uuid} with ${linkedIncidentUuid}`, error);
        this.errorService.logError(error);

        this.navigateTo.emit(FormsPageComponent.NAVIGATION_REQUEST_NEXT);
      },
    });
  }
}
