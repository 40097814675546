import { Component, Input, OnInit } from '@angular/core';
import { FormGroupName } from '@angular/forms';
import { AbstractMsmFormComponent } from '@component/abstract-msm-form-component.directive';
import { Question } from '@model/question.model';
import { AnswersService } from '@service/answers.service';
import { LogService } from '@service/log.service';

@Component({
  selector: 'msm-datetime-range-input',
  templateUrl: './datetime-range-input.component.html',
  styleUrls: ['./datetime-range-input.component.scss'],
})
export class DatetimeRangeInputComponent extends AbstractMsmFormComponent implements OnInit {
  @Input({ required: true }) question!: Question;

  constructor(
    protected override readonly answersService: AnswersService,
    private controlContainer: FormGroupName,
    protected override readonly logService: LogService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.answersForm = this.controlContainer.control;
    this.logService.logBreadcrumb(`Set answersFrom on datetime-range-input: ${!!this.answersForm}`);

    this.parentQuestionCode = this.question.code ?? '';
    this.setDefaultAnswers();
  }

  get questions(): Question[] {
    return this.question.children ?? [];
  }

  getChildQuestionCode(childQuestion: Question): string {
    return childQuestion.code.replace(`${this.question.code}.`, '');
  }

  getChildLabel(childCode: string): string {
    const labelResult = this.questions.find(child => this.getChildQuestionCode(child) === childCode);
    if (labelResult === undefined) {
      throw new Error(`Label not found with childCode: ${childCode}`);
    }

    return labelResult.label;
  }
}
