import { Directive, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormHelper } from '@component/helper/form-helper';
import { ViewWillLeave } from '@ionic/angular';
import { AnswerType, FormAnswers } from '@model/form-answers';
import { AppendAnswer } from '@model/question-option.model';
import { Question, QuestionType } from '@model/question.model';
import { AnswersService } from '@service/answers.service';
import { LogService } from '@service/log.service';
import { ToastService } from '@service/toast.service';
import { isEqual } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

type CodeAndControl = { code: string; control: UntypedFormControl | UntypedFormGroup } | null;

@Directive()
export abstract class AbstractMsmFormComponent implements ViewWillLeave {
  softConditionEvaluations: Map<string, string> = new Map();
  answersForm = new UntypedFormGroup({});
  answers: FormAnswers = {};

  protected handlingSoftConditions = new BehaviorSubject<boolean>(false);
  protected parentQuestionCode = '';

  private softConditionCounter = 0;
  private softConditionSubscriptions: Subscription[] = [];
  private updateOnChangeSubscriptions: Subscription[] = [];
  private appendAnswerSubscriptions: Subscription[] = [];
  private messageOnChangeSubscriptions: Subscription[] = [];
  private toastDuration = 10000;
  private toastButtons: string[] = ['Ok'];

  protected constructor(
    @Optional() protected readonly logService?: LogService,
    @Optional() protected readonly toastService?: ToastService,
    @Optional() protected readonly answersService?: AnswersService,
  ) {
  }

  abstract get questions(): Question[];

  ionViewWillLeave(): void {
    if (this.logService) {
      this.logService.logMessage(
        'ionViewWillLeave: clearing subscriptions and answersForm',
        'debug',
        { parentQuestion: { code: this.parentQuestionCode } },
      );
    }

    this.clearSoftConditionSubscriptions();
    this.clearUpdateOnChangeSubscriptions();
    this.clearAppendAnswerSubscriptions();
    this.clearMessageOnChangeSubscriptions();

    this.softConditionEvaluations = new Map();
    this.answersForm = new UntypedFormGroup({});
    this.answers = {};

    this.softConditionCounter = 0;
    this.parentQuestionCode = '';
  }

  hideQuestion(question: Question): boolean {
    const softConditionEvaluation = this.softConditionEvaluations.get(question.code) ?? '';

    if (question.softCondition === undefined) {
      return false;
    }

    return ['hide', 'enabled-hide'].includes(softConditionEvaluation);
  }

  getStrippedQuestionCode(code: string): string {
    if (!this.parentQuestionCode) {
      return code;
    }

    return code.replace(`${this.parentQuestionCode}.`, '');
  }

  protected enableSoftConditions(): void {
    this.clearSoftConditionSubscriptions();
    this.handleSoftConditions();

    if (this.answersForm.enabled) {
      this.questions
        .filter(q => q.softCondition?.question)
        .forEach(q => {
          const conditionControl = this.answersForm.get(
            this.getStrippedQuestionCode(q.softCondition?.question as string),
          );
          if (conditionControl) {
            this.softConditionSubscriptions.push(
              conditionControl.valueChanges.subscribe(() => this.handleSoftCondition(q)),
            );
          }
        });
    }
  }

  protected enableUpdateOnChange(): void {
    if (!this.answersService) {
      console.error('Update on change is not supported, since no answersService is given');

      return;
    }

    this.clearUpdateOnChangeSubscriptions();

    if (this.answersForm.enabled) {
      this.questions.filter(q => q.metaData?.updateOnChange).forEach(question => this.registerUpdateOnChange(question));
    }
  }

  protected enableAppendAnswers(): void {
    if (!this.answersService) {
      console.error('Append answers is not supported, since no answersService is given');

      return;
    }

    this.clearAppendAnswerSubscriptions();

    if (this.answersForm.enabled) {
      this.questions
        .filter(q => q.options?.find(o => o.metaData?.appendAnswers))
        .forEach(question => {
          const questionControl = this.answersForm.get(this.getStrippedQuestionCode(question.code));
          if (questionControl) {
            this.appendAnswerSubscriptions.push(
              questionControl.valueChanges.subscribe(value => this.handleAppendAnswers(question, value)),
            );
          } else if (question.type === QuestionType.QUESTION_TYPE_HIDDEN && question.options) {
            question.options.forEach(option => {
              this.handleAppendAnswers(question, option.code);
            });
          } else {
            console.log('Could not find control and is not \'hidden\'', question.code);
          }
        });
    }
  }

  protected setDefaultAnswers(): void {
    const defaultAnswers = this.questions
      .filter(question => question.metaData.defaultValue !== undefined)
      .filter(question => this.hasNoAnswer(this.getStrippedQuestionCode(question.code))) // Only fill unanswered questions
      .reduce((answers, question) => {
        answers[this.getStrippedQuestionCode(question.code)] = question.metaData.defaultValue as AnswerType;

        return answers;
      }, {} as FormAnswers);

    if (Object.keys(defaultAnswers).length !== 0) {
      this.answersForm.patchValue(defaultAnswers);
      this.answersForm.markAsDirty();
    }
  }

  protected hasNoAnswer(questionCode: string): boolean {
    const control = this.answersForm.get(questionCode);

    return control === null || control.value === undefined || control.value === null;
  }

  protected enableMessageOnChange(): void {
    if (!this.answersService) {
      console.error('Message on change is not supported, since no answersService is given');

      return;
    }

    if (!this.toastService) {
      console.error('Message on change is not supported, since no toastServcie is given');

      return;
    }

    this.clearMessageOnChangeSubscriptions();

    if (this.answersForm.enabled) {
      this.questions
        .filter(q => q.metaData?.messageOnChange)
        .forEach(question => this.registerMessageOnChange(question));
    }
  }

  private registerUpdateOnChange(question: Question): void {
    const questionControl = this.answersForm.get(this.getStrippedQuestionCode(question.code));
    if (questionControl) {
      this.answersService?.getAnswer(question.code).subscribe(answerOnPageLoad => {
        if (answerOnPageLoad === undefined && question.metaData.defaultValue !== undefined) {
          answerOnPageLoad = question.metaData.defaultValue;
        }

        // Only update answers if the question really changed; not when the answer is new
        if (answerOnPageLoad !== undefined) {
          this.updateOnChangeSubscriptions.push(
            questionControl.valueChanges.subscribe({
              next: updatedAnswer => this.handleUpdateOnChange(question, answerOnPageLoad, updatedAnswer),
            }),
          );
        }
      });
    } else {
      console.log('Could not find control for question with updateOnChange', question.code);
    }
  }

  private registerMessageOnChange(question: Question): void {
    const questionControl = this.answersForm.get(this.getStrippedQuestionCode(question.code));
    if (questionControl) {
      this.answersService?.getAnswer(question.code).subscribe(answerOnPageLoad => {
        if (answerOnPageLoad === undefined && question.metaData.defaultValue !== undefined) {
          answerOnPageLoad = question.metaData.defaultValue;
        }

        if (answerOnPageLoad !== undefined) {
          this.messageOnChangeSubscriptions.push(
            questionControl.valueChanges.subscribe({
              next: updatedAnswer => this.handleMessageOnChange(question, answerOnPageLoad, updatedAnswer),
            }),
          );
        }
      });
    } else {
      console.log('Could not find control for question with messageOnChange', question.code);
    }
  }

  private handleSoftConditions(): void {
    this.questions
      .filter(question => question.softCondition !== undefined)
      .forEach(question => this.handleSoftCondition(question));
  }

  private handleSoftCondition(question: Question): void {
    this.handlingSoftConditions.next(true);
    this.softConditionCounter++;

    this.updateSoftCondition(question);

    // Set a timeout to prevent a race condition with the hiding of the element,
    // if we try to enable the control while the element is still hidden, it will not render as enabled
    setTimeout(() => {
      const questionControl = this.answersForm.get(this.getStrippedQuestionCode(question.code));
      if (!questionControl) {
        if (!FormHelper.QUESTION_TYPES_WITHOUT_CONTROLS.includes(question.type)) {
          console.warn(
            `Could not find control for ${this.getStrippedQuestionCode(
              question.code,
            )} for enabling/disabling after updating the softCondition`,
          );
        }
      } else {
        switch (this.softConditionEvaluations.get(question.code)) {
          case null: {
            if (questionControl.disabled) {
              questionControl.enable({ onlySelf: false, emitEvent: false });
            }
            break;
          }
          case 'disable': {
            if (questionControl.enabled) {
              questionControl.disable({ onlySelf: false, emitEvent: false });
            }
            break;
          }
          case 'hide': {
            if (questionControl.enabled) {
              // Disable the formControl, since the form will be invalid otherwise
              questionControl.disable({ onlySelf: false, emitEvent: false });
            }
            break;
          }
          case 'enabled-hide': {
            if (questionControl.disabled) {
              questionControl.enable({ onlySelf: false, emitEvent: false });
            }
            break;
          }
          default:
            console.error(`Cannot handle softCondition action ${this.softConditionEvaluations.get(question.code)}`);
            questionControl.enable({ onlySelf: false, emitEvent: false });
            break;
        }
      }
      this.softConditionCounter--;
      this.handlingSoftConditions.next(this.softConditionCounter > 0);
    }, 25);
  }

  private updateSoftCondition(question: Question): void {
    this.softConditionEvaluations.set(question.code, this.evaluateSoftCondition(question) as string);
  }

  private evaluateSoftCondition(question: Question): string | null | undefined {
    if (!question.softCondition || !question.softCondition.question) {
      return null;
    }

    let softConditionAnswer: AnswerType | undefined = undefined;
    const answerControl = this.answersForm.get(this.getStrippedQuestionCode(question.softCondition.question));
    // The question for the softCondition is not on this page, check previous answers
    softConditionAnswer = answerControl ? answerControl.value : this.answers[this.getStrippedQuestionCode(question.softCondition.question)];

    if (softConditionAnswer === undefined) {
      console.warn(`FormsPageComponent: set softCondition to ${question.softCondition.actionWhenNotMet} for ${question.code}; no answer found for softCondition question ${question.softCondition.question}`);

      return question.softCondition.actionWhenNotMet;
    }

    if (Array.isArray(question.softCondition.value)) {
      return question.softCondition.value.every(value => softConditionAnswer !== value) ? question.softCondition.actionWhenNotMet : null;
    } else {
      let value = question.softCondition.value;
      if (typeof question.softCondition.value === 'string') {
        value = question.softCondition.value.startsWith('!') ? question.softCondition.value.substring(1) : value;
      }

      return softConditionAnswer !== value ? question.softCondition.actionWhenNotMet : null;
    }
  }

  private clearSoftConditionSubscriptions() {
    this.softConditionSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.softConditionSubscriptions = [];
  }

  private handleUpdateOnChange(question: Question, answerOnPageLoad: AnswerType, updatedAnswer: AnswerType): void {
    // Only update answers if the question really changed; not when the answer stays the same
    if (answerOnPageLoad !== updatedAnswer) {
      for (const questionToUpdate in question.metaData.updateOnChange) {
        this.updateAnswer(questionToUpdate, question.metaData.updateOnChange[questionToUpdate]);
      }
    }
  }

  private handleAppendAnswers(question: Question, givenAnswer: string): void {
    const selectedOption = question.options?.find(option => option.code === givenAnswer);
    if (selectedOption?.metaData?.appendAnswers) {
      selectedOption.metaData.appendAnswers.forEach(appendAnswer => {
        if (appendAnswer.value !== null) {
          this.appendAnswer(appendAnswer, appendAnswer.value);
        } else if (appendAnswer.metadataValue) {
          const metadataValue = question.metaData as FormAnswers;
          this.appendWithAnswerFromMetadata(appendAnswer, metadataValue);
        } else if (appendAnswer.from) {
          const strippedQuestionCode = this.getStrippedQuestionCode(appendAnswer.from);
          this.appendWithAnswerFromQuestion(appendAnswer, strippedQuestionCode);
        } else {
          console.error('Could not appendAnswer since no source is given');

          return;
        }
      });
    }
  }

  private appendWithAnswerFromMetadata(appendAnswer: AppendAnswer, metadataValue: FormAnswers): void {
    appendAnswer.metadataValue
      .split('.')
      .forEach(
        field =>
          (metadataValue = Object.prototype.hasOwnProperty.call(metadataValue, field) ? (metadataValue[field] as FormAnswers) : {}),
      );
    if (
      metadataValue !== null &&
      typeof metadataValue === 'object' &&
      Object.getOwnPropertyNames(metadataValue).length === 0
    ) {
      console.error(
        `Could not appendAnswers since field ${appendAnswer.metadataValue} cannot be found in the metadata.`,
      );

      return;
    }

    this.appendAnswer(appendAnswer, metadataValue);
  }

  private appendWithAnswerFromQuestion(appendAnswer: AppendAnswer, strippedQuestionCode: string): void {
    if (Object.prototype.hasOwnProperty.call(this.answers, strippedQuestionCode)) {
      this.appendAnswer(appendAnswer, this.answers[strippedQuestionCode]);
    } else {
      // The answer is not on this form, try to get it from the answersStore
      this.answersService?.getAnswer(strippedQuestionCode).pipe(
        first(),
      )
        .subscribe(answer => this.appendAnswer(appendAnswer, answer));
    }
  }

  private appendAnswer(appendAnswer: AppendAnswer, answer: AnswerType) {
    if (appendAnswer.to) {
      this.updateAnswer(appendAnswer.to, answer);
    } else if (appendAnswer.toSibling) {
      this.updateAnswer(appendAnswer.toSibling, answer, true);
    } else {
      console.error('Could not appendAnswer since no target is given');
    }
  }

  private updateAnswer(questionCode: string, answer: AnswerType | null, mustBeOnForm = false) {
    const targetControl = this.answersForm.get(this.getStrippedQuestionCode(questionCode));
    if (targetControl) {
      if (targetControl.disabled) {
        // Enable the control so the value gets saved
        targetControl.enable();
      }
      targetControl.setValue(answer);
    } else if (this.parentQuestionCode) {
      if (mustBeOnForm) {
        console.error(`Could not updateAnswer, expected question ${questionCode} to be on the form.`);
      } else {
        this.answersService?.saveAnswer(questionCode, answer).subscribe();
      }
    } else {
      this.handleUpdateAnswerControl(questionCode, answer);
    }
  }

  private handleUpdateAnswerControl(questionCode: string, answer: AnswerType | null) : void {
    const codeAndControlToAdd = questionCode
      .split('.')
      .reverse()
      .map((code, index) => {
        if (index === 0) {
          return { code: code, control: new UntypedFormControl(answer) } as CodeAndControl;
        } else {
          return { code: code, control: new UntypedFormGroup({}) } as CodeAndControl;
        }
      })
      .reduce((previousValue, currentValue) => {
        if (currentValue && previousValue && currentValue.control instanceof UntypedFormGroup) {
          currentValue.control.addControl(previousValue.code, previousValue.control);
        }

        return currentValue;
      }, null);

    if (codeAndControlToAdd) {
      this.answersForm.addControl(codeAndControlToAdd.code, codeAndControlToAdd.control);
      codeAndControlToAdd.control.markAsDirty();
      this.answersForm.markAsDirty();
    }
  }

  private clearUpdateOnChangeSubscriptions() {
    this.updateOnChangeSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.updateOnChangeSubscriptions = [];
  }

  private clearAppendAnswerSubscriptions() {
    this.appendAnswerSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.appendAnswerSubscriptions = [];
  }

  private clearMessageOnChangeSubscriptions() {
    this.messageOnChangeSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.messageOnChangeSubscriptions = [];
  }

  private handleMessageOnChange(question: Question, answerOnPageLoad: AnswerType, updatedAnswer: AnswerType): void {
    if (question.metaData.messageOnChange && !isEqual(answerOnPageLoad, updatedAnswer)) {
      this.toastService?.showToast(question.metaData.messageOnChange, { color: 'primary', duration: this.toastDuration, buttons: this.toastButtons });
    }
  }
}

