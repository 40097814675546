/* eslint-disable @typescript-eslint/member-ordering */
import { Location } from '@angular/common';
import { Component, ElementRef, NgZone, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { AbstractMsmFormComponent } from '@component/abstract-msm-form-component.directive';
import { FormHelper } from '@component/helper/form-helper';
import { ProgressMenuComponent } from '@component/progress-menu/progress-menu.component';
import { MenuController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { AnswerError } from '@model/answer-error.model';
import { DeviceToken } from '@model/device-token.model';
import { AnswerType, FormAnswers } from '@model/form-answers';
import { Incident } from '@model/incident.model';
import { Page } from '@model/page.model';
import { Question, QuestionType } from '@model/question.model';
import { Context, Contexts } from '@sentry/core';
import { AnswersService } from '@service/answers.service';
import { DeviceApiService } from '@service/device-api.service';
import { DeviceService } from '@service/device.service';
import { ErrorService } from '@service/error.service';
import { IncidentService } from '@service/incident.service';
import { IncidentsApiService } from '@service/incidents-api.service';
import { LanguageService } from '@service/language.service';
import { LogService } from '@service/log.service';
import { PrefillAnswersService } from '@service/prefill-answers.service';
import { ReloadMenuService } from '@service/reload-menu.service';
import { ToastService } from '@service/toast.service';
import { filter, merge, Observable, of, Subscription, take } from 'rxjs';
import { delayWhen, first, map, mergeMap, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { PageApiService } from './page-api.service';

@Component({
  selector: 'msm-forms-page',
  templateUrl: './forms-page.component.html',
  styleUrls: ['./forms-page.component.scss'],
})
export class FormsPageComponent extends AbstractMsmFormComponent implements OnInit, ViewWillEnter, ViewWillLeave {
  static readonly NAVIGATION_REQUEST_PREFIX_CATEGORY = 'category:';
  static readonly NAVIGATION_REQUEST_PREFIX_PAGE_ID = 'pageId:';
  static readonly NAVIGATION_REQUEST_NEXT = 'next';
  static readonly NAVIGATION_REQUEST_PREVIOUS = 'previous';
  static readonly QUESTION_TYPES_TO_WAIT_FOR: string[] = ['summary'];
  static readonly QUESTION_TYPES_NOT_SHOWING_NEXT_BUTTON = ['summary', 'waiting'];

  formReady = false;
  creatingIncident = false;
  failedToLoad = false;
  answersFormEnabled = true;
  componentReadyMap = new Map<string, boolean>();

  pageId = 0;
  canUsePrevious = false;
  pageTitle = '';
  helpText: string | undefined = undefined;
  pageDescription = '';
  questions: Question[] = [];
  goToNextOnCompletion = false;
  categoryLabel = '';
  showNextButton = true;
  supportNumber = '';
  supportText = '';
  isNative = false;

  incident: Incident | null = null;
  deviceToken?: DeviceToken;
  formAndComponentsReady = false;
  formReloading = false;
  environment = '';

  private prefilledAnswerValues?: FormAnswers;
  private currentCategory?: string;
  private navigateToSubscription?: Subscription;
  private dataSubscription?: Subscription;
  private formSubscriptions: Subscription[] = [];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    protected override readonly answersService: AnswersService,
    private readonly deviceApi: DeviceApiService,
    private readonly deviceService: DeviceService,
    private readonly errorService: ErrorService,
    private readonly formApi: PageApiService,
    private readonly loadMenuService: ReloadMenuService,
    private readonly formHelper: FormHelper,
    private readonly incidentApi: IncidentsApiService,
    private readonly incidentService: IncidentService,
    private readonly languageService: LanguageService,
    private readonly location: Location,
    protected override readonly logService: LogService,
    private readonly menuController: MenuController,
    private readonly prefillAnswersService: PrefillAnswersService,
    private readonly zone: NgZone,
    protected override readonly toastService: ToastService,
    private readonly el: ElementRef,
  ) {
    super();

    this.dataSubscription = activatedRoute.data.subscribe(changes => {
      this.incident = changes.incident;
      this.answers = changes.answers;
    });

    this.isNative = Capacitor.isNativePlatform() && Capacitor.getPlatform() !== 'web';

    this.languageService
      .getTranslation('pages.footer.text.supportPhoneNumber')
      .subscribe(translation => (this.supportNumber = translation));
    this.languageService
      .getTranslation('pages.footer.text.supportPhoneText')
      .subscribe(translation => (this.supportText = translation));
  }

  ionViewWillEnter(): void {
    this.incident = this.activatedRoute.snapshot.data.incident;
    this.answers = this.activatedRoute.snapshot.data.answers;

    this.prefillAnswersService.loadAnswers().subscribe(answers => {
      this.prefilledAnswerValues = answers;
      if (this.answersForm) {
        this.setPrefilledAnswers();
      }
    });

    this.incidentApi.getSharedData(this.incident?.uuid).subscribe(sharedData => {
      AnswersService.mergeAllExceptArrays(this.answers, sharedData);
      this.answersService.saveAnswers(this.answers).subscribe();
      this.updateCategory(this.activatedRoute.snapshot.paramMap.get('pageName'));
      this.logService.logBreadcrumb('Enable menu from forms-page.component', 'msm-custom', 'debug');
      this.loadMenuService.reloadMenu();
      this.menuController.enable(true, ProgressMenuComponent.MENU_ID);
    });
  }

  override ionViewWillLeave(): void {
    if (this.answersForm) {
      this.answersService.saveAnswers(this.formAnswers).subscribe();
    }

    super.ionViewWillLeave();

    this.clearSubscriptions();
  }

  ngOnInit(): void {
    this.environment = environment.environment;
  }

  disabledNextButton(): boolean {
    return (
      !this.formReady ||
      !this.answersForm ||
      (!this.answersForm.valid && !this.answersForm.disabled) ||
      this.creatingIncident
    );
  }

  next(): void {
    this.clearSubscriptions();

    if (this.answersForm) {
      this.answersForm.updateValueAndValidity({ onlySelf: false });
      this.answersForm.markAllAsTouched();

      if (this.answersForm.valid) {
        this.storePrefillAnswers();
        this.saveAnswersBeforeNavigation(this.incident?.uuid).subscribe(() => this.callNext());
      } else if (this.answersForm.disabled) {
        // Form is disabled, no need to save the answers
        this.callNext();
      } else {
        const firstInvalidField = this.getFirstInvalidField('', this.answersForm);
        if (firstInvalidField) {
          this.setFocusOnField(firstInvalidField);
        }
      }
    } else {
      this.callNext();
    }
  }

  previous(): void {
    if (!this.canUsePrevious) {
      console.error(`Cannot go to previous page from pageId "${this.pageId}"`);

      return;
    }

    this.clearSubscriptions();

    this.setFormReady(false);
    this.saveAnswersBeforeNavigation(this.incident?.uuid)
      .pipe(mergeMap(() => this.formApi.previous(this.answers, this.pageId)))
      .subscribe({
        next: result => {
          this.createPage(result);
        },
        error: error => {
          this.setFormReady(true);
          throw error;
        },
      });
  }

  get formAnswers(): FormAnswers {
    return this.answersForm.getRawValue();
  }

  updateAnswers(answersToUpdate: Map<string, string>): void {
    const updatesInFormStructure: { [key: string]: string } = {};
    answersToUpdate.forEach((value, key) => (updatesInFormStructure[key] = value));
    this.answersForm.patchValue(updatesInFormStructure);
    answersToUpdate.forEach((_, key) => {
      this.answersForm.get(key)?.markAsDirty();
    });
  }

  handleNavigateTo(navigateTo: string): void {
    this.navigateToSubscription = this.handlingSoftConditions.pipe(first(isHandling => !isHandling)).subscribe({
      next: () => {
        if (navigateTo === FormsPageComponent.NAVIGATION_REQUEST_NEXT) {
          this.next();
        } else if (navigateTo === FormsPageComponent.NAVIGATION_REQUEST_PREVIOUS) {
          this.previous();
        } else if (navigateTo.startsWith(FormsPageComponent.NAVIGATION_REQUEST_PREFIX_CATEGORY)) {
          this.saveAnswersBeforeNavigation(this.incident?.uuid ?? '').subscribe({
            next: () =>
              this.loadCategory(navigateTo.substring(FormsPageComponent.NAVIGATION_REQUEST_PREFIX_CATEGORY.length)),
            error: () => this.setFormReady(true),
          });
        } else if (navigateTo.startsWith(FormsPageComponent.NAVIGATION_REQUEST_PREFIX_PAGE_ID)) {
          const pageId = parseInt(navigateTo.substring(FormsPageComponent.NAVIGATION_REQUEST_PREFIX_PAGE_ID.length));

          if (isNaN(pageId)) {
            console.error(`Could not handle navigation request ${navigateTo}`);
            this.setFormReady(true);

            return;
          }

          this.saveAnswersBeforeNavigation(this.incident?.uuid ?? '').subscribe({
            next: () => this.loadPage(pageId),
            error: err => {
              this.setFormReady(true);
              this.toastService.showToastKey(err, { color: 'danger' });
            },
          });
        } else {
          console.error(`Could not handle navigation request ${navigateTo}`);
        }
      },
      complete: () => {
        console.debug('Done handling softCondition validation before navigation');
      },
    });
  }

  /**
   * Flag a component for a question as ready
   * @param questionCode The code of the question that is ready (or not)
   * @param ready Whether the component is ready
   */
  setComponentReady(questionCode: string, ready: boolean): void {
    this.componentReadyMap.set(questionCode, ready);

    this.logService.logBreadcrumb(
      `Received event: ${questionCode} is ${
        ready ? '' : 'NOT '
      }ready. Still waiting for the following components: [${this.getQuestionsToWaitForDescription()}].`,
      'msm-forms-page',
      'debug',
      'debug',
    );

    this.formAndComponentsReady = this.isFormAndComponentsReady();
  }

  private setFormReady(formReady: boolean) {
    this.formReady = formReady;
    this.formAndComponentsReady = this.isFormAndComponentsReady();
  }

  /**
   * Returns whether both the form and all components the application might need to wait for are ready.
   */
  private isFormAndComponentsReady(): boolean {
    return this.formReady && !Array.from(this.componentReadyMap.values()).some(isReady => isReady === false);
  }

  private updateCategory(newCategory: string | null): void {
    if (this.currentCategory !== newCategory) {
      this.setFormReady(false);
      this.formReloading = true;
      this.currentCategory = newCategory ?? '';
      if (this.currentCategory) {
        this.loadCategory(this.currentCategory);
      } else {
        this.formApi.next(this.answers).subscribe({
          next: result => this.createPage(result, true),
          error: err => {
            console.error(`Failed to retrieve form elements for next page : ${err}`);
            this.questions = [];
            this.setFormReady(true);
            this.formReloading = false;
            this.failedToLoad = true;
          },
        });
      }
    }
  }

  private loadCategory(categoryCode: string): void {
    this.clearSubscriptions();

    this.formApi.getForm(categoryCode, this.answers).subscribe({
      next: result => this.createPage(result),
      error: err => {
        console.error(`Failed to retrieve form elements for category page ${categoryCode} : ${err}`);
        this.questions = [];
        this.setFormReady(true);
      },
    });
  }

  private loadPage(pageId: number): void {
    this.clearSubscriptions();

    this.formApi.getPage(pageId, this.answers).subscribe({
      next: result => this.createPage(result),
      error: err => {
        console.error(`Failed to retrieve form elements for page ${pageId} : ${err}`);
        this.questions = [];
        this.setFormReady(true);
      },
    });
  }

  private callNext(): void {
    this.setFormReady(false);
    this.formApi.next(this.answers, this.pageId).subscribe({
      next: result => {
        this.loadMenuService.reloadMenu();
        const currentPageId = this.pageId;
        this.createPage(result);

        if (result.errors && result.errors.length > 0 && currentPageId >= result.pageId) {
          const errorContexts = {} as Contexts;
          result.errors.forEach(
            (error, index) => (errorContexts[`error${index}`] = Object.assign({} as Context, error)),
          );

          this.logService.logMessage(
            `Got form errors on page ${this.pageId} (${result.errors.map(error => error.code).join(';')})`,
            'debug',
            errorContexts,
          );
          this.updateErrors(result.errors);
        }
      },
      error: error => {
        this.setFormReady(true);
        throw error;
      },
    });
  }

  /**
   * Add backend validation errors to the form
   * @param errors
   * @private
   */
  private updateErrors(errors: AnswerError[]): void {
    setTimeout(() => {
      errors.forEach(error => {
        const fieldInError = this.answersForm.get(error.code);
        if (fieldInError) {
          fieldInError.setErrors({ backendValidationError: error.description });
        } else {
          this.toastService.showToastKey(error.description, { color: 'danger' });
        }
      });
    }, 0);
  }

  private createPage(result: Page, replaceHistory = false): void {
    this.logService.logBreadcrumb(
      `Loading page ${result.category.code}/${result.pageId}`,
      'msm-page-load',
      'info',
      'default',
      {
        title: result.pageTitle,
        errors: result.errors?.map(error => `${error.code}:${error.errorCode}`),
      },
    );

    if (replaceHistory) {
      this.currentCategory = result.category.code;
      this.location.replaceState(`questions/${result.category.code}`);
    } else if (this.currentCategory !== result.category.code) {
      this.currentCategory = result.category.code;
      this.location.go(`questions/${result.category.code}`);
    }

    this.zone.run(() => {
      this.pageId = result.pageId;
      this.pageTitle = result.pageTitle;
      this.canUsePrevious = result.canUsePrevious;
      this.helpText = result.helpText;
      this.pageDescription = result.pageDescription;
      this.goToNextOnCompletion = result.goToNextOnCompletion;
      this.categoryLabel = result.category.label;
      this.questions = this.handleAndRemoveSpecialQuestions(result.questions);

      // Don't show the 'next' button for specific question type (eg; summary)
      this.showNextButton = !this.questions.some(q =>
        FormsPageComponent.QUESTION_TYPES_NOT_SHOWING_NEXT_BUTTON.includes(q.type),
      );

      this.createForm();
    });
  }

  private handleAndRemoveSpecialQuestions(questions: Question[]): Question[] {
    const unansweredIncidentUuidQuestions = questions
      .filter(question => question.type === QuestionType.QUESTION_TYPE_INCIDENT_UUID)
      .filter(question => !this.answers[question.code]);

    if (unansweredIncidentUuidQuestions.length) {
      let createMainIncidentSubscription: Subscription | null = null;

      const myIncidentQuestion = unansweredIncidentUuidQuestions.find(question => !question.metaData.linkTo);
      if (myIncidentQuestion) {
        this.creatingIncident = true;
        createMainIncidentSubscription = merge(
          this.incidentService.getIncident().pipe(
            filter(result => !!result),
            tap(() => this.logService.logMessage('Needed to load Incident from incidentService', 'warning')),
          ),
          this.deviceApi.createDeviceToken().pipe(
            tap(deviceToken => (this.deviceToken = deviceToken)),
            mergeMap(deviceToken => this.deviceService.storeDeviceToken(deviceToken)),
            mergeMap(deviceToken => this.createIncident(myIncidentQuestion, deviceToken)),
            delayWhen(createdIncident => this.incidentService.storeCurrentIncident(createdIncident)),
            tap(() => this.logService.logMessage('Created new incident', 'debug')),
          ),
        )
          .pipe(take(1))
          .subscribe(createdIncident => {
            this.creatingIncident = false;
            this.incident = createdIncident;
          });
      }
      this.processLinkIncidentQuestions(unansweredIncidentUuidQuestions, createMainIncidentSubscription);
    }

    return questions.filter(question => question.type !== QuestionType.QUESTION_TYPE_INCIDENT_UUID);
  }

  private processLinkIncidentQuestions(
    unansweredIncidentUuidQuestions: Question[],
    createMainIncidentSubscription: Subscription | null,
  ) {
    const linkIncidentQuestions = unansweredIncidentUuidQuestions.filter(question => question.metaData.linkTo);

    if (linkIncidentQuestions.length) {
      merge(of(this.deviceToken), this.deviceService.loadDeviceToken())
        .pipe(first(token => !!token))
        .subscribe(deviceToken => {
          if (deviceToken !== undefined) {
            linkIncidentQuestions.forEach(question =>
              this.handleDeviceToken(question, deviceToken, createMainIncidentSubscription),
            );
          }
        });
    }
  }

  private handleDeviceToken(
    question: Question,
    deviceToken: DeviceToken,
    createMainIncidentSubscription: Subscription | null,
  ): void {
    if (!this.createLinkedIncident(question, deviceToken)) {
      if (createMainIncidentSubscription !== null) {
        createMainIncidentSubscription.add(() => this.linkIncidentQuestions(question, deviceToken));
      } else {
        this.errorService.logErrorAndNavigate(
          `Failed to create linked incident, since incident with key ${question.metaData.linkTo} cannot be found.`,
        );
      }
    }
  }

  private linkIncidentQuestions(question: Question, token: DeviceToken): void {
    // Wait for the main incident to be created
    if (!this.createLinkedIncident(question, token)) {
      this.errorService.logErrorAndNavigate(
        `Failed to create linked incident, since incident with key ${question.metaData.linkTo} cannot be found after main incident has been created.`,
      );
    }
  }

  /**
   * Create an incident for the question and store the uuid in the answers.
   * @param incidentQuestion
   * @param deviceToken
   */
  private createIncident(incidentQuestion: Question, deviceToken: DeviceToken): Observable<Incident> {
    return this.incidentApi.createIncident(deviceToken).pipe(
      mergeMap(incident =>
        // After saving the answers, continue with the created incident
        this.answersService.saveAnswer(incidentQuestion.code, incident.uuid).pipe(
          tap(updatedAnswers => (this.answers = updatedAnswers)),
          map(() => incident),
        ),
      ),
    ) as Observable<Incident>;
  }

  private createLinkedIncident(incidentQuestion: Question, deviceToken: DeviceToken): boolean {
    if (incidentQuestion.metaData.linkTo && this.answers[incidentQuestion.metaData.linkTo]) {
      const linkWithIncidentUuid = this.answers[incidentQuestion.metaData.linkTo] as string;

      this.createIncident(incidentQuestion, deviceToken)
        .pipe(mergeMap(incident => this.incidentApi.linkIncidentsByUuid(incident.uuid, linkWithIncidentUuid)))
        .subscribe({
          next: () => {
            this.creatingIncident = false;
          },
          error: error => {
            this.errorService.logErrorAndNavigate(
              `Failed to create or link incident ${error && error.message ? error.message : error}`,
            );
          },
        });

      return true;
    }

    return false;
  }

  private createForm(): void {
    this.updateComponentReadyMap();
    const hadAnswersForm = !!this.answersForm;
    this.answersForm = this.formHelper.createFormControls(this.questions, undefined, this.answers);
    this.logService.logBreadcrumb(
      `${hadAnswersForm ? 'Updated' : 'Set'} answersFrom on forms-page: ${!!this.answersForm}`,
    );

    this.answersForm.patchValue(this.answers);
    if (this.answersForm.enabled) {
      this.setPrefilledAnswers();
      this.setDefaultAnswers();
    }

    this.enableSoftConditions();
    this.enableUpdateOnChange();
    this.enableAppendAnswers();
    this.enableMessageOnChange();

    if (this.goToNextOnCompletion) {
      this.enableNextOnCompletion();
    }

    this.setFormReady(true);
    this.formReloading = false;
    this.failedToLoad = false;
  }

  /**
   * Retrieves the sharedData if available and combines it with the answers on the form if the form is dirty.
   * The combination will be saved and set in the answers field.
   * @param incidentUuid
   */
  private saveAnswersBeforeNavigation(incidentUuid: string | undefined): Observable<void> {
    if (incidentUuid) {
      return this.incidentApi.getSharedData(incidentUuid).pipe(
        mergeMap(sharedData => {
          const updateAnswers = { ...sharedData };

          if (this.answersForm?.dirty) {
            AnswersService.mergeAllExceptArrays(updateAnswers, this.answersForm.getRawValue());
          }

          return this.answersService.saveAnswers(updateAnswers);
        }),
        tap(mergedAnswers => (this.answers = mergedAnswers)),
        map(() => undefined),
      );
    }

    if (this.answersForm.dirty) {
      return this.answersService.saveAnswers(this.answersForm.getRawValue()).pipe(
        tap(mergedAnswers => (this.answers = mergedAnswers)),
        map(() => undefined),
      );
    }

    return of(undefined);
  }

  private setPrefilledAnswers(): void {
    if (!this.prefilledAnswerValues) {
      return;
    }

    const answersToUpdate = this.questions
      .filter(question => question.prefill) // Only consider questions that need to be prefilled
      .filter(
        question =>
          this.prefilledAnswerValues &&
          this.prefilledAnswerValues[question.code] !== undefined &&
          this.prefilledAnswerValues[question.code] !== null,
      ) // Only consider questions that have a prefilled answer stored
      .flatMap(question => (question.children ? question.children : question))
      .filter(question => this.hasNoAnswer(question.code)) // Only fill unanswered questions
      .reduce((answers, question) => this.processSetPrefilledAnswers(answers, question), {} as FormAnswers);

    if (Object.keys(answersToUpdate).length !== 0) {
      this.answersForm.patchValue(answersToUpdate);
      this.answersForm.markAsDirty();
    }
  }

  private processSetPrefilledAnswers(answers: FormAnswers, question: Question): FormAnswers {
    if (question.code.indexOf('.') < 0) {
      answers[question.code] = this.prefilledAnswerValues ? this.prefilledAnswerValues[question.code] : '';
    } else {
      const splitQuestionCode = question.code.split('.');
      const parentCode = splitQuestionCode[0];
      const childCode = splitQuestionCode[1];

      if (!answers[parentCode]) {
        answers[parentCode] = {};
      }

      let formAnswer: AnswerType = '';
      if (this.prefilledAnswerValues) {
        const prefilledParents = this.prefilledAnswerValues[parentCode] as FormAnswers;
        formAnswer = prefilledParents[childCode];
      }

      const parentAnswers = answers[parentCode] as FormAnswers;
      parentAnswers[childCode] = formAnswer;
    }

    return answers;
  }

  private enableNextOnCompletion(): void {
    const autoGoToNextSubscription = this.answersForm.statusChanges.subscribe(status => {
      if (status === 'VALID') {
        autoGoToNextSubscription.unsubscribe();
        this.next();
      }
    });
    this.formSubscriptions.push(autoGoToNextSubscription);
  }

  private clearSubscriptions() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    if (this.navigateToSubscription) {
      this.navigateToSubscription.unsubscribe();
    }
    this.formSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.formSubscriptions = [];
  }

  private storePrefillAnswers(): void {
    const combinedFormAnswers = { ...this.answers, ...this.answersForm.getRawValue() };
    const answerObj: FormAnswers = {};
    let prefillQuestionAvailable = false;

    for (const question of this.questions) {
      if (question.prefill) {
        prefillQuestionAvailable = true;
        answerObj[question.code] = combinedFormAnswers[question.code];
      }
    }

    if (prefillQuestionAvailable) {
      this.prefillAnswersService.saveAnswers(answerObj).subscribe();
    }
  }

  private setFocusOnField(field: string): void {
    let invalidControl = this.el.nativeElement.querySelector(`[name="${field}"]`);
    if (!invalidControl) {
      invalidControl = this.el.nativeElement.querySelector(`[ng-reflect-name="${field}"]`);
    }

    if (invalidControl) {
      let parentElement = invalidControl;
      let counter = 0;
      while (counter <= 3 && !parentElement.classList.contains('msm-form-question')) {
        parentElement = parentElement.parentNode;
        counter++;
      }

      if (parentElement.classList.contains('msm-form-question')) {
        parentElement.scrollIntoView({ behavior: 'smooth' });
      } else {
        invalidControl.parentNode.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  private updateComponentReadyMap(): void {
    this.componentReadyMap.clear();
    this.questions
      .filter(q => FormsPageComponent.QUESTION_TYPES_TO_WAIT_FOR.includes(q.type))
      .map(q => q.code)
      .forEach(code => this.componentReadyMap.set(code, false));

    if (this.componentReadyMap.size > 0) {
      this.logService.logBreadcrumb(
        `Waiting for the following components to be ready: [${this.getQuestionsToWaitForDescription()}].`,
        'msm-forms-page',
        'debug',
        'debug',
      );
    }

    this.formAndComponentsReady = this.isFormAndComponentsReady();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  protected getFirstInvalidField(formControlKey: string, control: AbstractControl): string | undefined {
    if ('controls' in control) {
      const controlWithControls = control as UntypedFormArray | UntypedFormGroup;

      if (!controlWithControls.controls) {
        return undefined;
      }

      return this.getFirstInvalidFieldGroup(controlWithControls);
    } else {
      // The AbstractControl is a FormControl
      if (!control.valid) {
        return formControlKey;
      } else {
        return undefined;
      }
    }
  }

  private getFirstInvalidFieldGroup(controlWithControls: UntypedFormArray | UntypedFormGroup): undefined | string {
    if (Array.isArray(controlWithControls.controls)) {
      // The AbstractControl is a FormArray
      const invalidControl = controlWithControls.controls.find(formGroup => formGroup.invalid) as AbstractControl;
      if (!invalidControl) {
        return undefined;
      }

      return this.getFirstInvalidField('', invalidControl);
    } else {
      // The AbstractControl is a FormGroup
      const foundValue = Object.entries(controlWithControls.controls).find(([, formControl]) => formControl?.invalid);
      if (!foundValue) {
        return undefined;
      }

      const [fieldKey, controlFound] = foundValue;

      return this.getFirstInvalidField(fieldKey, controlFound);
    }
  }

  private getQuestionsToWaitForDescription(): string {
    return Array.from(this.componentReadyMap.entries())
      .filter((entry: [string, boolean]) => entry[1] === false)
      .map((entry: [string, boolean]) => entry[0])
      .join(', ');
  }
}

