import { Component, EventEmitter, Optional, Output, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AbstractMsmInputComponent } from '@component/input/abstract-msm-input.component';
import { QuestionOption } from '@model/question-option.model';

@Component({
  selector: 'msm-option',
  templateUrl: 'option-input.component.html',
  styleUrls: ['option-input.component.scss'],
})
export class OptionInputComponent extends AbstractMsmInputComponent {

  @Output() navigateTo = new EventEmitter<string>();

  currentValue: string | undefined;

  constructor(
  @Optional() @Self() ngControl?: NgControl,
  ) {
    super(ngControl);
  }

  optionClicked(option: QuestionOption): void {
    this.currentValue = option.code;
    if (option.metaData && option.metaData.goTo) {
      // In case of an invalid option, reset currentvalue.
      if (option.metaData.invalidOption) {
        this.currentValue = undefined;
      }
      this.onChange(this.currentValue);
      this.navigateTo.emit(option.metaData.goTo);
    } else {
      this.onChange(this.currentValue);
    }
  }

  override setValue(value: string): void {
    this.currentValue = value;
  }

  setDivergingDisplay(option: QuestionOption): string {
    if (option.metaData?.display) {
      if (option.code === this.currentValue) {
        return `importance-${option.metaData.display.importance} contrast`;
      }

      return `importance-${option.metaData.display.importance}`;
    } else {
      return '';
    }
  }
}
