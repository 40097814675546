import EventEmitter from 'events';

import { Directive, Input, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl, ValidationErrors } from '@angular/forms';
import { Question } from '@model/question.model';

@Directive()
export abstract class AbstractMsmInputComponent implements ControlValueAccessor {
  @Input({ required: true }) question!: Question;

  @Output() protected valueChanges = new EventEmitter();
  protected isDisabled = false;

  get control(): UntypedFormControl | undefined {
    const foundControl = this.ngControl?.control;

    return (foundControl)? foundControl as UntypedFormControl: undefined;
  }

  get errors(): ValidationErrors | null {
    return this.ngControl?.errors ?? null;
  }

  get disabled(): boolean {
    return this.isDisabled;
  }

  protected constructor(public ngControl?: NgControl) {
    if (this.ngControl) {
      // Setting the value accessor directly (instead of using the providers)
      // so we don't need to link the valueAccessor functions to the input
      this.ngControl.valueAccessor = this;
    }
  }

  disable(_disabled: boolean): void {
    // Overwrite when disabling should do more than just set the control to disabled
  }

  setValue(
    _value: string | boolean | number | string[] | { [p: string]: string | boolean | number | undefined } | undefined,
  ): void {
    // Overwrite when setValue should do more than just set the value of the control
  }

  /* Implementation of ControlValueAccessor */
  // eslint-disable-next-line
  protected onChange = (v: any) => {};

  // eslint-disable-next-line
  protected onTouched = () => {};

  // eslint-disable-next-line
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string | boolean | number | undefined): void {
    this.setValue(value);
  }

  /**
   * Function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * Depending on the status, it enables or disables the appropriate DOM element.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.disable(this.isDisabled);
  }
}
