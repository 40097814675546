import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormHelper } from '@component/helper/form-helper';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
  ],
  exports: [
    CommonModule,
    IonicModule,
    TranslateModule,
  ],
  providers: [
    FormHelper,
  ],
})
export class SharedModule {
}
