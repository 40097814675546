<ion-item *ngIf="question && control">
  <ion-select [name]="question.code"
              [formControl]="control"
              [placeholder]="question.description"
              [interface]="ionSelectInterface"
              label="{{question.question}}"
              labelPlacement="floating"
              class='ion-text-wrap'>
    <ion-select-option *ngFor="let option of question.options" [value]="option.code">{{option.label}}</ion-select-option>
  </ion-select>
</ion-item>

<!-- Error messages -->
<!-- TODO: Extract and style; only show first error? -->
<div class="errors" *ngIf="errors && ngControl?.touched">
  <span class="error" *ngIf="errors.required">
    {{ 'input.errors.required' | translate: { field : question.label } }}
  </span>
</div>
