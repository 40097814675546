<div class="msm-video-container" [hidden]="!scannerReady" [ngClass]="{ 'error': errorMessage !== null || errors }">
  <video #qrScanPreview></video>
</div>

<div class="msm-message-container">
  <span *ngIf="message">{{message}}</span>
  <div class="errors" *ngIf="errorMessage || (errors && ngControl?.touched)">
    <span class="error" *ngIf="errorMessage">
      {{ errorMessage }}
    </span>
    <span class="error" *ngIf="!errorMessage && errors?.required">
      {{ 'component.scan.error.required' | translate }}
    </span>
  </div>
</div>
