export const baseEnvironment = {
  ios: {
    bundleId: 'nl.verzekeraars.mobielschademelden',
    buildNumber: 2,
    displayName: 'Schademelden',
  },
  android: {
    displayName: 'Schademelden',
    packageName: 'nl.abz.os',
  },
  name: 'mobielschademelden-herbouw',
  sentry: {
    dsn: 'https://2c78c7cb64e84a7a9d18725792969731@sentry.flusso.nl/25',
  },
  version: '6.1.0',
  youtubeReferenceLink: 'wBUP0mRtQ7I',
};
