import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import * as _ from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface TranslationResource {
  prefix: string;
  suffix: string;
}

export class MultipleTranslationsHttpLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private resources: TranslationResource[],
  ) {
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getTranslation(lang: string): Observable<any> {
    const requests = this.resources.map(resource => {
      const path = resource.prefix + lang + resource.suffix;

      return this.http.get(path).pipe(catchError(() => of(null)));
    });

    const translations = {};

    return forkJoin(requests).pipe(map(res => {
      res.forEach(translation => {
        if (translation) {
          _.merge(translations, translation);
        }
      });

      return translations;
    }));
  }
}
