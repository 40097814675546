<ion-input [name]="question.code"
           class="msm-licenseplate ion-no-margin"
           placeholder="{{'component.licenseplate.placeholder' | translate}}"
           type="text"
           inputmode="text"
           [(ngModel)]="licensePlate"
           [disabled]="disabled"
           maxlength="8"
           label = "">
</ion-input>
