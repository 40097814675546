import { Component, ElementRef, Input, Optional, Self, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Camera, CameraSource, GalleryPhoto, Photo } from '@capacitor/camera';
import { ConfirmResult, Dialog } from '@capacitor/dialog';
import { AbstractMsmInputComponent } from '@component/input/abstract-msm-input.component';
import { ImageUtilsService } from '@component/utils/image-utils';
import { DeviceService } from '@service/device.service';
import { ImageStorageService } from '@service/image-storage.service';
import { ImageService } from '@service/image.service';
import { IncidentService } from '@service/incident.service';
import { IncidentsApiService } from '@service/incidents-api.service';
import { LanguageService } from '@service/language.service';
import { LogService } from '@service/log.service';
import { ToastService } from '@service/toast.service';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { EMPTY, from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

interface ImageWrapper {
  uuid: string;
  base64DataUrl: string;
}

@Component({
  selector: 'msm-image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss'],
})
export class ImageInputComponent extends AbstractMsmInputComponent {
  @Input() size: 'thumbnail' | 'full' = 'thumbnail';

  @ViewChild('fileselector', { static: false }) fileselectorRef!: ElementRef<HTMLInputElement>;

  appNoCameraPermission = '';
  appNoGalleryPermission = '';
  dialogTitle = '';
  imageLoaded = false;
  incidentUuid: string | null = null;
  image?: ImageWrapper;
  isBrowserAppOnIos = false;
  labelYes = 'ja';
  labelNo = 'nee';

  private static readonly MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
  // Max height in css is 20 rem, so that is the max height we need to store.
  private readonly maxImageServerHeight = 2048;

  constructor(
    private readonly deviceService: DeviceService,
    private readonly imageUtilsService: ImageUtilsService,
    private readonly imageService: ImageService,
    private readonly imageStorageService: ImageStorageService,
    private readonly incidentService: IncidentService,
    private readonly incidentsApi: IncidentsApiService,
    private readonly languageService: LanguageService,
    private readonly logService: LogService,
    private readonly toastService: ToastService,
    @Optional() @Self() public override ngControl?: NgControl,
  ) {
    super(ngControl);
    this.languageService.getTranslation('generic.yes').subscribe(translation => this.labelYes = translation);
    this.languageService.getTranslation('generic.no').subscribe(translation => this.labelNo = translation);
    this.languageService.getTranslation('component.image.appNoCameraPermission').subscribe(translation => this.appNoCameraPermission = translation);
    this.languageService.getTranslation('component.image.appNoGalleryPermission').subscribe(translation => this.appNoGalleryPermission = translation);
    this.languageService.getTranslation('component.image.permissionDialogTitle').subscribe(translation => this.dialogTitle = translation);
    this.isBrowserAppOnIos = this.deviceService.isBrowserAppOnIos;
  }

  get placeholderImage(): string {
    if (this.question.metaData.icon) {
      return `/assets/images/placeholder/${this.question.metaData.icon}.png`;
    } else {
      return '';
    }
  }

  onFileChoose(fileList: FileList | null): void {
    if (fileList === null) {
      return;
    }

    const pattern = /image-*/;
    const fileArray = Array.from(fileList);

    fileArray.forEach(file => {
      if (!file.type.match(pattern)) {
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          this.imageUtilsService
            .resizeImage(reader.result.toString(), this.maxImageServerHeight, ImageInputComponent.MAX_FILE_SIZE)
            .pipe(
              concatMap(resizedDataUrl => this.uploadImage(resizedDataUrl)),
              concatMap(image => this.saveImage(image)),
            )
            .subscribe();
        }
      };

      reader.readAsDataURL(file);
    });
  }

  async selectImage(source: CameraSource): Promise<void> {
    const allowed = await this.checkPermissions(source.toLowerCase());

    if (!allowed) {
      return;
    }

    this.imageService
      .selectPhoto(source)
      .pipe(
        concatMap(image => {
          if (Object.prototype.hasOwnProperty.call(image, 'dataUrl')) {
            const photo = image as Photo;
            if (photo.dataUrl) {
              return of(photo.dataUrl);
            } else {
              throw new Error('Photo has no dataurl');
            }
          } else {
            return this.imageUtilsService.convertToBase64DataUrl(
              (image as GalleryPhoto).webPath,
              image.format || 'jpeg',
            );
          }
        }),
        concatMap(dataUrl => this.imageUtilsService.resizeImage(dataUrl, this.maxImageServerHeight, ImageInputComponent.MAX_FILE_SIZE)),
        concatMap(resizedDataUrl => this.uploadImage(resizedDataUrl)),
        catchError(err => {
          this.logService.logException(err);
          this.toastService.showToastKey('component.image.failedToUpload', { color: 'danger' });

          // Prevent the rest of the pipe to execute
          return EMPTY;
        }),
        concatMap(image => this.saveImage(image)),
      )
      .subscribe();
  }

  async checkPermissions(source: string): Promise<boolean> {
    // On iOS and in a browser the Permissions API is not available and
    // navigator.permissions is not available
    if (
      this.deviceService.isBrowserApp ||
      (this.deviceService.isBrowserAppOnAndroid && source.toUpperCase() === CameraSource.Photos)
    ) {
      return true;
    }

    let permissionState = '';

    if (this.deviceService.isBrowserAppOnAndroid) {
      const { state } = await navigator.permissions.query({
        name: 'camera',
      } as unknown as PermissionDescriptor);
      permissionState = state;
    } else {
      const permissions = await Camera.checkPermissions();
      permissionState = source === 'camera' ? permissions.camera : permissions.photos;
    }

    if (permissionState === 'denied') {
      this.handleDenied(source);

      return false;
    }

    return true;
  }

  takePhotoWithCamera(): void {
    this.selectImage(CameraSource.Camera);
  }

  selectImageFromGallery(): void {
    if (
      this.deviceService.isDeviceApp ||
      this.deviceService.isBrowserAppOnAndroid ||
      this.deviceService.isBrowserAppOnIos
    ) {
      this.selectImage(CameraSource.Photos);
    } else {
      this.fileselectorRef.nativeElement.click();
    }
  }

  onImageLoadError(): void {
    this.imageLoaded = false;
    this.logService.logMessage(`Could not load image in ion-img for question ${this.question.code}`, 'error', {
      metaData: this.question.metaData,
      image: {
        uuid: this.image?.uuid || '-',
        url: this.getImageUrlDisplayValue(this.image),
      },
    });
  }

  onImageLoaded(): void {
    this.imageLoaded = true;
  }

  removeImage(): void {
    if (!this.incidentUuid) {
      this.logService.logException(new Error('No incidentUuid present'));
      this.toastService.showToastKey('component.image.failedToRemove', { color: 'danger' });

      return;
    }

    if (this.image) {
      this.incidentsApi
        .removeAttachment(this.incidentUuid, this.image.uuid)
        .pipe(
          catchError(err => {
            this.logService.logException(err);
            this.toastService.showToastKey('component.image.failedToRemove', { color: 'danger' });

            // Prevent the rest of the pipe to execute
            return EMPTY;
          }),
        )
        .subscribe(() => {
          this.imageStorageService.removeImage(this.question.code ?? '').subscribe();
          this.image = {
            uuid: `placeholder_${this.question.code}`,
            base64DataUrl: this.placeholderImage,
          } as ImageWrapper;
          this.imageLoaded = this.placeholderImage !== '';
          this.updateFormControlValue();
        });
    }
  }

  setImage(attachmentUuid: string): void {
    if (attachmentUuid) {
      this.loadImageFromStorage(attachmentUuid);
    } else {
      this.image = {
        uuid: `placeholder_${this.question.code}`,
        base64DataUrl: this.placeholderImage,
      } as ImageWrapper;
      this.imageLoaded = true;
    }
  }

  override setValue(attachmentUuid: string): void {
    if (!this.incidentUuid) {
      this.incidentService.getIncident().subscribe(incident => {
        if (incident) {
          this.incidentUuid = incident.uuid;
        }
        this.setImage(attachmentUuid);
      });
    } else {
      this.setImage(attachmentUuid);
    }
  }

  private loadImageFromStorage(imageUuid: string): void {
    if (!this.incidentUuid) {
      this.logService.logException(new Error('No incidentUuid present'));
      this.toastService.showToastKey('component.image.failedToRemove', { color: 'danger' });

      return;
    }

    const incidentUuid = this.incidentUuid;
    this.imageStorageService
      .retrieveImage(this.question.code ?? '')
      .pipe(
        map(imageData => {
          const image = {
            uuid: imageUuid,
            base64DataUrl: imageData,
          } as ImageWrapper;

          if (image.base64DataUrl === '') {
            image.base64DataUrl = this.incidentsApi.getAttachmentUrl(incidentUuid, imageUuid);
          }

          return image;
        }),
      )
      .subscribe({
        next: value => {
          this.image = value;
          this.imageLoaded = true;
        },
        error: () => {
          this.logService.logMessage(`Could not load image from storage for question ${this.question.code}`, 'error', {
            metaData: this.question.metaData,
            image: {
              uuid: imageUuid,
              url: this.getImageUrlDisplayValue(this.image),
            },
          });
          this.image = undefined;
          this.imageLoaded = true;
        },
      });
  }

  private saveImage(image: ImageWrapper): Observable<void> {
    this.image = image;
    this.updateFormControlValue();
    this.imageLoaded = true;

    return this.imageStorageService.storeImage(this.question.code ?? '', image.base64DataUrl);
  }

  private uploadImage(dataUrl: string): Observable<ImageWrapper> {
    if (!this.incidentUuid) {
      return throwError(() => new Error('No incidentUuid present'));
    }

    const incidentUuid = this.incidentUuid;

    return from(fetch(dataUrl)).pipe(
      concatMap(base64Response => from(base64Response.blob())),
      concatMap(imageBlob =>
        this.incidentsApi.saveAttachments(incidentUuid, imageBlob, this.imageUtilsService.getMimeType(dataUrl)),
      ),
      map(uploadResponse => ({ uuid: uploadResponse.uuid, base64DataUrl: dataUrl } as ImageWrapper)),
    );
  }

  private updateFormControlValue(): void {
    this.onChange(!this.image || this.image.uuid.startsWith('placeholder_') ? null : this.image.uuid);
  }

  private getImageUrlDisplayValue(image?: ImageWrapper): string {
    let imageUrlDisplayValue = '-';
    if (image && image.base64DataUrl) {
      imageUrlDisplayValue =
        image.base64DataUrl.length > 100 ? `${image.base64DataUrl.substring(0, 97)}...` : image.base64DataUrl;
    }

    return imageUrlDisplayValue;
  }

  private async handleDenied(source: string): Promise<void> {
    if (this.deviceService.isDeviceApp) {
      let message = this.appNoGalleryPermission;
      if (source === CameraSource.Camera) {
        message = this.appNoCameraPermission;
      }
      const dialogResult: ConfirmResult = await Dialog.confirm({
        title: this.dialogTitle,
        message,
        cancelButtonTitle: this.labelNo,
        okButtonTitle: this.labelYes,
      });

      if (dialogResult.value) {
        await NativeSettings.open({
          optionAndroid: AndroidSettings.ApplicationDetails,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          optionIOS: IOSSettings.App,
        });
      }
    } else {
      this.toastService.showToastKey('component.image.browserNoCameraPermission', { color: 'danger' });
    }
  }
}
