import { Component, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AbstractMsmInputComponent } from '@component/input/abstract-msm-input.component';

@Component({
  selector: 'msm-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
})
export class SelectInputComponent extends AbstractMsmInputComponent implements OnInit {
  ionSelectInterface: undefined | 'action-sheet' | 'alert' | 'popover';

  constructor(
    @Optional() @Self() override ngControl?: NgControl,
  ) {
    super(ngControl);
  }

  ngOnInit(): void {
    if (this.question.type === 'dropdown') {
      this.ionSelectInterface = 'popover';
    }

    // Sort the questionOptions by their label
    this.question.options?.sort((questionOption1, questionOption2) =>
      questionOption1.label.localeCompare(questionOption2.label),
    );
  }
}
