import { Component, EventEmitter, OnInit, Optional, Output, QueryList, Self, ViewChild, ViewChildren } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AbstractMsmInputComponent } from '@component/input/abstract-msm-input.component';
import { IonRadio, IonRadioGroup } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';

interface BooleanOption {
  value: string | boolean;
  label: string;
}

@Component({
  selector: 'msm-boolean-input',
  templateUrl: './boolean-input.component.html',
  styleUrls: ['./boolean-input.component.scss'],
})
export class BooleanInputComponent extends AbstractMsmInputComponent implements OnInit {
  @ViewChild(IonRadioGroup, { static: false }) ionRadioGroup?: IonRadioGroup;
  @ViewChildren(IonRadio) radioButtons?: QueryList<IonRadio>;

  @Output() navigateTo = new EventEmitter<string>();

  options: BooleanOption[] = [];

  private readonly defaultLabelYes = 'generic.yes';
  private readonly defaultLabelNo = 'generic.no';

  constructor(
    private readonly translateService: TranslateService,
    @Optional() @Self() override ngControl?: NgControl,
  ) {
    super(ngControl);
  }

  override setValue(value: boolean): void {
    if (this.ionRadioGroup) {
      this.ionRadioGroup.value = value;
    }
  }

  override disable(isDisabled: boolean): void {
    (this.radioButtons || []).forEach(radioButton => {
      radioButton.disabled = isDisabled;
    });
  }

  ngOnInit(): void {
    if (this.question.options && this.question.options.length) {
      this.options = this.question.options.map(o => ({ value: o.code, label: o.label } as BooleanOption));

      this.control?.valueChanges.subscribe(newValue => {
        if (newValue !== undefined && newValue !== null) {
          const matchedOption = this.question.options?.find(o => o.code === newValue);
          if (matchedOption && matchedOption.metaData && matchedOption.metaData.goTo) {
            this.navigateTo.emit(matchedOption.metaData.goTo);
          }
        }
      });

    } else {
      forkJoin([this.translateService.get(this.defaultLabelYes), this.translateService.get(this.defaultLabelNo)])
        .subscribe(([yesLabel, noLabel]) => {
          this.options = [
            { value: true, label: yesLabel } as BooleanOption,
            { value: false, label: noLabel } as BooleanOption,
          ];
        });
    }
  }
}
