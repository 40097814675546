import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationExtras, Router } from '@angular/router';

import { DeviceService } from './device.service';

@Injectable({
  providedIn: 'root',
})
export class SkipLandingPageGuard  {

  constructor(
    private readonly deviceService: DeviceService,
    private router: Router,
  ) {
  }

  async canActivate(routeSnapshot: ActivatedRouteSnapshot): Promise<boolean> {

    if (!this.deviceService.isInitialized) {
      await this.deviceService.initialize();
    }

    const extras: NavigationExtras = {
      queryParams: routeSnapshot.queryParams,
    };

    if (this.deviceService.isBrowserAppOnIos ||
      this.deviceService.isBrowserAppOnAndroid ||
      this.deviceService.isDeviceApp) {
      this.router.navigate(['getting-started'], extras);

      return false;
    }

    return true;
  }
}
