import { Injectable } from '@angular/core';
import * as SentryAngular from '@sentry/angular';
import { Breadcrumb, Context, Contexts, Primitive } from '@sentry/core';
import { IncidentService } from '@service/incident.service';
import { Observable, of } from 'rxjs';

import { environment } from '../../environments/environment';

export interface PlatformContext extends Context {
  platforms: string[];
  deviceApp?: boolean;
  browserApp?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LogService {
  static readonly CONTEXT_KEY_ANSWERS = 'answers';

  constructor(private readonly incidentService: IncidentService) {
    this.incidentService.getIncident().subscribe(incident => {
      console.debug('Update incident used for Sentry logging', incident);
      SentryAngular.setContext('incident', incident ?? null);
      SentryAngular.setTag('incident.uuid', incident ? incident.uuid : null);
    });
  }

  initialize(): Observable<void> {
    console.debug('initialize LogService', environment);

    SentryAngular.init({
      dsn: environment.sentry.dsn,
      release: `${environment.name}@${environment.version}`,
      environment: `${environment.environment}`,
      debug: !environment.production,
      beforeBreadcrumb: (breadcrumb: Breadcrumb) => {
        if (breadcrumb.type === 'http') {
          if (breadcrumb.data && breadcrumb.data.url) {
            if (breadcrumb.data.url.startsWith('/svg')) {
              // Don't clutter the breadcrumbs with the retrieval of svgs
              return null;
            } else if (breadcrumb.data.url.startsWith('data:image')) {
              // Strip base64 data from the url
              breadcrumb.data.url = breadcrumb.data.url.replace(/^(data:[/a-zA-Z]+)(;base64)?,(.{3}).*(.{3})$/, '$1$2,$3...$4');
            }
          }
        }

        return breadcrumb;
      },
    });

    return of();
  }

  /**
   * Add a breadcrumb to Sentry, this will be visible in the stacktrace of messages and exceptions logged later.
   * Note that many actions are in the breadcrumbs already!
   * @param message The message to display for breadcrumb
   * @param category The category of the breadcrumb, common examples are console, exception, ui.click, xhr, fetch, navigation
   * @param level Severity of the breadcrumb
   * @param type Type of the breadcrumb, common example is 'http', but is often empty, the webconsole probably assigns a type based on the category
   * @param data Additional data to show in the breadcrumb. Use sparsely.
   */
  logBreadcrumb(
    message: string,
    category = 'msm-custom',
    level: SentryAngular.SeverityLevel = 'info',
    type?: string,
    data?: { [key: string]: unknown },
  ): void {
    const breadcrumb: Breadcrumb = {
      message,
      type,
      category,
      level,
      data,
    };
    SentryAngular.addBreadcrumb(breadcrumb);
  }

  logMessage(message: string, level: SentryAngular.SeverityLevel = 'info', contexts?: Contexts): void {
    // It is possible to manipulate the scope for a single log:
    // https://docs.sentry.io/platforms/javascript/guides/angular/usage/set-level/
    // There is no use for that yet
    SentryAngular.captureMessage(message, {
      level,
      contexts,
    });
    if (environment.environment === 'test') {
      console.log(message);
    }
  }

  // `any` is the parameter type requested by underlying function captureException
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  logException(error: any): void {
    // It is possible to manipulate the scope for a single log:
    // https://docs.sentry.io/platforms/javascript/guides/angular/usage/set-level/
    // There is no use for that yet
    SentryAngular.captureException(error);
  }

  /**
   * Add context to the Sentry scope
   * @param contextKey key of the context
   * @param contextValue value of the context
   */
  addContext(contextKey: string, contextValue: Context): void {
    SentryAngular.setContext(contextKey, contextValue);
  }

  addTag(tagKey: string, tagValue: Primitive): void {
    SentryAngular.setTag(tagKey, tagValue);
  }

  /**
   * Reset the context for the Sentry scope
   * @param contextKey key of the context
   */
  removeContext(contextKey: string): void {
    SentryAngular.setContext(contextKey, null);
  }
}
