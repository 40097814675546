import { Component, EventEmitter, OnInit, Optional, Output, Self } from '@angular/core';
import { UntypedFormControl, NgControl } from '@angular/forms';
import { AbstractMsmInputComponent } from '@component/input/abstract-msm-input.component';
import { VehicleMetaData } from '@model/question.model';

@Component({
  selector: 'msm-licenseplate-confirm-input',
  templateUrl: './licenseplate-confirm-input.component.html',
  styleUrls: ['./licenseplate-confirm-input.component.scss'],
})
export class LicenseplateConfirmInputComponent extends AbstractMsmInputComponent implements OnInit {

  @Output() navigateTo = new EventEmitter<string>();

  brandModel = '';
  imageUrl = '';
  imageLoaded = true;
  licenseControl?: UntypedFormControl;

  constructor(
    @Optional() @Self() public override ngControl?: NgControl,
  ) {
    super(ngControl);
  }

  imageIsNotLoaded(): void {
    this.imageLoaded = false;
  }

  ngOnInit(): void {
    const vehicle: VehicleMetaData | undefined = this.question.metaData && this.question.metaData.vehicle ?
      this.question.metaData.vehicle : undefined;

    if (vehicle) {
      this.imageUrl = vehicle.image || '';
      this.brandModel = `${vehicle.brand || ''} ${vehicle.model || ''}`;
    }

    this.licenseControl = new UntypedFormControl({ value: vehicle && vehicle.licenseplate ? vehicle.licenseplate : null, disabled: true });
  }

  onNavigateTo(navigateTo: string): void {
    this.onChange(null);
    this.navigateTo.emit(navigateTo);
  }
}
