<ion-header [translucent]="true" class="msm-page-header">
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button id="toggle-menu-button" autoHide="true" menu="progress"></ion-menu-button>
    </ion-buttons>

    <ion-title class="msm-header-title" [routerLink]="['/getting-started']">
      {{ title }}
      <p class="msm-header-subtitle" *ngIf="subTitle">{{ subTitle }}</p>
    </ion-title>

    <ion-label slot="end" class="msm-environment" *ngIf="environment !== 'PRODUCTION'"> {{environment}} </ion-label>

    <ion-buttons *ngIf="helpText" slot="end">
      <ion-button id="help-button" class="msm-help-button" (click)="setHelpModalVisibility(true)">
        <ion-icon slot="icon-only" name="help"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

  <ion-modal (didDismiss)="setHelpModalVisibility(false)" [isOpen]="isHelpModalVisible" [canDismiss]="true" [initialBreakpoint]=".5" [breakpoints]="[.25,.5]">
    <ng-template>
      <msm-help-modal [helpText]="helpText" (dismissModal)="setHelpModalVisibility(false)" ></msm-help-modal>
    </ng-template>
  </ion-modal>

</ion-header>
