import { Injectable } from '@angular/core';
import _moment from 'moment';

type DateTime = _moment.Moment | string | undefined;

@Injectable({
  providedIn: 'root',
})
export class DateHelper {
  static readonly META_DATA_DATE_FORMAT = 'YYYYMMDD';
  static readonly META_DATA_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
  static readonly DEFAULT_DISPLAY_DATE_FORMAT = 'L';
  static readonly DEFAULT_DISPLAY_DATETIME_FORMAT = 'L LT';
  static readonly ATTRIBUTE_DATE_FORMAT = 'YYYY-MM-DD';
  static readonly ATTRIBUTE_TIME_FORMAT = 'HH:mm';
  static readonly ATTRIBUTE_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

  static parseDate(date: string): _moment.Moment {
    return _moment(date, DateHelper.META_DATA_DATE_FORMAT);
  }

  static parseDateTime(dateTime: string): _moment.Moment {
    return _moment(dateTime, DateHelper.META_DATA_DATETIME_FORMAT);
  }

  static parseDateAndTime(date: string, time: string): _moment.Moment {
    return _moment(`${date}'T'${time}`, DateHelper.META_DATA_DATETIME_FORMAT);
  }

  static formatDateTime(dateTime: _moment.Moment): string {
    return dateTime.format(DateHelper.META_DATA_DATETIME_FORMAT);
  }

  static getDisplayDate(date: _moment.Moment | string): string {
    if (typeof date === 'string') {
      date = DateHelper.parseDate(date);
    }

    return date.format(DateHelper.DEFAULT_DISPLAY_DATE_FORMAT);
  }

  static getDisplayDateTime(date: DateTime): string {
    if (typeof date !== 'object') {
      date = DateHelper.parseDateTime(date ?? '');
    }

    return date.format(DateHelper.DEFAULT_DISPLAY_DATETIME_FORMAT);
  }

  static getAttributeDate(date: DateTime): string | null {
    if (!date) {
      return null;
    }

    if (typeof date === 'string') {
      date = DateHelper.parseDate(date);
    }

    return date.format(DateHelper.ATTRIBUTE_DATE_FORMAT);
  }

  static getAttributeTime(date: DateTime): string | null {
    if (!date) {
      return null;
    }

    if (typeof date === 'string') {
      date = DateHelper.parseDateTime(date);
    }

    return date.format(DateHelper.ATTRIBUTE_TIME_FORMAT);
  }

  static getAttributeDateTime(date: _moment.Moment | undefined): string | null {
    if (!date) {
      return null;
    }

    return date.format(DateHelper.ATTRIBUTE_DATETIME_FORMAT);
  }
}
