<form [formGroup]="answersForm" class="msm-form-container" *ngIf="answersForm">
  <ion-list>
    <ion-item *ngFor="let child of question.children"
              class="msm-form-question"
              lines="none"
              [hidden]="hideQuestion(child)">

      <msm-boolean-input *ngIf="child.type === 'boolean'" [question]="child" [formControlName]="getChildQuestionCode(child)">
      </msm-boolean-input>

      <msm-textarea-input *ngIf="child.type === 'textarea'" [question]="child" [formControlName]="getChildQuestionCode(child)">
      </msm-textarea-input>

      <msm-signcode-input *ngIf="child.type === 'signCode'" [question]="child" [formControlName]="getChildQuestionCode(child)">
      </msm-signcode-input>

      <msm-checkbox-input *ngIf="child.type === 'checkbox'" [question]="child" [formControlName]="getChildQuestionCode(child)">
      </msm-checkbox-input>

    </ion-item>

    <ion-button class="msm-sign-button" id="sign-button" type="button" color="primary" fill="solid"
                [disabled]="signing || !answersForm.valid"
                (click)="signIncident()">
      <ion-label>{{ question.question  | translate }}</ion-label>
    </ion-button>

  </ion-list>

</form>
