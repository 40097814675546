<div class="msm-image-component">
  <ion-item lines="none"
            *ngIf="question.question && !disabled">
    <div>
      <ion-label class='ion-text-wrap'>{{ question.question }}</ion-label>
      <ion-label class="msm-image-description ion-text-wrap">{{ question.description }}</ion-label>
    </div>
  </ion-item>

  <ion-grid class="msm-image">

    <ion-row>
      <ion-col class="msm-image-wrapper">

        <ion-img *ngIf="imageLoaded"
                 class="msm-image-container"
                 [ngClass]="'msm-image--' + size"
                 (ionImgDidLoad)="onImageLoaded()"
                 (ionError)="onImageLoadError()"
                 [src]="image?.base64DataUrl">
        </ion-img>

        <ng-container *ngIf="!disabled">
          <ion-button (click)="takePhotoWithCamera()"
                      expand="block"
                      fill="solid"
                      *ngIf="(image?.uuid === '' || image?.uuid?.startsWith('placeholder_')) && !isBrowserAppOnIos"
                      shape="round">
            <ion-icon slot="icon-only"
                      color="primary"
                      name="camera-outline">
            </ion-icon>
          </ion-button>

          <ion-button (click)="selectImageFromGallery()"
                      expand="block"
                      fill="solid"
                      *ngIf="image?.uuid === '' || image?.uuid?.startsWith('placeholder_')"
                      shape="round">
            <ion-icon slot="icon-only"
                      color="primary"
                      name="images-outline">
            </ion-icon>
          </ion-button>

          <input #fileselector
                 capture="environment"
                 class="input-file"
                 type="file"
                 accept="image/*"
                 hidden
                 (change)="onFileChoose(fileselector.files)">

          <ion-button (click)="removeImage()"
                      expand="block"
                      fill="solid"
                      *ngIf="!image?.uuid?.startsWith('placeholder_')"
                      shape="round">
            <ion-icon slot="icon-only"
                      color="danger"
                      name="trash-outline">
            </ion-icon>
          </ion-button>
        </ng-container>

      </ion-col>
    </ion-row>

  </ion-grid>

  <div class="errors"
       *ngIf="errors && ngControl?.touched">
    <span class="error"
          *ngIf="errors.required">
      {{ 'input.errors.required' | translate: {field: question.label} }}
    </span>
  </div>
</div>
