import { Component, Input, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'msm-page-header',
  templateUrl: 'page-header.component.html',
  styleUrls: ['page-header.component.scss'],
})

export class PageHeaderComponent implements OnInit {
  @Input({ required: true }) title!: string;
  @Input() subTitle?: string;
  @Input() helpText?: string;

  environment = '';
  isHelpModalVisible = false;

  ngOnInit(): void {
    this.environment = environment.environment.toUpperCase();
  }

  setHelpModalVisibility(show: boolean): void {
    this.isHelpModalVisible = show;
  }
}
