import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class AppState {
  private state: Record<string, string | number | boolean> = {
    theme: 'default',
  };

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params?.theme) {
        this.set('theme', params.theme);
      }
    });
  }

  /**
   * Retrieve a property from the appState
   * @param prop Property key
   */
  get(prop?: string): string | number | boolean | null {
    const state = this.state;
    if (prop !== undefined && state[prop]) {
      return state[prop];
    } else {
      return null;
    }
  }

  /**
   * Update a property on the appState
   * @param prop Property key
   * @param value Property value
   */
  set(prop: string, value: string | number | boolean): string | number | boolean {
    return this.state[prop] = value;
  }
}
