<div class="msm-linking-done-container">
  <ion-grid>
    <ion-row>
      <ion-col sizeXs="12" sizeSm="5" class="msm-linked-object">
        <ng-container *ngIf="reporterMobileNumber && !reporterLicensePlate">
          <ion-label class="msm-linking-phonenumber">{{reporterMobileNumber}}</ion-label>
        </ng-container>
        <ng-container *ngIf="reporterLicensePlate">
          <ion-input [name]="question.code"
                     class="msm-licenseplate ion-no-margin"
                     placeholder="{{'component.licenseplate.placeholder' | translate}}"
                     type="text"
                     inputmode="text"
                     [(ngModel)]="reporterLicensePlate"
                     readonly="true"
                     maxlength="8">
          </ion-input>
        </ng-container>
      </ion-col>
      <ion-col sizeXs="12" sizeSm="2" class="msm-linked-object">
        <ion-icon name="link-outline" size="large"></ion-icon>
      </ion-col>
      <ion-col class="msm-linked-object">
        <ng-container *ngIf="counterpartMobileNumber && !counterpartLicenseplate">
          <ion-label class="msm-linking-phonenumber">{{counterpartMobileNumber}}</ion-label>
        </ng-container>
        <ng-container *ngIf="counterpartLicenseplate">
          <ion-input [name]="question.code"
                     class="msm-licenseplate ion-no-margin"
                     placeholder="{{'component.licenseplate.placeholder' | translate}}"
                     type="text"
                     inputmode="text"
                     [(ngModel)]="counterpartLicenseplate"
                     readonly="true"
                     maxlength="8">
          </ion-input>
        </ng-container>

      </ion-col>
    </ion-row>
  </ion-grid>
</div>
