<ion-item lines="none" *ngIf="!disabled()">
  <ion-label class='ion-text-wrap'>{{question.question}}</ion-label>
</ion-item>

<div class="vehicle-cont" [formGroup]="answersForm">
  <ng-container *ngFor="let child of question.children">
    <msm-damage-indicator-input [question]="child"
                                [formControlName]="getChildQuestionCode(child)"
                                (initialContactEvent)="setInitialContact($event)"></msm-damage-indicator-input>
  </ng-container>
</div>
