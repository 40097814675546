import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, FormArrayName, UntypedFormGroup } from '@angular/forms';
import { FormHelper } from '@component/helper/form-helper';
import { Question } from '@model/question.model';

@Component({
  selector: 'msm-persons-input',
  templateUrl: './persons-input.component.html',
  styleUrls: ['./persons-input.component.scss'],
})
export class PersonsInputComponent implements OnInit {
  @Input({ required: true }) question!: Question;

  personControls!: UntypedFormArray;

  constructor(
    private readonly formHelper: FormHelper,
    private readonly controlContainer: FormArrayName,
  ) {
  }

  ngOnInit(): void {
    this.personControls = this.controlContainer.control;
  }

  addPerson(): void {
    if(this.question.children !== undefined) {
      this.personControls.push(this.formHelper.createFormControls(this.question.children, this.question.code));
    }
  }

  removePersonAt(index: number): void {
    if (this.personControls.length > 1) {
      this.personControls.removeAt(index);
    } else {
      this.personControls.controls[0].reset();
    }
    this.personControls.markAsDirty();
  }

  getAsFormGroup(personControl: AbstractControl): UntypedFormGroup {
    return personControl as UntypedFormGroup;
  }
}
