<ion-item lines="none"
          *ngIf="question.question">
  <ion-label class='ion-text-wrap'> {{question.question}} </ion-label>
</ion-item>

<ion-item class="msm-datetime-input">
  <ng-container *ngIf="!deviceService.isDeviceApp" [formGroup]="dateTimeForm">
    <ion-input
      formControlName="date"
      type="date"
      [name]="question.code + '-date'"
      [min]="min.date"
      [max]="max.date"
    ></ion-input>

    <ion-input
      formControlName="time"
      type="time"
      [name]="question.code + '-time'"
    ></ion-input>
  </ng-container>

  <ng-container *ngIf="deviceService.isDeviceApp" >
    <ion-datetime-button datetime="{{question.code}}"></ion-datetime-button>

    <ion-popover [keepContentsMounted]="true"
                 side="start"
                 alignment="center"
                 size='auto'
    >

      <ng-template>
        <ion-datetime *ngIf="control" [name]="question.code"
                      [formControl]="control"
                      id="{{question.code}}"
                      [min]="min.dateTime"
                      [max]="max.dateTime"
                      showDefaultButtons="true"
                      cancelText="{{'generic.cancel' | translate}}"
                      doneText="{{'generic.done' | translate}}"
        >
          <span slot="title">{{question.question}}</span>
        </ion-datetime>
      </ng-template>
    </ion-popover>

  </ng-container>

</ion-item>

<div class="errors" *ngIf="errors && (ngControl?.touched || (dateTimeForm && dateTimeForm.touched))">
  <span class="error" *ngIf="errors.required">
    {{ 'input.errors.required' | translate: { field : question.label } }}
  </span>
  <span class="error" *ngIf="errors.minDate">
    {{ 'input.errors.minValue' | translate: { field : question.label, minValue : dateHelper.getDisplayDateTime(min.value) } }}
  </span>
  <span class="error" *ngIf="errors.maxDate">
    {{ 'input.errors.maxValue' | translate: { field : question.label, maxValue : dateHelper.getDisplayDateTime(max.value) } }}
  </span>
</div>
