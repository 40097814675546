import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { ProgressMenuComponent } from '@component/progress-menu/progress-menu.component';
import { MenuController } from '@ionic/angular';
import { AnswersService } from '@service/answers.service';
import { DeviceService } from '@service/device.service';
import { LogService } from '@service/log.service';
import { register } from 'swiper/element/bundle';

import { AppState } from './app.state';
import { Constants } from './constants';

register();

@Component({
  selector: 'msm-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private readonly deviceService: DeviceService,
    readonly global: AppState,
    private readonly logService: LogService,
    private readonly menuController: MenuController,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly answerService: AnswersService,

  ) {
    this.initializeApp();
  }

  ngOnInit(): void {
    this.logService.logBreadcrumb('Disable menu from app.component', 'msm-custom', 'debug');
    this.menuController.enable(false, ProgressMenuComponent.MENU_ID).catch(err => {
      console.error(`Error disabling menu : ${err}`);
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['insurer-code']) {
        this.answerService.saveAnswer(Constants.PREFILLED_INSURER_QUESTION,params['insurer-code']).subscribe();
      }
    });

  }

  async initializeApp(): Promise<void> {
    await this.deviceService.initialize();
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const parsedUrl = new URL(event.url);
      const incidentUuid = parsedUrl.searchParams.get('incidentUuid');
      const extras: NavigationExtras = {
        queryParams: {
          url: event.url,
          incidentUuid,
        },
      };

      this.router.navigate(['/quick-start'], extras );
    });
  }
}
