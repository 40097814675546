<ion-item lines="none"
          *ngIf="question.question">
  <ion-label class='ion-text-wrap'> {{question.question}} </ion-label>
</ion-item>

<ng-container *ngIf="ngControl">

  <ion-item *ngIf="control" lines="none">
    <ion-segment [formControl]="control" [ngStyle]= "{'grid-auto-columns': 100 / options.length + '%'}">
        <ion-segment-button *ngFor="let option of options"
                            [value]="option.value">
          <ion-label class='ion-text-wrap'>{{ option.label }}</ion-label>
        </ion-segment-button>
    </ion-segment>
  </ion-item>

  <div class="errors"
       *ngIf="errors && ngControl.touched">
    <span class="error"
          *ngIf="errors.required">
      {{ 'input.errors.required' | translate: { field : question.label } }}
    </span>
    <span class="error"
          *ngIf="errors.invalidOption">
      {{ 'input.errors.invalidoption' | translate: { field : question.label } }}
    </span>
  </div>

</ng-container>
