import { Injectable } from '@angular/core';
import { FormAnswers } from '@model/form-answers';
import { AnswersService } from '@service/answers.service';
import { LogService } from '@service/log.service';
import { MsmStorageService } from '@storage/msm-storage.service';
import { Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PrefillAnswersService {

  private static readonly PREFILL_COLUMN_PREFILL = 'msmpAppPrefill';
  private static readonly TABLE_PREFILL = 'prefill';

  constructor(
    private readonly logService: LogService,
    private readonly storageService: MsmStorageService,
  ) { }

  saveAnswers(answers: FormAnswers): Observable<FormAnswers> {
    let mergedAnswersResult: FormAnswers;

    return this.loadAnswers().pipe(
      map(storedAnswers => AnswersService.mergeAllExceptArrays(storedAnswers || {}, answers)),
      tap(mergedAnswers => mergedAnswersResult = mergedAnswers),
      map(mergedAnswers => JSON.stringify(mergedAnswers)),
      // eslint-disable-next-line max-len
      mergeMap(answersJson => this.storageService.store(PrefillAnswersService.PREFILL_COLUMN_PREFILL, answersJson, PrefillAnswersService.TABLE_PREFILL)),
      map(() => mergedAnswersResult),
    );
  }

  loadAnswers(): Observable<FormAnswers> {
    return this.storageService.read(PrefillAnswersService.PREFILL_COLUMN_PREFILL, PrefillAnswersService.TABLE_PREFILL).pipe(
      map(storageValue => {
        try {
          return JSON.parse(storageValue);
        } catch (error) {
          this.logService.logException(error);

          return null;
        }
      }),
    );
  }
}
