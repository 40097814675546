<!--According to CrashR's documentation, styling define in the page-->
<style>
  #crashr {
    --success: var(--ion-color-success);
    --crashr-success-background: var(--ion-color-success);

    .crashr .crashr-location-message > div {
      font-family: var(--ion-font-family);
      font-size: 0.875rem;
    }

    .crashr .crashr-message > div {
      font-family: var(--ion-font-family);
      font-size: 0.875rem;
    }
  }
</style>

<div id="crashr" #crashr class="msm-crashr-container" *ngIf="crashrSupported === true"></div>

<form [formGroup]="answersForm" class="msm-form-container" *ngIf="crashrSupported === false">
  <ion-list>
    <ion-item *ngFor="let child of questions"
              [class]="'msm-form-question msm-form-question--' + child.type"
              lines="none"
              [hidden]="hideQuestion(child)">
      <!-- TODO: This code is the same as on the forms-page, should it be merged? -->
      <msm-data-input *ngIf="['text', 'email', 'phone', 'number', 'date', 'time'].includes(child.type)"
                      [question]="child"
                      [formControlName]="this.getChildQuestionCode(child)">
      </msm-data-input>

      <ng-container *ngIf="child.type === 'image'">
        <msm-image-input *ngIf="crashrId"
                         [question]="child"
                         size="full"
                         [formControlName]="this.getChildQuestionCode(child)">
        </msm-image-input>
      </ng-container>

      <ng-container *ngIf="!['text', 'email', 'phone', 'number', 'date', 'time', 'image'].includes(child.type)">
        <ion-label>Question of type <strong>{{child.type}}</strong>: {{child.question}}</ion-label>
        <ion-list *ngIf="child.options">
          <ion-item *ngFor="let option of child.options">
            <ion-label>{{option.label}}</ion-label>
          </ion-item>
        </ion-list>
      </ng-container>

    </ion-item>
  </ion-list>
</form>
