import { DexieIncident, DexiePrefill } from '@storage/msm-storage.model';
import Dexie, { Table } from 'dexie';

export class MsmDatabase extends Dexie {
  incident!: Table<DexieIncident, string>;
  prefill!: Table<DexiePrefill, string>;

  constructor() {
    super('msmpApp');

    this.version(1).stores({
      incident: 'id',
      prefill: 'id',
    });

  }
}

export const msmDatabase = new MsmDatabase();
