import { Component, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { LicenseplateUtil } from '@component/utils/licenseplate-util';
import { InputCustomEvent } from '@ionic/angular';
import { LanguageService } from '@service/language.service';

import { AbstractMsmInputComponent } from '../abstract-msm-input.component';

@Component({
  selector: 'msm-licenseplate-input',
  templateUrl: './licenseplate-input.component.html',
  styleUrls: ['./licenseplate-input.component.scss'],
})
export class LicensePlateInputComponent extends AbstractMsmInputComponent implements OnInit {
  actualLength = 0;
  errorText = '';
  licenseplate = '';
  readonly maxLength = 6;
  offSet = 0;
  patternOk = true;
  showWhenError = false;

  constructor(
    private readonly languageService: LanguageService,
    @Optional() @Self() override ngControl?: NgControl,
  ) {
    super(ngControl);
  }

  ngOnInit(): void {
    if (this.control?.value) {
      this.actualLength = this.control.value.length;
      this.licenseplate = LicenseplateUtil.formatLicenseplate(this.control.value, this.maxLength);
      this.patternOk = LicenseplateUtil.validateLicensplate(this.control.value);
    }
    this.languageService.getTranslation('input.errors.pattern', { field: this.question.label })
      .subscribe(res => this.errorText = res);
  }

  override setValue(value: string): void {
    this.setLicenseplate(value);
  }

  override disable(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.offSet = this.isDisabled ? 4 : 0;
  }

  focusout(): void {
    if (!this.isDisabled) {
      this.showWhenError = true;
    }
  }

  onLicenseplateChange(event: Event): void {
    const inputEvent = event as InputCustomEvent;
    const detailValue = inputEvent.detail.value;

    if (detailValue) {
      this.setLicenseplate(detailValue.toUpperCase());
    }
  }

  private setLicenseplate(licenseplate: string): void {
    if (licenseplate) {
      this.actualLength = LicenseplateUtil.stripLicensePlate(licenseplate).length;
    } else {
      this.actualLength = 0;
      this.licenseplate = '';
    }

    if (this.actualLength === 6) {
      this.licenseplate = LicenseplateUtil.formatLicenseplate(licenseplate, this.maxLength);
      this.patternOk = LicenseplateUtil.validateLicensplate(licenseplate);
      this.showWhenError = true;
    } else {
      this.patternOk = false;
    }

    this.onChange(licenseplate ? LicenseplateUtil.stripLicensePlate(this.licenseplate) : this.licenseplate);
  }
}
