import { Component, Input, OnInit } from '@angular/core';
import { Question } from '@model/question.model';

@Component({
  selector: 'msm-static-text',
  templateUrl: './static-text.component.html',
  styleUrls: ['./static-text.component.scss'],
})
export class StaticTextComponent implements OnInit {
  @Input({ required: true }) question!: Question;

  textSize?: string;
  textColor?: string;

  ngOnInit(): void {
    if (this.question.metaData.text) {
      this.textSize = this.question.metaData.text.size;
      this.textColor = this.question.metaData.text.color;
    }
  }

  setTextOptions(): string | null {
    if (this.textColor || this.textSize) {
      return this.setTextColor().concat(' ', this.setTextSize());
    }

    return null;
  }

  setTextColor(): string {
    if (this.textColor) {
      return `msm-static-text-color-${this.textColor}`;
    }

    return '';
  }

  setTextSize(): string {
    if (this.textSize) {
      return `msm-static-text-size-${this.textSize}`;
    }

    return '';
  }
}
