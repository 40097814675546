import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, GalleryPhoto, Photo } from '@capacitor/camera';
import { from, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class ImageService {

  constructor(
    private readonly logService: LogService,
  ) { }

  // Unfortunately errors by the CameraPlugin are not documented and not coded, so we can only recognize them by string value. See CameraPlugin.java
  private static readonly CAMERA_ERROR_CANCELED_BY_USER = 'User cancelled photos app';
  private static readonly CAMERA_NO_IMAGE_PICKED = 'No image picked';
  private static readonly USER_DENIED_ACCESS_PHOTOS = 'User denied access to photos';
  private static readonly USER_DENIED_ACCESS_CAMRA = 'User denied access to camera';
  private static readonly CAMRA_NOT_AVAILABLE_IN_SIMULATOR = 'Camera not available while running in Simulator';

  selectPhoto(source: CameraSource): Observable<Photo | GalleryPhoto> {
    return from(Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      source,
      quality: 100,
      saveToGallery: true,
      allowEditing: false,
    })).pipe(

      catchError(error => {
        if (error.message &&
          (error.message === ImageService.CAMERA_ERROR_CANCELED_BY_USER ||
            error.message === ImageService.CAMERA_NO_IMAGE_PICKED ||
            error.message === ImageService.USER_DENIED_ACCESS_PHOTOS ||
            error.message === ImageService.CAMRA_NOT_AVAILABLE_IN_SIMULATOR ||
            error.message === ImageService.USER_DENIED_ACCESS_CAMRA)) {
          return of();
        } else {
          this.logService.logMessage('Unhandled situation for selectPhoto', 'warning', { error } );
        }
        throw new Error(`SelectPhoto error: ${error?.message || error}`);
      }),
    );
  }
}

