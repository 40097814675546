<form [formGroup]="addressForm" class="msm-form-container" *ngIf="addressForm">

  <ion-list>
    <div *ngFor="let child of question.children" class="msm-form-question" lines="none"
         [ngClass]="{'msm-required-question': !child.metaData.validationRules?.optional}">
      <!-- TODO: This code is the same as on the forms-page, should it be merged? -->

      <msm-data-input *ngIf="['text', 'email', 'phone', 'number', 'date', 'time'].includes(child.type)"
                      [question]="child"
                      [formControlName]="this.getChildCode(child.code)">
      </msm-data-input>

      <msm-boolean-input *ngIf="child.type === 'boolean'" [question]="child" [formControlName]="this.getChildCode(child.code)">
      </msm-boolean-input>

      <msm-select-input *ngIf="child.type === 'dropdown'" [question]="child" [formControlName]="this.getChildCode(child.code)"></msm-select-input>

      <ng-container *ngIf="child.type === 'option'">
        <msm-option class="option-container" [formControlName]="this.getChildCode(child.code)" [question]="child">
        </msm-option>
      </ng-container>

      <ng-container *ngIf="!['text', 'email', 'phone', 'number', 'dropdown', 'date', 'time', 'boolean', 'option'].includes(child.type)">
        <ion-label>Question of type <strong>{{child.type}}</strong>: {{child.question}}</ion-label>
        <ion-list *ngIf="child.options">
          <ion-item *ngFor="let option of child.options">
            <ion-label>{{option.label}}</ion-label>
          </ion-item>
        </ion-list>
      </ng-container>

    </div>
  </ion-list>
  <ng-container *ngIf="zipCheckErrorMessage">
    <div class="warnings">
      <span class="warning">
      {{ zipCheckErrorMessage | translate }}
      </span>
    </div>
  </ng-container>
</form>
