<ion-item lines="none" *ngIf="question.question">
  <ion-label>{{question.question}}</ion-label>
</ion-item>

<form [formGroup]="answersForm" class="msm-form-container" *ngIf="answersForm">
  <ion-list>
    <div *ngFor="let child of questions" class="msm-form-question">
      <ng-container [ngSwitch]="child.type">

        <msm-datetime-input
          *ngSwitchCase="'datetime'"
          [question]="child"
          [formControlName]="this.getChildQuestionCode(child)">
        </msm-datetime-input>

        <ng-container *ngSwitchDefault>
          <ion-label>Question of type <strong>{{question.type}}</strong>: {{question.question}}</ion-label>
          <ion-list *ngIf="question.options">
            <ion-item *ngFor="let option of question.options">
              <ion-label>{{option.label}}</ion-label>
            </ion-item>
          </ion-list>
        </ng-container>

      </ng-container>
    </div>
  </ion-list>

  <div class="errors" *ngIf="answersForm && answersForm.touched && answersForm.errors">
    <span class="error" *ngIf="answersForm.errors.datesInOrder">
      {{ 'input.errors.endBeforeStart' | translate: { field1 : getChildLabel(answersForm.errors.datesInOrder[0]), field2: getChildLabel(answersForm.errors.datesInOrder[1]) } }}
    </span>
  </div>
</form>
