import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppVersionGuard } from '@service/app-version.guard';
import { SkipLandingPageGuard } from '@service/skip-landing-page.guard';

const routes: Routes = [
  {
    path: 'redirect',
    loadChildren: () => import('./redirect/redirect.module').then(m => m.RedirectModule),
  },
  {
    path: 'continue-incident',
    loadChildren: () => import('./continue-incident/continue-incident.module').then(m => m.ContinueIncidentModule),
    canActivateChild: [AppVersionGuard],
  },
  {
    path: 'getting-started',
    loadChildren: () => import('./getting-started/getting-started.module').then(m => m.GettingStartedPageModule),
    canActivateChild: [AppVersionGuard],
  },
  {
    path: 'questions',
    loadChildren: () => import('./forms/forms-page.module').then(m => m.FormsPageModule),
    canActivateChild: [AppVersionGuard],
  },
  {
    path: 'quick-start',
    loadChildren: () => import('./component/quick-start/quick-start.module').then(m => m.QuickStartModule),
    canActivateChild: [AppVersionGuard],
  },
  {
    path: 'landing-page',
    loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule),
    canActivate: [SkipLandingPageGuard],
    canActivateChild: [AppVersionGuard],
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy-page/privacy-page.module').then(m => m.PrivacyPageModule),
    canActivateChild: [AppVersionGuard],
  },
  {
    path: 'introduction',
    loadChildren: () => import('./introduction/introduction.module').then(m => m.IntroductionPageModule),
    canActivateChild: [AppVersionGuard],
  },
  {
    path: 'finished',
    loadChildren: () => import('./finished-page/finished-page.module').then(m => m.FinishedPageModule),
    canActivateChild: [AppVersionGuard],
  },
  {
    path: '',
    redirectTo: 'landing-page',
    pathMatch: 'full',
  },
  {
    path: 'error',
    pathMatch: 'prefix',
    loadChildren: () => import('./error/error.module').then(m => m.ErrorPageModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'error/page-not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
