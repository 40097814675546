import { Component, Input, OnInit } from '@angular/core';
import { Question } from '@model/question.model';

@Component({
  selector: 'msm-static-image',
  templateUrl: './static-image.component.html',
  styleUrls: ['./static-image.component.scss'],
})
export class StaticImageComponent implements OnInit {
  @Input({ required: true }) question!: Question;
  imageName = 'placeholder';
  imagePosition?: string;

  ngOnInit(): void {
    if (this.question.metaData.image) {
      this.imageName = this.question.metaData.image ? (this.question.metaData.image as string) : 'placeholder';
      if (this.question.metaData.imagePosition) {
        this.imagePosition = `msm-image-${this.question.metaData.imagePosition}`;
      }
    }
  }
}
