import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReloadMenuService {
  private reloadMenuSubject: Subject<void>;

  constructor() {
    this.reloadMenuSubject = new Subject<void>();
  }

  get reloadMenuObservable(): Observable<void> {
    return this.reloadMenuSubject.asObservable();
  }

  reloadMenu(): void {
    this.reloadMenuSubject.next();
  }
}
