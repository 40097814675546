  <ion-grid fixed>
    <ion-row>
      <ion-col class="ion-align-self-end">
        <div class="msm-input-counter"
             *ngIf="maxLength && !disabled">
          {{actualLength}}/{{maxLength}}
        </div>
      </ion-col>
    </ion-row>

    <ion-row class="msm-licenseplate-row">
    <ion-col size="12"
             offset="{{ offSet }}">
        <ion-input [name]="question.code"
                   class="msm-licenseplate"
                   placeholder="{{'component.licenseplate.placeholder' | translate}}"
                   type="text"
                   inputmode="text"
                   [autocomplete]="question.code"
                   (focusout)="focusout()"
                   (ionChange)="onLicenseplateChange($event)"
                   [(ngModel)]="licenseplate"
                   [disabled]="disabled"
                   maxlength="8">
        </ion-input>
      </ion-col>
    </ion-row>
  </ion-grid>

<div class="errors" *ngIf="showWhenError && (!patternOk) || (control?.touched && control?.status !== 'VALID')">
  <span class="error">
    {{ errorText }}
  </span>
</div>

<msm-data-input *ngIf="!disabled && control"
                [question]="question"
                [hidden]="true"
                [formControl]="control">
</msm-data-input>
