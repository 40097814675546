import { Injectable } from '@angular/core';
import { Incident } from '@model/incident.model';
import { MsmStorageService } from '@storage/msm-storage.service';
import { BehaviorSubject, Observable, map, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IncidentService {
  private static readonly INCIDENT_COLUMN_CURRENT_INCIDENT = 'msmpAppIncident';

  private currentIncidentSource = new BehaviorSubject<Incident | null>(null);
  private loadSucceeded = false;

  constructor(private readonly storageService: MsmStorageService) {}

  getIncident(): Observable<Incident | null> {
    if (!this.loadSucceeded) {
      return this.loadCurrentIncident();
    }

    // Make it complete (cold)
    return this.currentIncidentSource.pipe(take(1));
  }

  storeCurrentIncident(incident: Incident): Observable<void> {
    return this.storageService.store(IncidentService.INCIDENT_COLUMN_CURRENT_INCIDENT, JSON.stringify(incident)).pipe(
      tap(() => {
        this.loadSucceeded = true;
        this.currentIncidentSource.next(incident);
      }),
    );
  }

  clearCurrentIncident(): Observable<void> {
    return this.storageService.clear(IncidentService.INCIDENT_COLUMN_CURRENT_INCIDENT).pipe(
      tap(() => {
        this.loadSucceeded = false;
        this.currentIncidentSource.next(null);
      }),
    );
  }

  private loadCurrentIncident(): Observable<Incident | null> {
    return this.storageService.read(IncidentService.INCIDENT_COLUMN_CURRENT_INCIDENT).pipe(
      map(incidentJson => {
        let incident: Incident | null = null;

        if (incidentJson && '{}' !== incidentJson) {
          incident = JSON.parse(incidentJson) as Incident;
          this.currentIncidentSource.next(incident);
        }
        this.loadSucceeded = true;

        return incident;
      }),
    );
  }
}
