<form [formGroup]="linkForm" class="msm-form-container" *ngIf="linkForm"></form>
<ion-item lines="none"  class="msm-code-container">
  <ion-label class="ion-text-wrap msm-code-label">{{ question.question }}:</ion-label>
</ion-item>
<ion-item lines="none" class="msm-code-container">
  <ion-text class="msm-code-text" *ngIf="showPrefix">{{ question.metaData.prefix }}</ion-text>
  <ion-text class="msm-code-text" *ngIf="showPrefix">-</ion-text>
  <ion-input class="msm-code-input" *ngIf="control" [formControl]="control" [attr.maxlength]="linkLength" type="text" inputmode="numeric"
    placeholder="{{'_'.repeat(linkLength)}}"></ion-input>
</ion-item>

<!-- Error messages -->
<!-- TODO: Extract and style; only show first error? -->
<div class="errors" *ngIf="errors && ngControl?.touched && question">
  <span class="error" *ngIf="errors.required">
    {{ 'input.errors.required' | translate: { field : question.label } }}
  </span>
  <span class="error" *ngIf="errors.pattern">
    {{ 'input.errors.pattern' | translate: { field : question.label } }}
  </span>
  <span class="error" *ngIf="errors.minlength">
    {{ 'input.errors.minLength' | translate: { field : question.label, minLength : linkLength } }}
  </span>
</div>
