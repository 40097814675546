import { Component, EventEmitter, Optional, Output, Self, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AbstractMsmInputComponent } from '@component/input/abstract-msm-input.component';
import { IonCheckbox } from '@ionic/angular';

@Component({
  selector: 'msm-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss'],
})
export class CheckboxInputComponent extends AbstractMsmInputComponent {
  @ViewChild(IonCheckbox, { static: false }) ionCheckbox?: IonCheckbox;

  @Output() navigateTo = new EventEmitter<string>();

  constructor(
    @Optional() @Self() public override ngControl?: NgControl,
  ) {
    super(ngControl);
  }

  override setValue(value: boolean): void {
    if (this.ionCheckbox) {
      this.ionCheckbox.value = value;
    }

  }

  override disable(isDisabled: boolean): void {
    if (this.ionCheckbox) {
      this.ionCheckbox.disabled = isDisabled;
    }
  }
}
