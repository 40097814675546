import { Component, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DateHelper } from '@component/helper/date-helper';
import { AbstractMsmInputComponent } from '@component/input/abstract-msm-input.component';
import { DeviceService } from '@service/device.service';
import _moment from 'moment';

import { DataInput } from './datainput';
import { dataInputs } from './datainputs';

interface ValidationMetaData {
  value?: number;
  length?: number;
  date?: _moment.Moment;
}

@Component({
  selector: 'msm-data-input',
  templateUrl: './data-input.component.html',
  styleUrls: ['./data-input.component.scss'],
})
export class DataInputComponent extends AbstractMsmInputComponent implements OnInit {
  readonly dateHelper = DateHelper;

  questionType?: DataInput;
  ionDateTimeValue?: string;
  min: ValidationMetaData = {};
  max: ValidationMetaData = {};

  constructor(public deviceService: DeviceService, @Optional() @Self() public override ngControl?: NgControl) {
    super(ngControl);
  }

  ngOnInit(): void {
    this.questionType = this.getDataInput(this.question.type ?? '');

    if (this.question.metaData.validationRules) {
      this.min = this.prepareLengthValidation(this.question.metaData.validationRules.min);
      this.max = this.prepareLengthValidation(this.question.metaData.validationRules.max);
    }
  }

  prepareLengthValidation(rule: string | number): ValidationMetaData {
    if (typeof rule === 'number') {
      if (this.question.type === 'number') {
        return { value: rule };
      } else {
        return { length: rule, value: 0 };
      }
    } else {
      if (this.question.type === 'date') {
        return { date: DateHelper.parseDate(rule) };
      }
    }

    return { value: 0 };
  }

  datetimeChanged(event: Event): void {
    const evt = event as CustomEvent;

    let formControlValue = '';

    if (this.questionType?.inputType === 'time') {
      const dt = this.dateHelper.parseDateTime(evt.detail.value);
      formControlValue = dt.format('HH:mm');
    } else if (this.questionType?.inputType === 'date') {
      const dt = this.dateHelper.parseDate(evt.detail.value);
      formControlValue = this.dateHelper.getAttributeDate(dt) ?? '';
    }
    if (formControlValue !== '') {
      this.onChange(formControlValue);
    }
  }

  inputChanged(event: Event): void {
    const evt = event as CustomEvent;
    if (this.questionType?.inputType === 'number' && this.min.value) {
      if (evt.detail.value < this.min.value) {
        this.control?.setValue(this.min.value);
      }
    }
  }

  override setValue(value: string): void {
    if (value && (this.question.type === 'date' || this.question.type === 'time')) {
      this.ionDateTimeValue = this.formatValueForIonDateTime(value);
    }
  }

  formatValueForIonDateTime(value: string): string {
    let formattedValue = '';

    if (this.question.type === 'time') {
      const dt = _moment();
      dt.hour(parseInt(value.split(':')[0]));
      dt.minute(parseInt(value.split(':')[1]));
      formattedValue = this.dateHelper.getAttributeDateTime(dt) ?? '';
    } else if (this.question.type === 'date') {
      const dt = this.dateHelper.parseDate(value);
      formattedValue = this.dateHelper.getAttributeDate(dt) ?? '';
    }

    return formattedValue;
  }

  private getDataInput(id: string): DataInput {
    const input = dataInputs.find(datainput => datainput.id === id);
    if (input === undefined) {
      throw new Error(`DataInput not found with id ${id}`);
    }

    return input;
  }
}
