<ion-item>

  <ng-container *ngIf="(question.type !== 'date' && question.type !== 'time') || deviceService.isBrowserApp; else showIonDateTimeButton">
    <ion-input *ngIf="control" [name]="question.code"
               [formControl]="control"
               [type]="questionType?.inputType"
               [inputmode]="questionType?.inputMode"
               [autocomplete]="question.code"
               [min]="min.date ? dateHelper.getAttributeDate(min.date) : min.value"
               [max]="max.date ? dateHelper.getAttributeDate(max.date) : max.value"
               [attr.maxlength]="max.length"
               (ionChange)="inputChanged($event)"
               label="{{question.question}}"
               labelPlacement='floating'
    >
    </ion-input>
  </ng-container>

  <ng-template  #showIonDateTimeButton>
    <ion-label position="floating" class="ion-text-wrap msm-input-datetime-label">{{question.question}}</ion-label>
    <ion-datetime-button datetime="{{question.code}}"></ion-datetime-button>

    <ion-popover [keepContentsMounted]="true"
                 side="bottom"
                 alignment="start">
      <ng-template>
        <ion-datetime [name]="question.code"
                      id="{{question.code}}"
                      [(ngModel)]="ionDateTimeValue"
                      (ionChange)="datetimeChanged($event)"
                      showDefaultButtons="true"
                      preferWheel="true"
                      presentation="{{question.type}}"
                      cancelText="{{'generic.cancel' | translate}}"
                      doneText="{{'generic.done' | translate}}">
          <span slot="title">{{question.question}}</span>
        </ion-datetime>
      </ng-template>
    </ion-popover>
  </ng-template>

  <div slot="end" class="msm-input-counter" *ngIf="max.length">{{ngControl?.control?.value?.length || 0}}/{{max.length}}</div>
</ion-item>

<!-- Error messages -->
<!-- TODO: Extract and style; only show first error? -->
<div class="errors" *ngIf="errors && ngControl?.touched && question">
  <span class="error" *ngIf="errors.required">
    {{ 'input.errors.required' | translate: { field : question.label } }}
  </span>
  <span class="error" *ngIf="errors.pattern">
    {{ 'input.errors.pattern' | translate: { field : question.label } }}
  </span>
  <span class="error" *ngIf="errors.minlength">
    {{ 'input.errors.minLength' | translate: { field : question.label, minLength : min.length } }}
  </span>
  <span class="error" *ngIf="errors.maxlength">
    {{ 'input.errors.maxLength' | translate: { field : question.label, maxLength : max.length } }}
  </span>
  <span class="error" *ngIf="errors.min">
    {{ 'input.errors.minValue' | translate: { field : question.label, minValue : min.value } }}
  </span>
  <span class="error" *ngIf="errors.max">
    {{ 'input.errors.maxValue' | translate: { field : question.label, maxValue : max.value } }}
  </span>
  <span class="error" *ngIf="errors.minDate && min.date">
    {{ 'input.errors.minValue' | translate: { field : question.label, minValue : dateHelper.getDisplayDate(min.date) } }}
  </span>
  <span class="error" *ngIf="errors.maxDate && max.date">
    {{ 'input.errors.maxValue' | translate: { field : question.label, maxValue : dateHelper.getDisplayDate(max.date) } }}
  </span>
</div>
