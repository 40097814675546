import LicensePlate from 'license-plate';

export class LicenseplateUtil {
  static formatLicenseplate(value: string, maxLength: number): string {
    const formattedPlate = new LicensePlate(value).pretty();
    if (value && formattedPlate === value.toUpperCase() && formattedPlate.indexOf('-') === -1 && formattedPlate.length > maxLength) {
      return formattedPlate.substring(0, maxLength);
    }

    return formattedPlate;
  }

  static validateLicensplate(value: string): boolean {
    const plate = new LicensePlate(value);

    return plate.valid();
  }

  static stripLicensePlate(plate: string): string {
    return plate.replace(/-/g, '');
  }
}
