import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { PageHeaderModule } from '@component/page-header/page-header.module';
import { ProgressMenuComponent } from '@component/progress-menu/progress-menu.component';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ErrorService } from '@service/error.service';
import { LanguageService } from '@service/language.service';
import { LogService } from '@service/log.service';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppState } from './app.state';
import { HttpRetryRequestInterceptor } from './interceptor/http-retry-request-interceptor';
import { MultipleTranslationsHttpLoader } from './translations/multiple-translations-http-loader';

const translationsInitializationFactory = (languageService: LanguageService) => async () => {
  await languageService.initialize();
};
const logServiceInitializationFactory = (logService: LogService) => () => logService.initialize();

const multipleLoaderFactory = (http: HttpClient): MultipleTranslationsHttpLoader =>
  new MultipleTranslationsHttpLoader(http, [
    { prefix: '../assets/i18n/', suffix: '.json' },
    { prefix: `${environment.endpointUrl}/dictionary/static/`, suffix: '' },
  ]);

@NgModule({
  declarations: [AppComponent, ProgressMenuComponent],
  bootstrap: [AppComponent],
  imports: [BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    PageHeaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: multipleLoaderFactory,
        deps: [HttpClient],
      },
    })],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: translationsInitializationFactory,
      deps: [LanguageService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: logServiceInitializationFactory,
      deps: [LogService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRetryRequestInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorService,
    },
    AppState,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})

export class AppModule {
}

