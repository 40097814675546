<ng-container *ngFor="let option of question.options">

  <ion-item class="msm-option {{option.code === currentValue ? 'focused' : ''}}"
            (click)="optionClicked(option)"
            [ngClass]="setDivergingDisplay(option)"
            [detail]="!option.metaData?.display"
            button="true">

    <ion-label class='ion-text-wrap'>
      {{ option.label }}
    </ion-label>
  </ion-item>
</ng-container>

<div class="errors" *ngIf="errors && ngControl?.touched">
  <span class="error" *ngIf="errors.required">
    {{ 'input.errors.required' | translate: {field: question.label} }}
    {{ 'input.errors.required' | translate: {field: question.label} }}
  </span>
</div>
