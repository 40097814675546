import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { LicenseplateUtil } from '@component/utils/licenseplate-util';

import { AbstractMsmInputComponent } from '../abstract-msm-input.component';

@Component({
  selector: 'msm-licenseplate-summary-input',
  templateUrl: './licenseplate-summary-input.component.html',
  styleUrls: ['./licenseplate-summary-input.component.scss'],
})
export class LicensePlateSummaryInputComponent extends AbstractMsmInputComponent implements OnInit {
  @Input() summaryView = false;

  actualLength = 0;
  errorText = '';
  licensePlate = '';
  readonly maxLength = 6;
  offSet = 0;

  constructor(
    @Optional() @Self() override ngControl?: NgControl,
  ) {
    super(ngControl);
  }

  ngOnInit(): void {
    if (this.control?.value) {
      this.actualLength = this.control.value.length;
      this.licensePlate = LicenseplateUtil.formatLicenseplate(this.control.value, this.maxLength);
    }
  }


  override disable(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.offSet = this.isDisabled ? 4 : 0;
  }
}
