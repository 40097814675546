import { Injectable } from '@angular/core';
import { msmDatabase } from '@storage/msm-database';
import { DexieImage } from '@storage/msm-storage.model';
import { Table } from 'dexie';
import { catchError, mergeMap, of, tap } from 'rxjs';
import { map, from, Observable } from 'rxjs';

import { MsmStorageTable } from './msm-storage-table.model';

@Injectable({
  providedIn: 'root',
})
export class MsmStorageService {

  private readonly id = '1';

  constructor() {
    this.initialize();
  }

  async initialize(): Promise<void> {
    let record = await msmDatabase.incident.get(this.id);
    if (!record) {
      msmDatabase.incident.add({ id: this.id });
    }

    record = await msmDatabase.prefill.get(this.id);
    if (!record) {
      msmDatabase.prefill.add({ id: this.id });
    }
  }

  read(key: string, table = 'incident'): Observable<string> {
    // Prevent the Promise from resolving before the Observable is subscribed to by wrapping it in an Observable
    return of(`Read ${table}[${this.id}].${key}`).pipe(
      mergeMap(() => from(this.getTable(table).get(this.id))),
      map(data => (data && data[key]) || '{}'),
      catchError(() => of('{}')),
    );
  }

  store(key: string, value = '{}', table = 'incident'): Observable<void> {
    // Prevent the Promise from resolving before the Observable is subscribed to by wrapping it in an Observable
    return of(`Store to ${table}[${this.id}].${key}`).pipe(
      mergeMap(() => from(this.getTable(table).update(this.id, { [key]: value }))),
      map(() => void 0),
    );
  }

  retrieveImage(storageKey: string, imageKey: string, defaultValue = '', table = 'incident'): Observable<string> {
    return from(this.getTable(table).get(this.id)).pipe(
      map(data => (data && data[storageKey] || []) as DexieImage[]),
      map(images => images.find(image => image.key === imageKey)),
      mergeMap(image => (image ? of(image.value) : of(defaultValue))),
    );
  }

  removeImage(storageKey: string, imageKey: string, table = 'incident'): Observable<void> {
    return from(this.getTable(table).get(this.id)).pipe(
      map(data => (data && data[storageKey] || []) as DexieImage[]),
      tap(async images => {
        const idx = images.findIndex(img => img.key === imageKey);
        if (idx > -1) {
          images.splice(idx, 1);
          await this.getTable(table).update(this.id, { [storageKey]: images });
        }
      }),
      map(() => void 0),
    );
  }

  storeImage(storageKey: string, imageKey: string, value = '', table = 'incident'): Observable<void> {
    return from(this.getTable(table).get(this.id)).pipe(
      map(data => (data && data[storageKey] || []) as DexieImage[]),
      tap(async images => {
        const available = images.find(img => img.key === imageKey);
        if (available) {
          available.value = value;
        } else {
          images.push({
            key: imageKey,
            value: value,
          } as DexieImage);
        }

        await this.getTable(table).update(this.id, { [storageKey]: images });
      }),
      map(() => void 0),
    );
  }

  clear(key: string, table = 'incident'): Observable<void> {
    // Prevent the Promise from resolving before the Observable is subscribed to by wrapping it in an Observable
    return of(`Clear ${table}[${this.id}].${key}`).pipe(
      tap(logValue => console.log('msm-storage', logValue)),
      mergeMap(() => from(this.getTable(table).update(this.id, { [key]: null }))),
      map(() => void 0),
    );
  }

  private getTable(table: string): Table<MsmStorageTable, string> {
    const foundTable = Object.entries(msmDatabase).find(entry => entry[0] === table);
    if (!foundTable) {
      throw new Error(`Table ${table} not found`);
    }

    return foundTable[1];
  }
}

