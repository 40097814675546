<div class="msm-persons-container" *ngIf="personControls">
  <div class="msm-persons-row" *ngFor="let personControl of personControls.controls; index as i">
    <msm-person-input [question]="question" [formGroup]="getAsFormGroup(personControl)" [index]="i"></msm-person-input>
    <ion-button (click)="removePersonAt(i)" fill="solid" size="medium" shape="round">
      <ion-icon slot="icon-only" color="danger" name="trash-outline"></ion-icon>
    </ion-button>
  </div>

  <div class="msm-persons-buttons">
    <ion-button (click)="addPerson()" size="medium" shape="round">
      <ion-icon slot="icon-only" color="primary" name="add-circle-outline"></ion-icon>
    </ion-button>
  </div>
</div>
