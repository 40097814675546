import { Component, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AbstractMsmInputComponent } from '@component/input/abstract-msm-input.component';
import { StringUtil } from '@component/utils/string-util';

@Component({
  selector: 'msm-textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: ['./textarea-input.component.scss'],
})
export class TextareaInputComponent extends AbstractMsmInputComponent implements OnInit {
  minLength?: number;
  maxLength?: number;
  stringLength = StringUtil.countNewLineCharacterString;

  constructor(
    @Optional() @Self() override ngControl?: NgControl,
  ) {
    super(ngControl);
  }

  ngOnInit(): void {
    if (this.question.metaData && this.question.metaData.validationRules) {
      if (this.question.metaData.validationRules.min && typeof this.question.metaData.validationRules.min === 'number') {
        this.minLength = this.question.metaData.validationRules.min;
      }
      if (
        this.question.metaData.validationRules.max &&
        typeof this.question.metaData.validationRules.max === 'number'
      ) {
        this.maxLength = this.question.metaData.validationRules.max;
      }
    }
  }
}
