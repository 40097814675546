<div class="msm-help-modal-wrapper">
  <ion-toolbar class="msm-help-modal-header">
    <ion-icon slot="start" name="information-circle-outline" size="large"></ion-icon>
    <ion-title class="msm-help-modal-header">{{ 'modal.help.header' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close-circle-outline" size="large"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <p *ngIf="helpText" class="msm-help-modal-content">{{helpText}}</p>
</div>
