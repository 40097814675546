import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Category } from '@model/category.model';
import { AnswersService } from '@service/answers.service';
import { CategoryApiService } from '@service/category-api.service';
import { ErrorService } from '@service/error.service';
import { ReloadMenuService } from '@service/reload-menu.service';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AppState } from '../../app.state';

@Component({
  selector: 'msm-progress-menu',
  templateUrl: 'progress-menu.component.html',
  styleUrls: ['progress-menu.component.scss'],
})
export class ProgressMenuComponent implements OnInit, OnDestroy {
  static readonly MENU_ID = 'progress';

  menuItems$!: Observable<Category[]>;
  menuEnabled$!: Observable<boolean>;
  environment: string;
  loadMenuSubscription?: Subscription;

  private readonly appThemes = [
    'default',
    'allianz',
    'ohra',
    'skindemo',
    'kia',
    'LM2131',
    'LM2142',
  ];

  constructor(
    private readonly categoryApi: CategoryApiService,
    private readonly answersService: AnswersService,
    private readonly reloadMenuService: ReloadMenuService,
    private readonly errorService: ErrorService,
    readonly location: Location,
    readonly global: AppState,
  ) {
    this.environment = environment.environment.toUpperCase();
  }

  ngOnInit(): void {
    this.loadMenuSubscription = this.reloadMenuService.reloadMenuObservable.subscribe(() => {
      this.menuItems$ = this.answersService.loadAnswers().pipe(
        mergeMap(answers => this.categoryApi.findForMenu(answers)),
        catchError(error => {
          this.errorService.logError(error);

          return of([]);
        }),
      );
    });
  }

  ngOnDestroy(): void {
    this.loadMenuSubscription?.unsubscribe();
  }

  changeTheme(): void {
    let newTheme: string;
    const themeIndex = this.appThemes.findIndex(theme => theme === this.global.get('theme'));
    if (themeIndex !== -1 && (themeIndex + 1) < this.appThemes.length) {
      newTheme = this.appThemes[themeIndex + 1];
    } else {
      newTheme = this.appThemes[0];
    }
    this.global.set('theme', newTheme);
  }
}
