import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Incident } from '@model/incident.model';
import { IncidentsApiService } from '@service/incidents-api.service';
import { LanguageService } from '@service/language.service';
import { LogService } from '@service/log.service';
import QrScanner from 'qr-scanner';
import { FormsPageComponent } from 'src/app/forms/forms-page.component';

import { AbstractMsmInputComponent } from '../abstract-msm-input.component';

@Component({
  selector: 'msm-scan-qr-input',
  templateUrl: './scan-qr-input.component.html',
  styleUrls: ['./scan-qr-input.component.scss'],
})
export class ScanQrInputComponent extends AbstractMsmInputComponent implements AfterViewInit, OnDestroy {
  @Input({ required: true }) incident!: Incident | undefined | null;
  @Output() navigateTo = new EventEmitter<string>();

  @ViewChild('qrScanPreview') videoElem!: ElementRef;

  scannerReady = false;
  message: string | null = 'Opening your camera...';
  errorMessage: string | null = null;

  private noCameraError = 'Cannot use this functionality, since your device does not have a camera';
  private scannerStartError = 'Could not start the QR Scanner';
  private invalidCodeError = 'QR code not recognized';
  private linkFailedError = 'Failed to link your incident';
  private codeScannedMessage = 'Code scanned, trying to link your incident...';
  private linkSuccessfulMessage = 'Linked successfully';

  private hasCameraPromise: Promise<boolean>;
  private qrScanner?: QrScanner;
  private linkingInProgress = false;

  constructor(
    private readonly incidentsApi: IncidentsApiService,
    private readonly languageService: LanguageService,
    private readonly logService: LogService,
    @Optional() @Self() override ngControl?: NgControl,
  ) {
    super(ngControl);

    this.hasCameraPromise = QrScanner.hasCamera();

    this.languageService.getTranslation('component.scan.loading').subscribe(translation => {
      if (!this.scannerReady && this.errorMessage !== null) {
        this.message = translation;
      }
    });
    this.languageService.getTranslation('component.scan.error.noCamera').subscribe(translation => this.noCameraError = translation);
    this.languageService.getTranslation('component.scan.error.startScanner').subscribe(translation => this.scannerStartError = translation);
    this.languageService.getTranslation('component.scan.error.invalidQrCode').subscribe(translation => this.invalidCodeError = translation);
    this.languageService.getTranslation('component.scan.scanned').subscribe(translation => this.codeScannedMessage = translation);
    this.languageService.getTranslation('component.scan.success').subscribe(translation => this.linkSuccessfulMessage = translation);
    this.languageService.getTranslation('component.scan.failed').subscribe(translation => this.linkFailedError = translation);
  }

  ngAfterViewInit(): void {
    this.qrScanner = new QrScanner(
      this.videoElem.nativeElement,
      result => this.handleScanResult(result),
      {
        highlightScanRegion: true,
        returnDetailedScanResult: true,
      },
    );

    this.hasCameraPromise.then(hasCamera => {
      if (!hasCamera || !this.qrScanner) {
        this.setErrorMessage(this.noCameraError);
      } else {
        this.qrScanner
          .start()
          .then(() => {
            this.message = null;
            this.errorMessage = null;
            this.scannerReady = true;
          })
          .catch(reason => {
            this.logService.logMessage(`Failed to start the QR scanner due to ${reason}`, 'error');
            this.setErrorMessage(`${this.scannerStartError}: ${reason}`);
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.qrScanner?.destroy();
  }

  override setValue(value: string): void {
    if (value) {
      this.linkingInProgress = true;
      this.linkWithCurrentIncident(value);
    }
  }

  private static extractIncidentUuid(scanResult: QrScanner.ScanResult): string {
    if (scanResult.data.indexOf('?incidentUuid=') > 0) {
      return scanResult.data.split('?incidentUuid=')[1];
    } else {
      return scanResult.data;
    }
  }

  private handleScanResult(scanResult: QrScanner.ScanResult): void {
    if (!this.linkingInProgress) {
      this.linkingInProgress = true;
      this.qrScanner?.stop();
      this.setMessage(this.codeScannedMessage);
      const linkToIncidentUuid = ScanQrInputComponent.extractIncidentUuid(scanResult);

      const regexMatches = linkToIncidentUuid.match('^[a-zA-Z0-9-]+$');
      if (regexMatches === null || regexMatches.length !== 1) {
        this.setErrorMessage(this.invalidCodeError);
        this.linkingInProgress = false;
        this.qrScanner?.start();
      } else {
        this.errorMessage = null;
        this.onChange(linkToIncidentUuid);
        this.linkWithCurrentIncident(linkToIncidentUuid);
      }
    }
  }

  private linkWithCurrentIncident(incidentUuidToLink: string): void {
    this.incidentsApi.linkIncidentsByUuid(this.incident?.uuid ?? '', incidentUuidToLink).subscribe({
      next: () => {
        this.setMessage(this.linkSuccessfulMessage);
        this.navigateTo.emit(FormsPageComponent.NAVIGATION_REQUEST_NEXT);
      },
      error: err => {
        this.onChange(null);
        this.setErrorMessage(this.linkFailedError);
        this.logService.logException(err);
        this.linkingInProgress = false;
        this.qrScanner?.start();
      },
    });
  }

  private setMessage(message: string): void {
    this.message = message;
    this.errorMessage = null;
  }

  private setErrorMessage(errorMessage: string): void {
    this.message = null;
    this.errorMessage = errorMessage;
  }
}
