import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, FormGroupName } from '@angular/forms';
import { FormsPageComponent } from '@component/../forms/forms-page.component';
import { AbstractMsmFormComponent } from '@component/abstract-msm-form-component.directive';
import { FormHelper } from '@component/helper/form-helper';
import { FormAnswers } from '@model/form-answers';
import { IncidentStatus } from '@model/incident-status';
import { Incident, UpdateStatus } from '@model/incident.model';
import { Question } from '@model/question.model';
import { AnswersService } from '@service/answers.service';
import { DossierlinkService } from '@service/dossierlink.service';
import { ErrorService } from '@service/error.service';
import { IncidentsApiService } from '@service/incidents-api.service';
import { LogService } from '@service/log.service';
import { ToastService } from '@service/toast.service';
import { switchMap } from 'rxjs';

@Component({
  selector: 'msm-sign-input',
  templateUrl: './sign-input.component.html',
  styleUrls: ['./sign-input.component.scss'],
})
export class SignInputComponent extends AbstractMsmFormComponent implements OnInit {
  @Input({ required: true }) incident!: Incident | undefined | null;
  @Input({ required: true }) question!: Question;
  @Input({ required: true }) signPageId!: number;
  @Input({ required: true }) parentAnswersForm!: UntypedFormGroup;
  @Output() navigateTo = new EventEmitter<string>();

  signing = false;

  constructor(
    private controlContainer: FormGroupName,
    private readonly errorService: ErrorService,
    private readonly incidentsApiService: IncidentsApiService,
    protected override readonly logService: LogService,
    protected override readonly toastService: ToastService,
    protected override readonly answersService: AnswersService,
    private readonly dossierLinkService: DossierlinkService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.answersForm = this.controlContainer.control;
    this.logService.logBreadcrumb(`Set answersFrom on sign-input: ${!!this.answersForm}`);

    this.parentQuestionCode = this.question.code ?? '';
    this.answersService.loadAnswers().subscribe(answers => {
      this.answers = answers;
      this.enableSoftConditions();
    });
  }

  get questions(): Question[] {
    return this.question.children ?? [];
  }

  getChildQuestionCode(childQuestion: Question): string {
    return FormHelper.getQuestionCodeWithoutPrefix(childQuestion.code, this.question.code);
  }

  signIncident(): void {
    if (!this.signing && this.question) {
      this.signing = true;
      // eslint-disable-next-line max-len
      const allTheAnswers = AnswersService.mergeAllExceptArrays(
        AnswersService.mergeAllExceptArrays({}, this.answers),
        this.parentAnswersForm.getRawValue(),
      );

      const formType = allTheAnswers[this.question.metaData.questionKeyFormType as string];
      const hashCodes = allTheAnswers.summary as FormAnswers;
      const hashCodeReporter = hashCodes[this.question.metaData.questionKeyHashCode as string] as string;
      const hashCodeCounterpart = hashCodes[this.question.metaData.questionKeyLinkedHashCode as string] as string;

      switch (formType) {
        case 'singlesided': {
          this.signSingleSidedIncident(allTheAnswers);
          break;
        }
        case 'samesided': {
          this.signSameSidedIncident(allTheAnswers, hashCodeCounterpart, hashCodeReporter);
          break;
        }
        case 'doublesided': {
          this.signDoubleSidedIncident(allTheAnswers, hashCodeCounterpart);
          break;
        }
      }
    }
  }

  private signSingleSidedIncident(answers: FormAnswers): void {
    this.logService.logMessage('Signing single sided incident', 'debug');

    const updateStatusRequest = this.createUpdateStatusRequest('', '', answers);

    this.incidentsApiService
      .updateStatus(this.incident?.uuid ?? '', updateStatusRequest)
      .pipe(switchMap(response => this.dossierLinkService.storeDossierLink(response.dossierLink)))
      .subscribe({
        next: () => this.navigateTo.emit(FormsPageComponent.NAVIGATION_REQUEST_NEXT),
        error: err => this.handleSignErrorResponse(err, updateStatusRequest),
      });
  }

  private signSameSidedIncident(answers: FormAnswers, hashCodeCounterpart: string, hashCodeReporter: string): void {
    const linkedIncidentUuid = this.answers[this.question.metaData.questionKeyLinkedIncidentUuid as string] as string;

    const reporter = this.answersForm.get(this.getSignCodeKeyReporter())?.value;
    const counterpart = this.answersForm.get(this.getSignCodeKeyCounterpart())?.value;
    if (!reporter || !counterpart) {
      throw new Error('Cannot update status of form. SignCodeKey of Reporter or Counterpart is missing');
    }

    const updateStatusRequest = this.createSamesidedUpdateStatusRequest(
      reporter,
      counterpart,
      hashCodeReporter,
      hashCodeCounterpart,
      answers,
    );

    this.logService.logMessage(`Signing same sided incident with reporter code ${updateStatusRequest.status.code} and counterpart code ${updateStatusRequest.linkedStatus?.code}`, 'debug');

    this.incidentsApiService
      .samesidedUpdateStatus(this.incident?.uuid ?? '', linkedIncidentUuid, updateStatusRequest)
      .pipe(switchMap(response => this.dossierLinkService.storeDossierLink(response.dossierLink)))
      .subscribe({
        next: () => this.navigateTo.emit(FormsPageComponent.NAVIGATION_REQUEST_NEXT),
        error: err => this.handleSignErrorResponse(err, updateStatusRequest),
      });
  }

  private signDoubleSidedIncident(answers: FormAnswers, hashCodeCounterpart: string): void {
    const value = this.answersForm.get(this.getSignCodeKeyReporter())?.value;
    if (!value) {
      throw new Error('Cannot update status of form, signcodeReporter is missing');
    }

    const updateStatusRequest = this.createUpdateStatusRequest(value, hashCodeCounterpart, answers);

    this.logService.logMessage(`Signing double sided incident with code ${updateStatusRequest.status.code}`, 'debug');

    this.incidentsApiService
      .updateStatus(this.incident?.uuid ?? '', updateStatusRequest)
      .pipe(switchMap(response => this.dossierLinkService.storeDossierLink(response.dossierLink)))
      .subscribe({
        next: () => this.navigateTo.emit(FormsPageComponent.NAVIGATION_REQUEST_NEXT),
        error: err => this.handleSignErrorResponse(err, updateStatusRequest),
      });
  }

  private createUpdateStatusRequest(signCode: string, hashCode: string, answers: FormAnswers): UpdateStatus {
    return {
      status: {
        status: IncidentStatus.SIGNED,
        code: signCode,
        hashCode: hashCode,
      },
      signPageId: this.signPageId,
      answers,
    };
  }

  private createSamesidedUpdateStatusRequest(
    reporterSignCode: string,
    counterpartSignCode: string,
    hashCode: string,
    hashCodeCounterpart: string,
    answers: FormAnswers,
  ): UpdateStatus {
    return {
      status: {
        status: IncidentStatus.SIGNED,
        code: reporterSignCode,
        hashCode: hashCodeCounterpart,
      },
      linkedStatus: {
        status: IncidentStatus.SIGNED,
        code: counterpartSignCode,
        hashCode: hashCode,
      },
      signPageId: this.signPageId,
      answers,
    };
  }

  private getSignCodeKeyReporter(): string {
    return FormHelper.getQuestionCodeWithoutPrefix(
      this.question.metaData.questionKeyReporterSignCode as string,
      this.question.code,
    );
  }

  private getSignCodeKeyCounterpart(): string {
    return FormHelper.getQuestionCodeWithoutPrefix(
      this.question.metaData.questionKeyCounterpartSignCode as string,
      this.question.code,
    );
  }

  private handleSignErrorResponse(err: HttpErrorResponse, updateStatusRequest: UpdateStatus): void {
    this.signing = false;

    if (err?.status === 409) {
      // Conflict indicates the other party has made changes to their version of the incident, unbeknownst to the reporter
      this.logService.logMessage('Could not sign incident since the other party has made changes', 'info', { 'status': updateStatusRequest.status });
      this.navigateTo.emit(FormsPageComponent.NAVIGATION_REQUEST_NEXT);
    } else if (err?.status === 412) {
      // Precondition Failed indicates the sivi is not valid, and the user should be notified
      this.logService.logMessage('Could not sign incident since the sivi is not valid', 'error', { 'status': updateStatusRequest.status });
      this.toastService.showToastKey('component.signed.signSiviInvalid', { color: 'warning', duration: 10000 });
    } else if (err?.status === 425) {
      // Too Early indicates the sivi is not yet valid, but we can try again.
      this.logService.logMessage('Could not sign incident since the sivi is not complete yet', 'warning', { 'status': updateStatusRequest.status });
      this.toastService.showToastKey('component.signed.signNotSucceed', { color: 'warning', duration: 10000 });
    } else {
      if (err?.error.message) {
        this.errorService.logError(err);
        this.toastService.showToastKey(err.error.message, { color: 'danger' });
      } else {
        throw err;
      }
    }
  }
}
