<ion-button id="msm-button-request-sign-code"
            (click)="requestSignCode()"
            fill="outline"
            class="msm-sign-button"
            [disabled]="requestingCode"
>
  {{ 'component.signcode.requestCode' | translate }}
</ion-button>

<ion-item lines="none"
          class="msm-code-container">
  <ion-label class="ion-text-wrap msm-code-label">{{ question.question }}: </ion-label>
</ion-item>

<ion-item *ngIf="control" lines="none"
          class="msm-code-container">
  <ion-input class="msm-code-input"
             [formControl]="control"
             [attr.maxlength]="maxLength"
             type="text"
             inputmode="numeric"
             placeholder="{{'_'.repeat(maxLength)}}"></ion-input>
</ion-item>

<!-- Error messages -->
<!-- TODO: Extract and style; only show first error? -->
<div class="errors" *ngIf="errors && ngControl?.touched">
  <span class="error" *ngIf="errors.required">
    {{ 'input.errors.required' | translate: { field : question.label } }}
  </span>
  <span class="error" *ngIf="errors.pattern">
    {{ 'input.errors.pattern' | translate: { field : question.label } }}
  </span>
  <span class="error" *ngIf="errors.minlength">
    {{ 'input.errors.minLength' | translate: { field : question.label, minLength : question.metaData.validationRules?.max } }}
  </span>
</div>

