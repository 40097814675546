import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateHelper } from '@component/helper/date-helper';
import { StringUtil } from '@component/utils/string-util';
import _moment from 'moment';

export class CustomValidators {

  static dateTimesInOrder(formControlNames: string[]): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {
      let previousDate: _moment.Moment = _moment(new Date(0,0,0));
      let previousControlName = '';

      for (const controlNameIndex in formControlNames) {
        const controlName = formControlNames[controlNameIndex];

        const value = control.get(controlName)?.value;
        if (!value) {
          continue;
        }

        const currentDate: _moment.Moment = DateHelper.parseDateTime(value);
        if (currentDate.isBefore(previousDate)) {
          return { datesInOrder: [previousControlName, controlName] };
        }
        previousControlName = controlName;
        previousDate = currentDate;
      }

      return null;
    };
  }

  static minDate(minMoment: _moment.Moment): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      const valueMoment = _moment(value);
      if (valueMoment.isBefore(minMoment)) {
        return { minDate: true };
      } else {
        return null;
      }
    };
  }

  static maxDate(maxMoment: _moment.Moment): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      const valueMoment = _moment(value);
      if (valueMoment.isAfter(maxMoment)) {
        return { maxDate: true };
      } else {
        return null;
      }
    };
  }

  /**
   * Create a custom maximumLengthValidator that validates the length of the string contexts.
   * Count each newline character (\n) in string as two characters.
   * @param {Number} maxValue, the maximum number of characters that can be entered into the control.
   * @return {ValidatorFn}
   */
  static maxLength(maxValue: number): ValidatorFn {
    const validatorFn = (control: AbstractControl): ValidationErrors | null => {
      const filledValue = control.value;
      const count = StringUtil.countNewLineCharacterString(filledValue);

      if (count > maxValue) {
        return { maxlength: `Length ${count} exceeds maximum allowed length of ${maxValue}` };
      }

      return null;
    };

    return validatorFn;
  }


  static isNotEqual(invalidOptions: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (invalidOptions === control.value) {
        return { invalidOption: true };
      } else {
        return null;
      }
    };
  }
}
