import { Component, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AbstractMsmInputComponent } from '@component/input/abstract-msm-input.component';

@Component({
  selector: 'msm-form-type',
  templateUrl: 'form-type-input.component.html',
  styleUrls: ['form-type-input.component.scss'],
})
export class FormTypeInputComponent extends AbstractMsmInputComponent implements OnInit {
  currentValue = '';
  descriptionLines: string[][] = [];

  constructor(
    @Optional() @Self() public override ngControl?: NgControl,
  ) {
    super(ngControl);
  }

  itemClicked(answer: string): void {
    this.onChange(answer);
  }

  ngOnInit(): void {
    this.question.options?.map(option => {
      this.descriptionLines.push(option.description.split('\n'));
    });
  }

  override setValue(value: string): void {
    this.currentValue = value;
  }
}
